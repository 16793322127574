/* eslint-disable react/display-name */
import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import Recaptcha from '../../types/Recaptcha'

const useRecaptcha = () => {
    const data = useStaticQuery(graphql`
        query HeaderQuery {
            site {
                siteMetadata {
                    recaptchaSiteKey
                }
            }
        }
    `)

    return data.site.siteMetadata
}

const withRecaptcha = (Component: React.ComponentType<any>): React.ComponentType<any> => (props: Recaptcha) => {
    const recaptcha = useRecaptcha()

    return <Component {...props} recaptcha={recaptcha} />
}

export default withRecaptcha
