import * as React from 'react'
import * as styles from './IFrame.module.scss'

const IFrame: React.FunctionComponent<IFrameProps> = (props: IFrameProps) => (
    <iframe className={styles.iframe} src={props.src} title={props.title} />
)

export default IFrame

interface IFrameProps {
    src: string
    title?: string
}
