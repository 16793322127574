import * as React from 'react'
import { IResult } from '../../../types/Search'
import AnyLink from '../../AnyLink'
import * as styles from './SearchResultRow.module.scss'

const SearchResultRow: React.FunctionComponent<SearchResultRowProps> = (props: SearchResultRowProps) => {
    const { result } = props

    return (
        <tr>
            <td>
                <h2>{result.title}</h2>
                <p dangerouslySetInnerHTML={{ __html: result.htmlSnippet }} className={styles.description} />
                <AnyLink to={result.url}>{result.url}</AnyLink>
            </td>
        </tr>
    )
}

export default SearchResultRow

interface SearchResultRowProps {
    result: IResult
}
