import * as React from 'react'
import { Row } from 'react-bootstrap'
import PageContext from '../../../types/PageContext'
import { IVacancyRecord, TVacancies } from '../../../types/Vacancy'
import Vacancy from '../Vacancy'

class VacancyPlugin extends React.Component<Props, {}> {
    render() {
        const { pageContext, pluginData } = this.props
        let vacancyData: IVacancyRecord[] = []

        if (pluginData) {
            vacancyData = pluginData[pageContext.language]
        }

        const vacancies = vacancyData.map((vacancy, i) => <Vacancy key={`vac-${i}`} vacancy={vacancy} />)

        return <Row>{vacancies}</Row>
    }
}

export default VacancyPlugin

interface Props {
    config: any
    pageContext: PageContext<any>
    pluginData: TVacancies
}
