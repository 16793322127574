import * as React from 'react'
import { IQuoteDialogData } from '../../../types/Quote'
import Translation from '../../../types/Translation'
import axios from '../../../utils/axios'
import withTranslations from '../../hoc/withTranslations'
import ConfirmDialog from './ConfirmDialog'
import * as styles from './CreditCardModal.module.scss'
import CreditCardDialog from './CreditCardDialog'

class InvoiceModal extends React.Component<InvoiceModalProps, InvoiceModalState> {
    state = {
        errorMsg: '',
        displayConfirmDialog: false,
    }

    renderBtnContent = () => <span className={'pti-book'}>{this.props.t('template', 'general.invoice')}</span>

    renderTextContent = () => (
        <>
            <p>{this.props.t('template', 'quotes.dialog.invoice.text')}</p>
        </>
    )

    handleClose = async () => {
        this.setState({
            displayConfirmDialog: false,
        })

        if (this.props.onInvoiceComplete) {
            await this.props.onInvoiceComplete()
        }
    }

    handleInvoiceQuote = async (quoteDialogData: IQuoteDialogData, handleClose: any) => {
        const success = await this.postInvoiceQuote(quoteDialogData)

        if (success) {
            handleClose()

            this.setState({
                displayConfirmDialog: true,
            })
        }
    }

    postInvoiceQuote = async (quoteDialogData: IQuoteDialogData) => {
        const { quoteId, t } = this.props
        const quoteInvoiceUrl = `/api/v1.0/Quotes/${quoteId}/Order/invoice`

        try {
            await axios.post(quoteInvoiceUrl, quoteDialogData)
            return true
        } catch (error: any) {
            console.log(error)

            if (error.response) {
                console.log(`status code: ${error.response.status}`)
                console.log(error.response.data)
            }

            this.displayError(t('form', 'general.form.error'))

            return false
        }
    }

    displayError = (errorMsg: string) => {
        this.setState({
            errorMsg,
        })
    }

    render() {
        const btnContent = this.renderBtnContent()

        const { t, quoteNumber, totalPrice } = this.props

        const { errorMsg, displayConfirmDialog } = this.state

        const subTitle = t('template', 'quotes.title.quote') + ` ${quoteNumber}`
        const textContent = this.renderTextContent()

        const additionalContent = (
            <div className={styles.totalPrice}>
                <span>
                    <strong>{t('template', 'quotes.dialog.total.label')}</strong>
                </span>

                <span>
                    <strong>{totalPrice}</strong>
                </span>
            </div>
        )

        const btnLabel = t('template', 'quotes.dialog.invoice.button.label')

        return (
            <>
                <CreditCardDialog
                    btnContent={btnContent}
                    title={t('template', 'quotes.dialog.invoice.title')}
                    subTitle={subTitle}
                    textContent={textContent}
                    additionalContent={additionalContent}
                    modalAction={this.handleInvoiceQuote}
                    modalActionBtnLabel={btnLabel}
                    errorMsg={errorMsg}
                />

                <ConfirmDialog
                    show={displayConfirmDialog}
                    title={t('template', 'quotes.dialog.invoice.title')}
                    subTitle={t('template', 'quotes.dialog.invoice.confirmation.title')}
                    textContent={t('template', 'quotes.dialog.invoice.confirmation.text')}
                    onClose={this.handleClose}
                />
            </>
        )
    }
}

export default withTranslations(InvoiceModal)

interface InvoiceModalProps extends Translation {
    quoteNumber: string
    totalPrice: string
    quoteId: string
    onInvoiceComplete?: any
}

interface InvoiceModalState {
    errorMsg: string
    displayConfirmDialog: boolean
}
