import * as React from 'react'
import PageContext from '../../../types/PageContext'
import LicenceKits from '../../mypdftools/licences/LicencesKits'

class MyPDFLicencesPlugin extends React.Component<Props> {
    render(): React.ReactNode {
        const { pageContext } = this.props

        return <LicenceKits pageContext={pageContext} />
    }
}

export default MyPDFLicencesPlugin

interface Props {
    config: any
    pageContext: PageContext<any>
    pluginData: any
}
