import * as React from 'react'
import { Col, Row } from 'react-bootstrap'
import { INewsListItem } from '../../../types/News'
import * as styles from './NewsList.module.scss'
import NewsListItem from './NewsListItem'

const NewsList: React.FunctionComponent<NewsListProps> = (props: NewsListProps) => {
    const { newsList } = props

    const newsItems = newsList.map((newsListItem, i) => <NewsListItem key={`${i}-key`} newsListItem={newsListItem} />)

    return (
        <Row>
            <Col sm={12} className={styles.newsList}>
                {newsItems}
            </Col>
        </Row>
    )
}

export default NewsList

interface NewsListProps {
    newsList: INewsListItem[]
}
