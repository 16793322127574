import * as React from 'react'
import PageContext from '../../../types/PageContext'
import { UrlLinks } from '../../../types/UrlLookup'
import OrderProcess from '../../mypdftools/orderprocess/OrderProcess'

class MyPDFQuotesPlugin extends React.Component<Props, {}> {
    render() {
        const { pageContext } = this.props
        const contactUrls = this.props.pluginData

        return <OrderProcess pageContext={pageContext} contactUrls={contactUrls} />
    }
}

export default MyPDFQuotesPlugin

interface Props {
    config: any
    pageContext: PageContext<any>
    pluginData: UrlLinks
}
