import * as React from 'react'
import { Table } from 'react-bootstrap'
import * as styles from './GenericTable.module.scss'

export const GenericTable: React.FunctionComponent<GenericTableProps> = (props: GenericTableProps) => (
    <Table striped={true} className={`${styles.genericTable} generic-table`} responsive={true}>
        {props.children}
    </Table>
)

interface GenericTableProps {
    children: React.ReactNode
}
