import * as React from 'react'
import { Button } from 'react-bootstrap'
import * as styles from './SearchPaginationItem.module.scss'

const SearchPaginationItem: React.FunctionComponent<SearchPaginationItemProps> = (props: SearchPaginationItemProps) => {
    const { onClick, children, active } = props

    let btn = (
        <Button className={`${styles.pagBtn} page-link`} onClick={onClick}>
            {children}
        </Button>
    )

    if (active) {
        btn = <span className={`${styles.activeBtn} page-link`}>{children}</span>
    }

    return <li className={`page-item ${active ? 'active' : ''}`}>{btn}</li>
}

export default SearchPaginationItem

interface SearchPaginationItemProps {
    onClick: any
    active?: boolean
    children: React.ReactNode
}
