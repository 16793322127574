import { Link } from 'gatsby'
import * as React from 'react'
import { Col } from 'react-bootstrap'
import Translation from '../../types/Translation'
import { IVacancyRecord } from '../../types/Vacancy'
import withTranslations from '../hoc/withTranslations'
import ColoredBox from './ColoredBox'

const Vacancy: React.FunctionComponent<VacancyProps> = (props: VacancyProps) => {
    const { vacancy, t } = props

    return (
        <Col sm={4}>
            <ColoredBox rounded={true} pdftColor="darkolive">
                <p>{vacancy.slogan}</p>
                <h2>{vacancy.description}</h2>
                <p>
                    <Link to={vacancy.url} className="link-more">
                        {t('template', 'general.link.text.moreinfo')}
                    </Link>
                </p>
            </ColoredBox>
        </Col>
    )
}

interface VacancyProps extends Translation {
    vacancy: IVacancyRecord
}

export default withTranslations(Vacancy)
