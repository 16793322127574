import * as React from 'react'
import { Row } from 'react-bootstrap'
import { ISuccessStoryListRecord } from '../../../types/SuccessStory'
import HorizontalRule from '../HorizontalRule'
import SuccessStoryListItem from './SuccessStoryListItem'

const SuccessStoryListSection: React.FunctionComponent<SuccessStoryListSectionProps> = (props: SuccessStoryListSectionProps) => {
    const { sectionKey, sectionItems } = props

    return (
        <>
            <h3 id={sectionKey}>{sectionKey}</h3>

            <HorizontalRule />

            <Row>
                {sectionItems.map((item: ISuccessStoryListRecord, i) => (
                    <SuccessStoryListItem key={`list-record-${i}`} listRecord={item} />
                ))}
            </Row>
        </>
    )
}

export default SuccessStoryListSection

interface SuccessStoryListSectionProps {
    sectionKey: string
    sectionItems: ISuccessStoryListRecord[]
}
