import axios from 'axios'
import * as React from 'react'
import { Alert, Col, Container, Row, Image } from 'react-bootstrap'
import postifyData from '../../../helper/FormHelper'
import { reportGtmEvent } from '../../../helper/TagManagerHelper'
import SuccessPage from '../../../templates/SuccessPageTemplate'
import PageContext from '../../../types/PageContext'
import Translation from '../../../types/Translation'
import withTranslations from '../../hoc/withTranslations'
import CallbackForm from '../form/CallbackForm'
import ContactForm from '../form/ContactForm'
import EvalFeedbackForm from '../form/EvalFeedbackForm'

class FormPlugin extends React.Component<Props> {
    state = {
        submitSuccess: false,
        formData: null,
        recaptchaToken: '',
        error: false,
        errorMsg: '',
    }

    constructor(props: Props) {
        super(props)

        this.handleSubmit = this.handleSubmit.bind(this)
    }

    async handleSubmit(apiData: any, formData: any) {
        this.setState({
            error: false,
            errorMsg: '',
        })

        const { recaptchaToken } = this.state
        const { t, config } = this.props

        if (config.id === 'contact' && !recaptchaToken.length) {
            this.displayFormError(t('form', 'form.recaptcha.missing'))
            return
        }

        const apiEndpoints: { [key: string]: string } = {
            contact: '/api/v1.0/ContactForm',
            callback: '/api/v1.0/CallbackForm',
            evalfeedback: '/api/v1.0/EvalFeedbackForm',
        }

        const endpoint = this.props.config.id
        const url = apiEndpoints[endpoint]

        const postContactFormData = {
            ...postifyData(apiData),
            grecaptcha: recaptchaToken,
        }

        const postFormData = {
            ...postifyData(apiData),
        }

        try {
            reportGtmEvent('Form', 'submit', { form: config.id === 'callback' ? 'callBack' : config.id })
            if (config.id === 'contact') {
                await axios.post(url, postContactFormData, {
                    headers: {
                        'Accept-Language': this.props.pageContext.language,
                    },
                })
            } else {
                await axios.post(url, postFormData, {
                    headers: {
                        'Accept-Language': this.props.pageContext.language,
                    },
                })
            }

            this.setState({
                submitSuccess: true,
                formData,
            })
        } catch (error: any) {
            console.log(error)

            if (error.response) {
                console.log(`status code: ${error.response.status}`)
                console.log(error.response.data)
            }

            this.displayFormError(this.props.t('form', 'contactForm.alert.rejected'))
        }
    }

    handleRecaptcha = (recaptchaToken: string) => {
        this.setState({
            ...this.state,
            recaptchaToken,
        })
    }

    displayFormError(errorMsg: string) {
        this.setState({
            error: true,
            errorMsg,
        })
    }

    public render() {
        const { pageContext, config, pluginData } = this.props
        const { countries, referrerOptions, areaOptions, timeOptions } = pluginData

        const { submitSuccess, formData, error, errorMsg } = this.state

        const langCountries = countries[pageContext.language]
        const langReferrerOptions = referrerOptions[pageContext.language]

        const langAreas = areaOptions[pageContext.language]
        const langSpansOfTime = timeOptions[pageContext.language]

        let form: JSX.Element = <div />

        if (config.id === 'contact') {
            form = (
                <ContactForm
                    onSubmit={this.handleSubmit}
                    language={pageContext.language}
                    countryOptions={langCountries}
                    findUsOptions={langReferrerOptions}
                    handleRecaptcha={this.handleRecaptcha}
                />
            )
        } else if (config.id === 'callback') {
            form = (
                <CallbackForm
                    onSubmit={this.handleSubmit}
                    language={pageContext.language}
                    areasOfInterest={langAreas}
                    spansOfTime={langSpansOfTime}
                />
            )
        } else if (config.id === 'evalfeedback') {
            form = (
                <EvalFeedbackForm
                    onSubmit={this.handleSubmit}
                    language={pageContext.language}
                    pageContext={pageContext}
                    config={config}
                    pluginData={pluginData}
                />
            )
        }

        return (
            <Container>
                <Row>
                    <Col sm={12}>
                        {!submitSuccess && form}
                        {submitSuccess && <SuccessPage formData={formData} pageContext={pageContext} formType={config.id} />}
                        {error && <Alert variant={'danger'}>{errorMsg}</Alert>}
                    </Col>
                </Row>
            </Container>
        )
    }
}

export default withTranslations(FormPlugin)

interface Props extends Translation {
    pageContext: PageContext<any>
    config: any
    pluginData: any
}
