import React from 'react'
import { Col } from 'react-bootstrap'
import { IDownloadData, IProductLineLicencesState } from '../../../types/LicenseKit'
import PageContext from '../../../types/PageContext'
import Translation from '../../../types/Translation'
import { IVersion } from '../../../types/Version'
import withTranslations from '../../hoc/withTranslations'
import CollapsableRow from './CollapsableRow'
import DownloadKitLicense from './DownloadKitLicense'
import LicensesHeadRow from './LicensesHeadRow'
import ProductLineLicences from './ProductLineLicences'

class ThreeHeights extends React.Component<ThreeHeightsProps, ThreeHeightsState> {
    renderLicences = (downloadData: IDownloadData[], state: IProductLineLicencesState) => {
        const { t, pageContext } = this.props

        const { selectedVersion } = state

        const collapsableRows = downloadData.map((data, i: number) => {
            let uncollapsed = false

            if (i === 0) {
                uncollapsed = true
            }

            const id = `th-row-${i}`

            return (
                <CollapsableRow
                    key={id}
                    id={id}
                    name={data.articleName}
                    articleCode={data.articleCode}
                    platform={data.platform}
                    variant={data.type}
                    uncollapsed={uncollapsed}
                >
                    <DownloadKitLicense downloadData={data} language={pageContext.language} selectedVersion={selectedVersion} />
                </CollapsableRow>
            )
        })

        let content: any = null

        if (Array.isArray(downloadData)) {
            if (downloadData.length > 0) {
                content = collapsableRows
            } else {
                content = <div>{t('template', 'licenses.info.message.emptylicense')}</div>
            }
        }

        return (
            <>
                <LicensesHeadRow>
                    <Col sm={6} xs={12}>
                        {t('template', 'licenses.column.title.description')}
                    </Col>
                    <Col sm={2} xs={4}>
                        {t('template', 'licenses.column.title.variant')}
                    </Col>
                    <Col sm={2} xs={4}>
                        {t('template', 'licenses.column.title.platform')}
                    </Col>
                    <Col sm={2} xs={4}>
                        {t('template', 'licenses.column.title.articlecode')}
                    </Col>
                </LicensesHeadRow>

                {content}
            </>
        )
    }

    render() {
        const { t } = this.props

        return (
            <>
                <ProductLineLicences
                    downloadData={this.props.threeHeightsActive}
                    versions={this.props.versionsThreeHeights}
                    title={t('template', 'licenses.productgeneration.threeheights.title')}
                    pageContext={this.props.pageContext}
                    renderData={this.renderLicences}
                    hideSectionOnEmpty={true}
                />

                <ProductLineLicences
                    downloadData={this.props.threeHeightsEval}
                    versions={this.props.versionsThreeHeights}
                    title={t('template', 'licenses.productgeneration.threeheights.eval.title')}
                    pageContext={this.props.pageContext}
                    renderData={this.renderLicences}
                    evalVersions={true}
                    hideSectionOnEmpty={true}
                />
            </>
        )
    }
}

export default withTranslations(ThreeHeights)

interface ThreeHeightsProps extends Translation {
    pageContext: PageContext
    threeHeightsActive: IDownloadData[] | null
    threeHeightsEval: IDownloadData[] | null
    versionsThreeHeights: IVersion[] | null
}

interface ThreeHeightsState {}
