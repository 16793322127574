import React from 'react'
import { Button, OverlayTrigger, Spinner, Tooltip } from 'react-bootstrap'
import { fetchDocument, openDocumentTab } from '../../../helper/DocumentHelper'
import { IQuoteRecord } from '../../../types/Quote'
import Translation from '../../../types/Translation'
import { GenericTable } from '../../content/GenericTable'
import CreditCardModal from '../../content/modal/CreditCardModal'
import InvoiceModal from '../../content/modal/InvoiceModal'
import ModalCancel from '../../content/modal/ModalCancel'
import ModalModify from '../../content/modal/ModalModify'
import withTranslations from '../../hoc/withTranslations'
import * as styles from './QuoteTable.module.scss'
import { getStatus } from './StatusHelper'

class QuoteTable extends React.Component<QuoteTableProps> {
    createHandleDocument = (documentId: string) => async () => {
        await this.handleDocument(documentId)
    }

    handleDocument = async (documentId: string) => {
        const documentUrl = await fetchDocument(documentId)

        if (documentUrl) {
            openDocumentTab(documentUrl)
        }
    }

    renderQuote = (quote: IQuoteRecord, index: number) => {
        const { t, contactUrl } = this.props

        const totalPrice = quote.currency + ' ' + quote.totalStr

        const orderBtns = (
            <>
                <CreditCardModal itemNumber={quote.id} totalPrice={totalPrice} itemId={quote.id} isQuote={true} />
                <InvoiceModal
                    quoteNumber={quote.documentId}
                    totalPrice={totalPrice}
                    quoteId={quote.id}
                    onInvoiceComplete={this.props.onDataUpdate}
                />
            </>
        )

        const modifyBtns = (
            <>
                <ModalModify quoteDocumentId={quote.documentId} quoteId={quote.id} onModifyComplete={this.props.onDataUpdate} />
                <ModalCancel
                    quoteDocumentId={quote.documentId}
                    quoteId={quote.id}
                    contactLinkUrl={contactUrl}
                    onCancelComplete={this.props.onDataUpdate}
                />
            </>
        )

        const handleDocument = this.createHandleDocument(quote.documentId)

        const statusIsOpen = quote.status === 'Open'

        const detailTooltip = (
            <OverlayTrigger
                key={'btn-trigger'}
                placement={'top'}
                overlay={<Tooltip id={'tooltip-btn'}>{t('template', 'quotes.tooltip.text.showquote')}</Tooltip>}
            >
                <Button onClick={handleDocument} size={'sm'}>
                    <span className={'pti-file-text-o'} />
                </Button>
            </OverlayTrigger>
        )

        const key = `quote-row-${index}`

        return (
            <tr key={key}>
                <td>{quote.title}</td>
                <td>{quote.expiration}</td>
                <td>{getStatus(quote.status, t)}</td>
                <td>
                    {quote.currency} {quote.totalStr}
                </td>
                <td className={'text-center'}>{quote.documentId && detailTooltip}</td>
                <td className={'text-center'}>{statusIsOpen && orderBtns}</td>
                <td className={'text-center'}>{statusIsOpen && modifyBtns}</td>
            </tr>
        )
    }

    render() {
        const { t } = this.props
        const tableRows = this.props.quotes.map(this.renderQuote)

        let content: any = (
            <div className={styles.spinnerContainer}>
                <Spinner animation={'border'} />
            </div>
        )

        if (this.props.success) {
            if (this.props.quotes.length > 0) {
                content = (
                    <>
                        <GenericTable>
                            <thead>
                                <tr>
                                    <th>{t('template', 'quotes.table.head.quotenumber')}</th>
                                    <th>{t('template', 'quotes.table.head.expiration')}</th>
                                    <th>{t('template', 'quotes.table.head.status')}</th>
                                    <th>{t('template', 'quotes.table.head.price')}</th>
                                    <th className={'text-center'}>{t('template', 'quotes.table.head.details')}</th>
                                    <th className={'text-center'}>{t('template', 'quotes.table.head.order')}</th>
                                    <th className={'text-center'}>{t('template', 'quotes.table.head.modify')}</th>
                                </tr>
                            </thead>
                            <tbody>{tableRows}</tbody>
                        </GenericTable>
                    </>
                )
            } else {
                content = t('template', 'quotes.table.data.empty')
            }
        }

        return (
            <>
                <h2>{t('template', 'quotes.title.quotes')}</h2>
                {content}
            </>
        )
    }
}

export default withTranslations(QuoteTable)

interface QuoteTableProps extends Translation {
    contactUrl: string
    onDataUpdate: any
    quotes: any
    success: boolean
}
