import * as React from 'react'

const HorizontalRule: React.FunctionComponent<HorizontalRuleProps> = (props: HorizontalRuleProps) => {
    const { spaceBottom } = props

    const classes = spaceBottom ? 'mb-5' : ''

    return <hr className={classes} />
}

export default HorizontalRule

interface HorizontalRuleProps {
    spaceBottom?: boolean
}
