import * as React from 'react'
import { Col, Row } from 'react-bootstrap'
import { IReportPreview } from '../../../types/InternshipReport'
import Translation from '../../../types/Translation'
import withTranslations from '../../hoc/withTranslations'
import { QuoteText } from '../Citation'

import ContentButton from '../ContentButton'
import ContentImage from '../ContentImage'
import * as styles from './InternshipReportListRecord.module.scss'

const InternshipReportListRecord: React.FunctionComponent<InternshipReportListRecordProps> = (
    props: InternshipReportListRecordProps,
) => {
    const { internshipReportListRecord, t } = props
    const { name, photo, quote, author, reportUrl } = internshipReportListRecord

    const btnText = `${t('template', 'meta.title.internshipreport')} ${name}`

    return (
        <Row className={styles.listRecord}>
            <Col sm={4} className={styles.imageContainer}>
                {photo && <ContentImage image={photo} fluid={true} />}
            </Col>

            <Col sm={8}>
                <p>{QuoteText(quote)}</p>

                <p className={styles.spaceBottom}>
                    <em>{author}</em>
                </p>

                <Row>
                    <Col sm={6}>
                        <ContentButton to={reportUrl} block={true}>
                            {btnText}
                        </ContentButton>
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}

export default withTranslations(InternshipReportListRecord)

interface InternshipReportListRecordProps extends Translation {
    internshipReportListRecord: IReportPreview
}
