import * as React from 'react'
import { GlossaryRecord, TGlossaryData } from '../../../types/Glossary'
import * as styles from './Glossary.module.scss'
import GlossaryNavigation from './GlossaryNavigation'
import GlossarySection from './GlossarySection'

const Glossary: React.FunctionComponent<GlossaryProps> = (props: GlossaryProps) => {
    const { keyType, entries } = props
    const glossaryKeys = keyType === 'numeric' ? generateGlossaryNumericKey(entries.length) : GLOSSARY_ALPHABET

    const glossaryData: TGlossaryData = {}
    if (keyType === 'numeric') {
        for (let i = 0; i < entries.length; i++) {
            glossaryData[glossaryKeys[i]] = [entries[i]]
        }
    } else {
        glossaryKeys.forEach((key) => {
            glossaryData[key] = []
        })
        for (const entry of entries) {
            if (entry.title) {
                const indexKey = entry.title.charAt(0).toUpperCase()
                glossaryData[indexKey]?.push(entry)
            }
        }
    }

    const glossarySections = []
    for (const glossaryKey of Object.keys(glossaryData)) {
        if (glossaryData[glossaryKey].length) {
            const sortedGlossarItems = glossaryData[glossaryKey].sort(sortGlossaryRecordsByTitle)

            glossarySections.push(
                <GlossarySection glossarKey={glossaryKey} glossarItems={sortedGlossarItems} key={`section-${glossaryKey}`} />,
            )
        }
    }

    return (
        <>
            <GlossaryNavigation glossaryData={glossaryData} />
            <div className={styles.sections}>{glossarySections}</div>
        </>
    )
}

const GLOSSARY_ALPHABET = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('')

const generateGlossaryNumericKey = (length: number) =>
    Array(length) // generates empty array with the given length
        .fill(1) // starting from 1
        .map((x, y) => `${x + y}`) // adding current index

const sortGlossaryRecordsByTitle = (a: GlossaryRecord, b: GlossaryRecord) => a.title.localeCompare(b.title)

interface GlossaryProps {
    keyType: 'alphabet' | 'numeric'
    entries: GlossaryRecord[]
}

export default Glossary
