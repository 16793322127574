import * as React from 'react'
import { Col, Image, Row } from 'react-bootstrap'
import AnyLink from '../AnyLink'
import * as styles from './ContactBox.module.scss'
import ContentButton from './ContentButton'

const ContactBox: React.FunctionComponent<ContactBoxProps> = (props: ContactBoxProps) => {
    const { title, position, linkedin, xing, imgSrc, email } = props

    let socialLinks: any

    if (linkedin || xing) {
        socialLinks = (
            <ul className={styles.socialNetworks}>
                {linkedin && (
                    <li>
                        <AnyLink to={`https://www.linkedin.com/in/${linkedin}`} rel="noopener">
                            <i className="pti-linkedin-square" />
                        </AnyLink>
                    </li>
                )}
                {xing && (
                    <li>
                        <AnyLink to={`https://www.xing.com/profile/${xing}`} rel="noopener">
                            <i className="pti-xing-square" />
                        </AnyLink>
                    </li>
                )}
            </ul>
        )
    }

    return (
        <Row className={styles.contactBox}>
            <Col sm={3} className="text-left">
                <Image fluid={true} src={imgSrc} className={styles.contactImg} />
            </Col>
            <Col sm={6}>
                <div>
                    <strong>{title}</strong>
                </div>
                <span className={styles.position}>{position}</span>
            </Col>
            <Col sm={3}>
                <ContentButton size="sm" block={true} href={`mailto:${email}`}>
                    Send an e-mail
                </ContentButton>
                {socialLinks}
            </Col>
        </Row>
    )
}

interface ContactBoxProps {
    imgSrc: string
    title: string
    position: string
    linkedin?: string
    xing?: string
    email: string
}

export default ContactBox
