import React from 'react'
import { Col } from 'react-bootstrap'
import { IDownloadData, IProductLineLicencesState } from '../../../types/LicenseKit'
import PageContext from '../../../types/PageContext'
import Translation from '../../../types/Translation'
import { IVersion } from '../../../types/Version'
import withTranslations from '../../hoc/withTranslations'
import CollapsableRow from './CollapsableRow'
import DownloadKitLicense from './DownloadKitLicense'
import LicensesHeadRow from './LicensesHeadRow'
import ProductLineLicences from './ProductLineLicences'

class FourHeights extends React.Component<FourHeightsProps, FourHeightsState> {
    renderLicences = (downloadData: IDownloadData[], state: IProductLineLicencesState) => {
        const { t, pageContext } = this.props

        const { selectedVersion } = state

        const collapsableRows = downloadData.map((data, i: number) => {
            let uncollapsed = false

            if (i === 0) {
                uncollapsed = true
            }

            const id = `fh-active-row-${i}`

            return (
                <CollapsableRow key={id} id={id} name={data.articleName} uncollapsed={uncollapsed}>
                    <DownloadKitLicense
                        downloadData={data}
                        showEdition={true}
                        language={pageContext.language}
                        selectedVersion={selectedVersion}
                    />
                </CollapsableRow>
            )
        })

        let content: any = null

        if (Array.isArray(downloadData)) {
            if (downloadData.length > 0) {
                content = collapsableRows
            } else {
                content = <div>{t('template', 'licenses.info.message.emptylicense')}</div>
            }
        }

        return (
            <>
                <LicensesHeadRow>
                    <Col sm={6}>{t('template', 'licenses.column.title.description')}</Col>
                </LicensesHeadRow>

                {content}
            </>
        )
    }

    render() {
        const { t } = this.props

        return (
            <>
                <ProductLineLicences
                    versions={this.props.fourHeightsVersions}
                    downloadData={this.props.fourHeightsActive}
                    title={t('template', 'licenses.productgeneration.fourheights.title')}
                    pageContext={this.props.pageContext}
                    renderData={this.renderLicences}
                    disableAllVersions={true}
                />

                <ProductLineLicences
                    versions={this.props.fourHeightsVersions}
                    downloadData={this.props.fourHeightsEval}
                    title={t('template', 'licenses.productgeneration.fourheights.eval.title')}
                    pageContext={this.props.pageContext}
                    renderData={this.renderLicences}
                    evalVersions={true}
                />
            </>
        )
    }
}

export default withTranslations(FourHeights)

interface FourHeightsProps extends Translation {
    pageContext: PageContext<any>
    fourHeightsActive: IDownloadData[] | null
    fourHeightsEval: IDownloadData[] | null
    fourHeightsVersions: IVersion[] | null
}

interface FourHeightsState {}
