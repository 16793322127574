import * as React from 'react'
import { Col, Row, Table } from 'react-bootstrap'
import { ISearchResults } from '../../../types/Search'
import SearchResultRow from './SearchResultRow'
import * as styles from './SearchResults.module.scss'

const SearchResults: React.FunctionComponent<SearchResultsProps> = (props: SearchResultsProps) => {
    const { searchResults } = props

    return (
        <Row>
            <Col sm={12}>
                <Table striped={true} className={styles.searchResults}>
                    <tbody>
                        {searchResults.items.map((result, i: number) => (
                            <SearchResultRow key={`row-${i}`} result={result} />
                        ))}
                    </tbody>
                </Table>
            </Col>
        </Row>
    )
}

interface SearchResultsProps {
    searchResults: ISearchResults
}

export default SearchResults
