import * as React from 'react'
import { Col, Row } from 'react-bootstrap'
import Translation from '../../types/Translation'
import withTranslations from '../hoc/withTranslations'
import { IImage } from '../../types/Image'
import ColoredBox from './ColoredBox'
import ContentButton from './ContentButton'
import ContentImage from './ContentImage'

const ReadMoreBox: React.FunctionComponent<ReadMoreBoxProps> = (props: ReadMoreBoxProps) => {
    const { img, title, children, to, t } = props

    return (
        <Row className="h-100">
            <Col sm={12} className="d-flex flex-column">
                {img && (
                    <div className="text-center">
                        <ContentImage image={img} fluid={true} />
                    </div>
                )}
                <ColoredBox pdftColor="gray" className="pt-3 flex-grow-1">
                    <div className="h-100 d-flex flex-column">
                        <div className="mb-2 text-center">
                            <p>
                                <strong>{title}</strong>
                            </p>
                            {children}
                        </div>
                        <div className="mt-auto text-center">
                            <ContentButton to={to} size="sm" pdftColor="mustardyellow">
                                {t('template', 'general.link.text.readmore')}
                            </ContentButton>
                        </div>
                    </div>
                </ColoredBox>
            </Col>
        </Row>
    )
}

interface ReadMoreBoxProps extends Translation {
    title: string
    children: React.ReactNode
    img?: IImage
    to: string
}

export default withTranslations(ReadMoreBox)
