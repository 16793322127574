import * as React from 'react'
import { Button, Form, InputGroup } from 'react-bootstrap'
import Translation from '../../../types/Translation'
import withTranslations from '../../hoc/withTranslations'

class SearchBar extends React.Component<SearchBarProps, SearchBarState> {
    handleSubmit = (e: any) => {
        e.preventDefault()

        this.props.onSearchSubmit?.()
    }

    handleSearchChange = (e: any) => {
        const val = e.target.value
        this.props.onSearchChange?.(val)
    }

    render() {
        const { searchQuery, t } = this.props

        return (
            <Form onSubmit={this.handleSubmit} inline={true}>
                <InputGroup className="w-100">
                    <Form.Control
                        type="search"
                        value={searchQuery}
                        onChange={this.handleSearchChange}
                        placeholder={t('template', 'general.label.search')}
                    />

                    <InputGroup.Append>
                        <Button type="submit">
                            {t('template', 'general.label.search')}
                            <span className="pti-search" />
                        </Button>
                    </InputGroup.Append>
                </InputGroup>
            </Form>
        )
    }
}

interface SearchBarProps extends Translation {
    onSearchSubmit?: () => void
    onSearchChange?: (searchQuery: string) => void
    searchQuery: string
}

interface SearchBarState {
    searchQuery: string
}

export default withTranslations(SearchBar)
