import React from 'react'
import { Alert, Col, Row } from 'react-bootstrap'
import Select from 'react-select'
import { IFilterOption } from '../../../types/Common'
import { IDownloadData, IProductLineLicencesState } from '../../../types/LicenseKit'
import PageContext from '../../../types/PageContext'
import Translation from '../../../types/Translation'
import { IVersion } from '../../../types/Version'
import withTranslations from '../../hoc/withTranslations'
import * as styles from './ProductLineLicences.module.scss'

class ProductLineLicences extends React.Component<ProductLineLicencesProps, IProductLineLicencesState> {
    constructor(props: ProductLineLicencesProps) {
        super(props)

        const defaultVersionOption = this.getDefaultVersionOption()

        this.state = {
            selectedVersion: defaultVersionOption,
        }
    }

    getDefaultVersion(versions: IVersion[]) {
        const version = versions[0]
        return version.label
    }

    getDefaultVersionOption = () => {
        let newVersionOption: IFilterOption = { value: '', label: '' }
        const versions = this.getVersions()
        const versionOptions = this.getVersionOptions()
        let foundOption: any

        if (versions) {
            const defaultVersion = this.getDefaultVersion(versions)

            foundOption = versionOptions.find((versionOpt) => versionOpt.value === defaultVersion)
        }

        if (foundOption) {
            newVersionOption = foundOption
        }

        return newVersionOption
    }

    handleVersionChange = (opt: any) => {
        this.setState({
            selectedVersion: opt,
        })
    }

    getVersionOptions = () => {
        const versions = this.getVersions()
        if (versions) {
            return this.createVersionOptions(versions)
        }
        return []
    }

    getVersions = () => {
        if (this.props.evalVersions) {
            return this.props.versions?.filter((version) => version.showForEval)
        }
        return this.props.versions
    }

    createVersionOptions = (versions: IVersion[]) => {
        const versionOptions = versions.map((version) => {
            const label = `Version ${version.label}`

            const newFilterOption: IFilterOption = {
                value: version.label,
                label,
            }

            return newFilterOption
        })

        if (!this.props.evalVersions && !this.props.disableAllVersions) {
            const allVersionsOption: IFilterOption = {
                value: 'all',
                label: this.props.t('template', 'licences.version.all'),
            }

            versionOptions.push(allVersionsOption)
        }

        return versionOptions
    }

    getFilteredDownloadData = () => {
        const { downloadData } = this.props
        const { selectedVersion } = this.state

        if (selectedVersion.value !== 'all') {
            return downloadData?.map((data) => {
                const kits = data.kits.filter((kit) => kit.version === selectedVersion.value || !kit.version)

                const descriptions = data.descriptions?.filter((description) => description.version === selectedVersion.value)
                return {
                    ...data,
                    descriptions,
                    kits,
                }
            })
        }

        return downloadData
    }

    render() {
        const { selectedVersion } = this.state

        const { t, downloadData, versions, hideSectionOnEmpty } = this.props

        const versionOptions = this.getVersionOptions()
        const filteredDownloadData = this.getFilteredDownloadData()

        let content: any

        let error = false

        if (!Array.isArray(downloadData) || !Array.isArray(versions)) {
            error = true
        }

        if (error) {
            content = <Alert variant={'danger'}>{t('form', 'general.form.error')}</Alert>
        }

        if (Array.isArray(downloadData) && downloadData.length === 0 && hideSectionOnEmpty) {
            return <></>
        }

        return (
            <>
                <Row className={styles.titleRow}>
                    <Col md={9}>
                        <h2 className={styles.title}>{this.props.title}</h2>
                    </Col>

                    <Col md={3}>
                        <Select value={selectedVersion} onChange={this.handleVersionChange} options={versionOptions} />
                    </Col>
                </Row>

                {content}

                {!error && this.props.renderData(filteredDownloadData, this.state)}
            </>
        )
    }
}

export default withTranslations(ProductLineLicences)

interface ProductLineLicencesProps extends Translation {
    downloadData: IDownloadData[] | null
    versions: IVersion[] | null
    pageContext: PageContext<any>
    title: string
    renderData: any
    evalVersions?: boolean
    disableAllVersions?: boolean
    hideSectionOnEmpty?: boolean
}
