import * as React from 'react'
import { Col, Row } from 'react-bootstrap'
import { ISuccessStoryListRecord } from '../../../types/SuccessStory'
import ContentButton from '../ContentButton'
import ImageLink from '../ImageLink'
import * as styles from './SuccessStoryListItem.module.scss'

const SuccessStoryListItem: React.FunctionComponent<SuccessStoryListItemProps> = (props: SuccessStoryListItemProps) => {
    const { listRecord } = props
    const { customerImage, successStoryUrl, teaserText, linkText } = listRecord

    let successStoryImg = <div />

    if (customerImage) {
        successStoryImg = <ImageLink to={successStoryUrl} image={customerImage} />
    }

    return (
        <Col sm={4} className={styles.successStoryContainer}>
            <Row>
                <Col sm={{ span: 6, offset: 3 }}>
                    <div className={styles.storyImgContainer}>{successStoryImg}</div>
                </Col>
            </Row>
            <Row className={styles.teaser}>
                <Col sm={12} className={styles.teaserTextContainer}>
                    <p>{teaserText}</p>
                </Col>
            </Row>
            <Row>
                <Col sm={12}>
                    <ContentButton size={'sm'} pdftColor={'darkolive'} to={successStoryUrl} block={true}>
                        {linkText}
                    </ContentButton>
                </Col>
            </Row>
        </Col>
    )
}

export default SuccessStoryListItem

interface SuccessStoryListItemProps {
    listRecord: ISuccessStoryListRecord
}
