import * as React from 'react'
import PageContext from '../../../types/PageContext'

import { IFilterOption } from '../../../types/Common'
import { ICountries } from '../../../types/Country'
import { IAccountFormPluginData } from '../../../types/Plugin'
import AccountProfile from '../../mypdftools/account/AccountProfile'

class MyPDFAccountPlugin extends React.Component<Props, {}> {
    render() {
        const { pageContext, pluginData } = this.props
        const { language } = pageContext

        let countries: ICountries = { en: [], de: [], fr: [] }
        let languageOpts: IFilterOption[] = []
        let countryOpts: IFilterOption[] = []

        if (pluginData) {
            countries = pluginData.countries
            countryOpts = pluginData.countries[language]
            languageOpts = pluginData.languageOptions[language]
        }

        return (
            <AccountProfile
                countryOptions={countryOpts}
                countries={countries}
                languageOptions={languageOpts}
                pageContext={pageContext}
            />
        )
    }
}

export default MyPDFAccountPlugin

interface Props {
    config: any
    pageContext: PageContext<any>
    pluginData: IAccountFormPluginData
}
