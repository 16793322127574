import * as React from 'react'
import PageContext from '../../../types/PageContext'
import Search from '../search/Search'

class SearchPlugin extends React.Component<Props, {}> {
    render() {
        const { pageContext } = this.props

        return <Search pageContext={pageContext} />
    }
}

export default SearchPlugin

interface Props {
    config: any
    pageContext: PageContext<any>
    pluginData: any
}
