import React from 'react'
import { Alert, Col, Row } from 'react-bootstrap'
import { formatDate } from '../../../helper/DateHelper'
import { IFilterOption } from '../../../types/Common'
import { LangKey } from '../../../types/Languages'
import { IDescription, IDownloadData, IKit, IResponseLicenseKey } from '../../../types/LicenseKit'
import { IResponseEvalDownload } from '../../../types/Product'
import { ILicenseTableData, ILicenseTableRow } from '../../../types/Table'
import Translation from '../../../types/Translation'
import axios from '../../../utils/axios'
import LicenseKey from '../../content/download/LicenseKey'
import LicenseTable from '../../content/download/LicenseTable'
import ProductKitTable from '../../content/download/ProductKitTable'
import withTranslations from '../../hoc/withTranslations'
import * as styles from './DownloadKitLicense.module.scss'

class DownloadKitLicense extends React.Component<DownloadKitLicenseProps, DownloadKitLicenseState> {
    constructor(props: DownloadKitLicenseProps) {
        super(props)

        const licenses = this.createLicenseTableRows()

        this.state = {
            licenses,
            errorMsg: '',
        }
    }

    parseDescription = (descriptionRecord: IDescription) => {
        const { parameters } = descriptionRecord
        const description: JSX.Element[] = []

        const descriptionWithPlaceholders = descriptionRecord.description.replace(/^ +/gm, '')

        let textBefore = ''
        let textRemaining: string = descriptionWithPlaceholders

        let i = 0

        for (const param of parameters) {
            ;[textBefore, textRemaining] = textRemaining.split(param.placeholder)

            description.push(<span key={`i-${i}`}>{textBefore}</span>)

            const handleClick = this.createHandleLinkClick(param.id, param.uri)

            description.push(
                <button onClick={handleClick} className={`btn btn-link link-file ${styles.linkBtn}`} key={param.uri}>
                    {param.title}
                </button>,
            )

            i++
        }

        description.push(<span key={'last'}>{textRemaining}</span>)

        return description
    }

    createHandleLinkClick = (manualId: string, uri: string) => () => this.handleLinkClick(manualId, uri)

    handleLinkClick = async (manualId: string, uri: string) => {
        const linkUrl = await this.fetchManualUrl(manualId, uri)

        if (linkUrl) {
            Object.assign(document.createElement('a'), { target: '_blank', href: linkUrl }).click()
        }
    }

    fetchManualUrl = async (manualId: string, uri: string) => {
        const url = `/api/v1.0/Url/kitdownload/?kitId=${encodeURIComponent(manualId)}&kitUri=${encodeURIComponent(uri)}`

        try {
            const response = await axios.get(url)

            if (response && response.data) {
                return response.data.url
            }

            return null
        } catch (error: any) {
            console.log(error)
            if (error.response) {
                console.log(`status code: ${error.response.status}`)
                console.log(error.response.data)
            }

            this.displayError(this.props.t('form', 'general.form.error'))
        }
    }

    fetchEvalDownloadData = async (evalFormData: any) => {
        const { productCode, platformCode } = evalFormData

        const url = `/api/v1.0/Kits/EvalDownloadData/${productCode}/${platformCode}`

        try {
            const response = await axios.get(url)

            if (response.data) {
                return response.data as IResponseEvalDownload
            }

            return null
        } catch (error: any) {
            console.log(error)
            if (error.response) {
                console.log(`status code: ${error.response.status}`)
                console.log(error.response.data)
            }

            this.displayError(this.props.t('form', 'general.form.error'))
        }
    }

    fetchKitDownload = async (kit: IKit) => {
        const url = `/api/v1.0/Url/kitdownload/?kitId=${encodeURIComponent(kit.id)}&kitUri=${encodeURIComponent(kit.uri)}`

        try {
            const response = await axios.get(url)

            if (response && response.data) {
                const binaryDownloadUrl = response.data.url

                if (binaryDownloadUrl) {
                    window.open(binaryDownloadUrl, '_self')
                }
            }
        } catch (error: any) {
            console.log(error)

            if (error.response) {
                console.log(`status code: ${error.response.status}`)
                console.log(error.response.data)
            }

            this.displayError(this.props.t('form', 'general.form.error'))
        }
    }

    displayError(errorMsg: string) {
        this.setState({
            errorMsg,
        })
    }

    createFetchLicense = (licenseId: string) => async () => this.fetchLicense(licenseId)

    fetchLicense = async (licenseId: string) => {
        const url = `/api/v1.0/Kits/Licensekey/${licenseId}`

        try {
            const response = await axios.get(url)

            if (response && response.data) {
                const key: IResponseLicenseKey = response.data
                this.saveLicenseKey(licenseId, key)
            }
        } catch (error: any) {
            console.log(error)

            if (error.response) {
                console.log(`status code: ${error.response.status}`)
                console.log(error.response.data)
            }
        }
    }

    saveLicenseKey = (licenseId: string, key: IResponseLicenseKey) => {
        const licenseKeyRow = this.createLicenseKeyTableRow(licenseId, key.key)
        const { licenses } = this.state

        const indexRow = licenses.findIndex((row) => row.licenseId === licenseId)

        const clonedLicenses = [...licenses]

        if (indexRow !== -1) {
            const insertIndex = indexRow + 1
            const licenseRow = clonedLicenses[insertIndex]

            if (licenseRow) {
                if (!licenseRow.keyRow) {
                    clonedLicenses.splice(insertIndex, 0, licenseKeyRow)

                    this.setState({
                        licenses: clonedLicenses,
                    })
                }
            } else {
                clonedLicenses.push(licenseKeyRow)

                this.setState({
                    licenses: clonedLicenses,
                })
            }
        }
    }

    createLicenseKeyTableRow = (licenseId: string, licenseKey: string) => {
        const data = [<LicenseKey key={`license-key-${licenseId}`} licenseKey={licenseKey} />]

        const { licenses } = this.state

        const license = licenses.find((row) => row.licenseId === licenseId)

        const newLicenseRow: ILicenseTableRow = {
            data,
            keyRow: true,
            licenseId,
            clickHandler: null,
            version: license?.version || '',
        }

        return newLicenseRow
    }

    createLicenseTableRows = () => {
        const { downloadData } = this.props
        const { licenses } = downloadData

        const rows: ILicenseTableRow[] = []

        licenses.forEach((license, i: number) => {
            let fetchLicense: any = () => null

            if (license.id) {
                fetchLicense = this.createFetchLicense(license.id)

                let edition: any = <td key={`td-edition-${i}`}>{license.edition}</td>

                if (!this.props.showEdition) {
                    edition = null
                }

                const newLicenseRow: ILicenseTableRow = {
                    data: [
                        <td key={`td-desc-${i}`}>{license.description}</td>,
                        edition,
                        <td key={`td-quantity-${i}`}>{license.count}</td>,
                        <td key={`td-expiration-${i}`}>
                            {license.maintenanceExpirationDate &&
                                formatDate(license.maintenanceExpirationDate, this.props.language)}
                        </td>,
                    ],
                    keyRow: false,
                    licenseId: license.id,
                    clickHandler: fetchLicense,
                    maintenanceExpirationDate: license.maintenanceExpirationDate || '',
                    version: license.version,
                }

                rows.push(newLicenseRow)
            }
        })

        return rows
    }

    getFilteredLicenses = () => {
        const { licenses } = this.state

        const { selectedVersion } = this.props

        return licenses.filter((license) => {
            if (!license.version) {
                return true
            }

            return license.version === selectedVersion.value
        })
    }

    render() {
        const { t, downloadData, showEdition, language } = this.props

        const { errorMsg } = this.state

        const { descriptions, kits } = downloadData

        let content: any = null

        const rows = this.getFilteredLicenses()

        let editionTh: any = ''

        if (showEdition) {
            editionTh = <th> {t('template', 'licence.table.head.edition')} </th>
        }

        const heads = [
            <th key={'th-license'}>{t('template', 'licence.table.head.licence')}</th>,
            editionTh,
            <th key={'th-quantity'} className={styles.thQuantity}>
                {t('template', 'licence.table.head.quantity')}
            </th>,
            <th key={'th-maintenance'} className={styles.thMaintenance} colSpan={2}>
                {t('template', 'licence.table.head.maintenance')}
            </th>,
        ]

        const tableData: ILicenseTableData = {
            rows,
            heads,
        }

        if (kits && kits.length > 0) {
            content = (
                <>
                    <ProductKitTable
                        productKits={downloadData.kits}
                        language={language}
                        onDownloadKit={this.fetchKitDownload}
                        maintenanceActiveUntil={downloadData.maintenanceActiveUntil}
                    />

                    <LicenseTable tableData={tableData} />
                </>
            )
        } else if (kits) {
            content = <Alert variant={'danger'}>{this.props.t('template', 'eval.download.error.emptykits')}</Alert>
        }

        if (errorMsg) {
            content = null
        }

        const manualDescriptions: JSX.Element[][] = []

        if (descriptions && descriptions.length > 0) {
            manualDescriptions.push(this.parseDescription(descriptions[descriptions.length - 1]))
        }

        return (
            <>
                <Row>
                    <Col sm={12}>
                        <p className={styles.manualDescription}>{manualDescriptions}</p>

                        {errorMsg && <Alert variant={'danger'}>{errorMsg}</Alert>}

                        {content}
                    </Col>
                </Row>
            </>
        )
    }
}

export default withTranslations(DownloadKitLicense)

interface DownloadKitLicenseProps extends Translation {
    onSubmit?: any
    downloadData: IDownloadData
    showEdition?: boolean
    language: LangKey
    selectedVersion: IFilterOption
}

interface DownloadKitLicenseState {
    licenses: ILicenseTableRow[]
    errorMsg: string
}
