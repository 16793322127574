import * as React from 'react'
import { Col, Row, Form } from 'react-bootstrap'

import Select from 'react-select'
import { ISuccessStoryListRecord } from '../../../types/SuccessStory'
import { IFilterOption } from '../../../types/Common'
import Translation from '../../../types/Translation'
import withTranslations from '../../hoc/withTranslations'
import SuccessStoryList from './SuccessStoryList'
import * as styles from './FilterableSuccessStoryList.module.scss'

class FilterableSuccessStoryList extends React.Component<FilterableSuccessStoryListProps, FilterableSuccessStoryListState> {
    constructor(props: FilterableSuccessStoryListProps) {
        super(props)

        const { productFilterOptions, countryFilterOptions } = props

        this.state = {
            filters: {
                customer: {
                    selectedOption: {
                        value: '',
                    },
                    filterFunction: filterByCustomer,
                },
                product: {
                    selectedOption: productFilterOptions[0],
                    filterFunction: filterByProduct,
                },
                country: {
                    selectedOption: countryFilterOptions[0],
                    filterFunction: filterByCountry,
                },
            },
        }
    }

    handleProductChange = (opt: any) => {
        this.setState({
            filters: {
                ...this.state.filters,
                product: {
                    ...this.state.filters.product,
                    selectedOption: opt,
                },
            },
        })
    }

    handleCountryChange = (opt: any) => {
        this.setState({
            filters: {
                ...this.state.filters,
                country: {
                    ...this.state.filters.country,
                    selectedOption: opt,
                },
            },
        })
    }

    handleCustomerChange = (opt: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            filters: {
                ...this.state.filters,
                customer: {
                    ...this.state.filters.customer,
                    selectedOption: { value: opt.target.value },
                },
            },
        })
    }

    filter() {
        const { filters } = this.state
        const { successStories } = this.props

        let resultSet = successStories

        type TFilterIndexKey = keyof typeof filters

        Object.keys(filters).forEach((key) => {
            const filterIndexKey = key as TFilterIndexKey
            const filter = filters[filterIndexKey]

            if (filter.selectedOption.value !== 'all' && filter.selectedOption.value !== '') {
                const newResultSet = resultSet.filter((successStory: ISuccessStoryListRecord) =>
                    filter.filterFunction(successStory, filter.selectedOption.value),
                )

                resultSet = newResultSet
            }
        })

        return resultSet
    }

    render() {
        const filteredSuccessStories = this.filter()

        const { filters } = this.state
        const { productFilterOptions, countryFilterOptions } = this.props

        const filterHeader = (
            <Form>
                <Row className={styles.header}>
                    <Col sm={4}>
                        <label>{this.props.t('template', 'general.filter.filterbycustomer.label')}</label>
                        <Form.Control
                            type="text"
                            value={filters.customer.selectedOption.value}
                            onChange={this.handleCustomerChange}
                        />
                    </Col>

                    <Col sm={4}>
                        <label>{this.props.t('template', 'general.filter.filterbyproduct.label')}</label>
                        <Select
                            value={filters.product.selectedOption}
                            onChange={this.handleProductChange}
                            options={productFilterOptions}
                            classNamePrefix={'pdft_select'}
                        />
                    </Col>

                    <Col sm={4}>
                        <label>{this.props.t('template', 'general.filter.filterbycountry.label')}</label>
                        <Select
                            value={filters.country.selectedOption}
                            onChange={this.handleCountryChange}
                            options={countryFilterOptions}
                            classNamePrefix={'pdft_select'}
                        />
                    </Col>
                </Row>
            </Form>
        )

        return <SuccessStoryList successStories={filteredSuccessStories} additionalHeader={filterHeader} />
    }
}

const filterByProduct = (successStory: ISuccessStoryListRecord, productCode: string) =>
    !!successStory.products?.find((product) => product.code === productCode)

const filterByCountry = (successStory: ISuccessStoryListRecord, country: string) => successStory.country === country

const filterByCustomer = (successStory: ISuccessStoryListRecord, searchTerm: string) =>
    successStory.companySearchName.includes(searchTerm.toLowerCase().replace(/[-+().,&/\s]/g, ''))

export default withTranslations(FilterableSuccessStoryList)

interface FilterableSuccessStoryListProps extends Translation {
    successStories: ISuccessStoryListRecord[]
    productFilterOptions: IFilterOption[]
    countryFilterOptions: IFilterOption[]
}

interface IFilter {
    selectedOption: {
        value: string
        label?: string
    }
    filterFunction: (r: ISuccessStoryListRecord, s: string) => boolean
}

interface FilterableSuccessStoryListState {
    filters: {
        customer: IFilter
        product: IFilter
        country: IFilter
    }
}
