import React from 'react'
import { Spinner } from 'react-bootstrap'
import { IOrderRecord } from '../../../types/Order'
import Translation from '../../../types/Translation'
import { GenericTable } from '../../content/GenericTable'
import withTranslations from '../../hoc/withTranslations'
import * as styles from './QuoteTable.module.scss'
import { getStatus } from './StatusHelper'

class OrderTable extends React.Component<OrderTableProps> {
    renderOrder = (order: IOrderRecord, index: number) => {
        const key = `order-row-${index}`

        return (
            <tr key={key}>
                <td>{order.date}</td>
                <td>
                    {order.currency} {order.totalStr}
                </td>
                <td>{getStatus(order.status, this.props.t)}</td>
                <td>{order.description}</td>
            </tr>
        )
    }

    render() {
        const { t } = this.props
        const tableRows = this.props.orders.map(this.renderOrder)

        let content: any = (
            <div className={styles.spinnerContainer}>
                <Spinner animation={'border'} />
            </div>
        )

        if (this.props.success) {
            if (this.props.orders.length > 0) {
                content = (
                    <>
                        <GenericTable>
                            <thead>
                                <tr>
                                    <th>{t('template', 'kits.table.head.date')}</th>

                                    <th>{t('template', 'quotes.table.head.price')}</th>

                                    <th>{t('template', 'quotes.table.head.status')}</th>

                                    <th>{t('template', 'quotes.table.head.description')}</th>
                                </tr>
                            </thead>
                            <tbody>{tableRows}</tbody>
                        </GenericTable>
                    </>
                )
            } else {
                content = t('template', 'orders.table.data.empty')
            }
        }

        return (
            <>
                <h2>{t('template', 'quotes.title.orders')}</h2>
                {content}
            </>
        )
    }
}

export default withTranslations(OrderTable)

interface OrderTableProps extends Translation {
    orders: IOrderRecord[]
    success: boolean
}
