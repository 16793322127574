import * as React from 'react'
import { IReportPreview } from '../../../types/InternshipReport'
import InternshipReportListRecord from './InternshipReportListRecord'

const InternshipReportList: React.FunctionComponent<InternshipReportListProps> = (props: InternshipReportListProps) => {
    const reportsList = props.reports.map((report, i) => (
        <InternshipReportListRecord internshipReportListRecord={report} key={`report-${i}`} />
    ))

    return <>{reportsList}</>
}

interface InternshipReportListProps {
    reports: IReportPreview[]
}

export default InternshipReportList
