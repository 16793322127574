import React from 'react'
import { Alert, Col, Form, Row, Spinner } from 'react-bootstrap'
import Select from 'react-select'
import { IFilterOption } from '../../../types/Common'
import Translation from '../../../types/Translation'

import ColoredBox from '../../content/ColoredBox'
import GenericForm from '../../content/form/GenericForm'
import withTranslations from '../../hoc/withTranslations'
import * as styles from './AccountForm.module.scss'

class AccountForm extends React.Component<AccountFormProps, AccountFormState> {
    constructor(props: AccountFormProps) {
        super(props)

        this.state = {
            form: {
                email: '',
                gender: 'Male',
                firstname: '',
                lastname: '',
                phone: '',
                language: this.props.defaultLangOpt,
                customer: {
                    name: '',
                    address: '',
                    address2: '',
                    city: '',
                    state: '',
                    zip: '',
                    country: this.props.defaultCountryOpt,
                },
                newsletter: false,
                updateFlash: false,
            },
            spinner: true,
        }
    }

    async componentDidMount() {
        if (this.props.onComponentMount) {
            await this.props.onComponentMount(this)
        }
    }

    handleSubmit = async () => {
        if (!this.props.disableSpinner) {
            this.setState({
                spinner: true,
            })
        }

        if (this.props.onSubmit) {
            await this.props.onSubmit(this.state)
        }

        if (!this.props.disableSpinner) {
            this.setState({
                spinner: false,
            })
        }
    }

    handleChange = (e: any) => {
        const field = e.target.name
        const newState = {
            form: {
                ...this.state.form,
                [field]: e.target.value,
            },
        }

        this.setState(newState)
    }

    handleCustomerChange = (e: any) => {
        const field = e.target.name
        const newState = {
            form: {
                ...this.state.form,
                customer: {
                    ...this.state.form.customer,
                    [field]: e.target.value,
                },
            },
        }

        this.setState(newState)
    }

    handleGender = (e: any) => {
        this.setState({
            form: {
                ...this.state.form,
                gender: e.target.value,
            },
        })
    }

    handleLanguageChange = (opt: any) => {
        this.setState({
            form: {
                ...this.state.form,
                language: opt,
            },
        })
    }

    handleCountryChange = (opt: any) => {
        this.setState({
            form: {
                ...this.state.form,
                customer: {
                    ...this.state.form.customer,
                    country: opt,
                },
            },
        })
    }

    handleCheckboxChange = (e: any) => {
        const field = e.target.name
        const checked = e.target.checked

        this.setState({
            form: {
                ...this.state.form,
                [field]: checked,
            },
        })
    }

    render() {
        const { t, countryOptions } = this.props

        const maxLengthAddress = 50
        const maxLengthName = 40
        const maxLengthEmail = 254
        const maxLengthTelephone = 30
        const maxLengthCompany = 40
        const maxLengthZip = 16
        const maxLengthCity = 32

        const { country, address, address2, state, city, zip, name } = this.state.form.customer

        const { email, gender, firstname, lastname, language, newsletter, updateFlash, phone } = this.state.form

        const {
            errorMsg,
            successMsg,
            languageOptions,
            emailAddrInfo,
            submitBtnText,
            additionalInfo,
            disableSpinner,
            displayUpdateFlash,
        } = this.props

        let colorBoxContent = (
            <div className={styles.spinnerContainer}>
                <Spinner animation={'border'} />
            </div>
        )

        if (!this.state.spinner || disableSpinner) {
            colorBoxContent = (
                <Row>
                    <Col sm={6}>
                        <Form.Group className={styles.salutation} controlId={'form.salutation'}>
                            <Form.Label>{t('form', 'general.field.salutation.label')}</Form.Label>

                            <div className={`d-flex align-items-center ${styles.salutationRadios}`}>
                                <Form.Check
                                    type={'radio'}
                                    label={t('form', 'general.salutation.mister')}
                                    id={'radio-male'}
                                    name="gender"
                                    inline={true}
                                    value={'Male'}
                                    defaultChecked={gender === 'Male'}
                                    onChange={this.handleGender}
                                />

                                <Form.Check
                                    type={'radio'}
                                    label={t('form', 'general.salutation.misses')}
                                    id={'radio-female'}
                                    name="gender"
                                    value={'Female'}
                                    inline={true}
                                    defaultChecked={gender === 'Female'}
                                    onChange={this.handleGender}
                                />
                            </div>
                        </Form.Group>
                    </Col>
                    <Col sm={6} />
                    <Col sm={6}>
                        <Form.Group controlId={'form.email'}>
                            <Form.Label>
                                {t('form', 'general.field.email.label')}
                                <span>*</span>
                            </Form.Label>
                            <Form.Control
                                type="email"
                                name={'email'}
                                required={true}
                                onChange={this.handleChange}
                                value={email}
                                maxLength={maxLengthEmail}
                                autoComplete="username"
                            />
                            <Form.Control.Feedback type="invalid">
                                {t('form', 'form.validate.email.invalid')}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col sm={6} className={styles.emailInfo}>
                        <p>{emailAddrInfo}</p>
                    </Col>
                    <Col sm={6}>
                        <Form.Group controlId={'form.firstname'}>
                            <Form.Label>
                                {t('form', 'general.field.firstname.label')}
                                <span>*</span>
                            </Form.Label>
                            <Form.Control
                                type={'text'}
                                name={'firstname'}
                                required={true}
                                onChange={this.handleChange}
                                value={firstname}
                                maxLength={maxLengthName}
                                autoComplete="given-name"
                            />
                            <Form.Control.Feedback type="invalid">
                                {t('form', 'form.validate.firstname.invalid')}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col sm={6}>
                        <Form.Group controlId={'form.lastname'}>
                            <Form.Label>
                                {t('form', 'general.field.lastname.label')}
                                <span>*</span>
                            </Form.Label>
                            <Form.Control
                                type={'text'}
                                name={'lastname'}
                                required={true}
                                onChange={this.handleChange}
                                value={lastname}
                                maxLength={maxLengthName}
                                autoComplete="family-name"
                            />
                            <Form.Control.Feedback type="invalid">
                                {t('form', 'form.validate.lastname.invalid')}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>

                    <Col sm={6}>
                        <Form.Group controlId={'form.company'}>
                            <Form.Label>
                                {t('form', 'general.field.company.label')}
                                <span>*</span>
                            </Form.Label>
                            <Form.Control
                                type={'text'}
                                name={'name'}
                                onChange={this.handleCustomerChange}
                                value={name}
                                required={true}
                                maxLength={maxLengthCompany}
                                autoComplete="organization"
                            />
                            <Form.Control.Feedback type="invalid">
                                {t('form', 'form.validate.company.invalid')}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>

                    <Col sm={6} />

                    <Col sm={6}>
                        <Form.Group controlId={'form.address'}>
                            <Form.Label>
                                {t('form', 'general.field.address.label')}
                                <span>*</span>
                            </Form.Label>

                            <Form.Control
                                type={'text'}
                                style={{
                                    marginBottom: '5px',
                                }}
                                name={'address'}
                                onChange={this.handleCustomerChange}
                                required={true}
                                value={address}
                                maxLength={maxLengthAddress}
                                autoComplete="address-line1"
                            />
                            <Form.Control
                                type={'text'}
                                name={'address2'}
                                onChange={this.handleCustomerChange}
                                value={address2 || undefined}
                                maxLength={maxLengthAddress}
                                autoComplete="address-line2"
                            />
                        </Form.Group>
                    </Col>

                    <Col sm={6}>
                        <Row>
                            <Col sm={4}>
                                <Form.Group controlId={'form.zip'}>
                                    <Form.Label>
                                        {t('form', 'general.field.zip.label')}
                                        <span>*</span>
                                    </Form.Label>
                                    <Form.Control
                                        type={'text'}
                                        name={'zip'}
                                        onChange={this.handleCustomerChange}
                                        value={zip}
                                        required={true}
                                        maxLength={maxLengthZip}
                                        autoComplete="postal-code"
                                    />
                                </Form.Group>
                            </Col>

                            <Col sm={8}>
                                <Form.Group controlId={'form.city'}>
                                    <Form.Label>
                                        {t('form', 'general.field.city.label')}
                                        <span>*</span>
                                    </Form.Label>
                                    <Form.Control
                                        type={'text'}
                                        name={'city'}
                                        onChange={this.handleCustomerChange}
                                        required={true}
                                        value={city}
                                        maxLength={maxLengthCity}
                                        autoComplete="address-level2"
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Col>

                    <Col sm={6}>
                        <Form.Group controlId={'form.state'}>
                            <Form.Label>{t('form', 'general.field.state.label')}</Form.Label>
                            <Form.Control
                                type={'text'}
                                name={'state'}
                                onChange={this.handleCustomerChange}
                                value={state}
                                maxLength={maxLengthCity}
                                autoComplete="address-level1"
                            />
                        </Form.Group>
                    </Col>

                    <Col sm={6}>
                        <Form.Group controlId={'form.country'}>
                            <Form.Label>
                                {t('form', 'general.field.country.label')}
                                <span>*</span>
                            </Form.Label>
                            <Select
                                value={country}
                                onChange={this.handleCountryChange}
                                options={countryOptions}
                                classNamePrefix={'pdft_select'}
                            />
                        </Form.Group>
                    </Col>

                    <Col sm={6}>
                        <Form.Group controlId={'form.telephone'}>
                            <Form.Label>{t('form', 'general.field.telephone.label')}</Form.Label>
                            <Form.Control
                                type={'text'}
                                name={'phone'}
                                onChange={this.handleChange}
                                value={phone}
                                maxLength={maxLengthTelephone}
                                autoComplete="tel"
                            />
                        </Form.Group>
                    </Col>

                    <Col sm={6}>
                        <Form.Group controlId={'form.language'}>
                            <Form.Label>
                                {t('form', 'general.field.language.label')}
                                <span>*</span>
                            </Form.Label>
                            <Select
                                value={language}
                                onChange={this.handleLanguageChange}
                                options={languageOptions}
                                classNamePrefix={'pdft_select'}
                            />
                        </Form.Group>
                    </Col>

                    <Col sm={6}>
                        <Row>
                            <Col sm={6}>
                                <Form.Group controlId={'formNewsletter'}>
                                    <Form.Check
                                        type={'checkbox'}
                                        label={'Newsletter'}
                                        name="newsletter"
                                        inline={true}
                                        checked={newsletter}
                                        onChange={this.handleCheckboxChange}
                                    />
                                </Form.Group>
                            </Col>
                            {displayUpdateFlash && (
                                <Col sm={6}>
                                    <Form.Group controlId={'formUpdateFlash'}>
                                        <Form.Check
                                            type={'checkbox'}
                                            label={'Release Newsflash'}
                                            name="updateFlash"
                                            inline={true}
                                            checked={updateFlash}
                                            onChange={this.handleCheckboxChange}
                                        />
                                    </Form.Group>
                                </Col>
                            )}
                        </Row>
                    </Col>

                    <Col sm={12}>{additionalInfo}</Col>

                    <Col sm={6} style={{ marginTop: '20px' }}>
                        <Form.Group>
                            <input type={'submit'} className={'btn btn-primary'} value={submitBtnText} />
                        </Form.Group>
                    </Col>
                </Row>
            )
        }

        return (
            <>
                {successMsg && <Alert variant={'success'}>{successMsg}</Alert>}

                {errorMsg && <Alert variant={'danger'}>{errorMsg}</Alert>}
                <GenericForm onSubmit={this.handleSubmit}>
                    <ColoredBox>
                        <div className={styles.coloredBox}>{colorBoxContent}</div>
                    </ColoredBox>
                </GenericForm>
            </>
        )
    }
}

export default withTranslations(AccountForm)

interface AccountFormProps extends Translation {
    onSubmit: any
    countryOptions: IFilterOption[]
    languageOptions: IFilterOption[]
    onComponentMount?: any
    errorMsg?: string
    successMsg?: string
    emailAddrInfo: string
    submitBtnText: string
    additionalInfo?: JSX.Element
    disableSpinner?: boolean
    defaultLangOpt: IFilterOption
    defaultCountryOpt: IFilterOption
    displayUpdateFlash?: boolean
}

interface ICustomer {
    name: string
    address: string
    address2: string
    city: string
    state: string
    zip: string
    country: IFilterOption
}

interface AccountFormState {
    form: {
        email: string
        gender: string
        firstname: string
        lastname: string
        phone: string
        language: IFilterOption
        customer: ICustomer
        newsletter: boolean
        updateFlash: boolean
    }
    spinner: boolean
}
