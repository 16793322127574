import * as React from 'react'
import Location, { ISearchPayment } from '../../../types/Location'
import Translation from '../../../types/Translation'
import withLocation from '../../hoc/withLocation'
import withTranslations from '../../hoc/withTranslations'
import ConfirmDialog from './ConfirmDialog'

class CreditCardConfirmDialog extends React.Component<CreditCardConfirmDialogProps, CreditCardConfirmDialogState> {
    state = {
        displayConfirmDialog: false,
    }

    componentDidMount = () => {
        const { search } = this.props
        const { payment } = search

        if (payment === 'success') {
            this.showConfirmDialog()
        }
    }

    showConfirmDialog = () => {
        this.setState({
            displayConfirmDialog: true,
        })
    }

    handleClose = () => {
        this.setState({
            displayConfirmDialog: false,
        })
    }

    render() {
        const { t } = this.props

        return (
            <ConfirmDialog
                show={this.state.displayConfirmDialog}
                title={t('template', 'quotes.dialog.creditcard.confirmation.title')}
                subTitle={t('template', 'quotes.dialog.creditcard.confirmation.subtitle')}
                textContent={t('template', 'quotes.dialog.creditcard.confirmation.text')}
                onClose={this.handleClose}
            />
        )
    }
}

export default withLocation(withTranslations(CreditCardConfirmDialog))

interface CreditCardConfirmDialogProps extends Translation, Location<ISearchPayment> {}

interface CreditCardConfirmDialogState {
    displayConfirmDialog: boolean
}
