import React from 'react'
import axios from '../../../utils/axios'
import PageContext from '../../../types/PageContext'
import { UrlLinks } from '../../../types/UrlLookup'
import CreditCardConfirmDialog from '../../content/modal/CreditCardConfirmDialog'
import RetryDialog from '../../content/modal/RetryDialog'
import withLocation from '../../hoc/withLocation'
import { IQuoteRecord } from '../../../types/Quote'
import { LangKey } from '../../../types/Languages'
import { formatDate } from '../../../helper/DateHelper'
import { IOrderRecord } from '../../../types/Order'
import { IInvoiceRecord } from '../../../types/Invoice'
import InvoiceTable from './InvoiceTable'
import OrderTable from './OrderTable'
import QuoteTable from './QuoteTable'

class OrderProcess extends React.Component<OrderProcessProps> {
    state = {
        hasUpdatedData: false,
        orders: [],
        successOrders: false,
        quotes: [],
        successQuotes: false,
        invoices: [],
        successInvoices: false,
    }
    async componentDidMount() {
        await Promise.all([this.fetchQuotes(), this.fetchOrders(), this.fetchInvoices()])
    }

    // TODO: extract duplicated code in fetchQuotes, fetchOrders and fetchInvoices to a new function
    fetchQuotes = async () => {
        const url = '/api/v1.0/Quotes'

        try {
            const response = await axios.get(url, {
                headers: {
                    'Accept-Language': this.props.pageContext.language,
                },
            })

            if (response && response.data) {
                const quotes = response.data as IQuoteRecord[]
                this.saveQuotes(quotes)
            }
        } catch (error) {
            console.log(error)

            if (error.response) {
                console.log(`status code: ${error.response.status}`)
                console.log(error.response.data)
            }
        }
    }

    fetchOnUpdateData = async () => {
        this.fetchQuotes()
        this.fetchOrders()
    }

    saveQuotes = (quotes: IQuoteRecord[]) => {
        const preparedQuotes = quotes.map((quote) => {
            const language: LangKey = this.props.pageContext.language

            const expirationDate = formatDate(quote.expiration, language)

            const quoteRecord: IQuoteRecord = quote
            quoteRecord.expiration = expirationDate

            return quoteRecord
        })

        this.setState({
            quotes: preparedQuotes,
            successQuotes: true,
        })
    }

    fetchOrders = async () => {
        const url = '/api/v1.0/Orders'

        try {
            const response = await axios.get(url, {
                headers: {
                    'Accept-Language': this.props.pageContext.language,
                },
            })

            if (response && response.data) {
                const orders = response.data as IOrderRecord[]
                this.saveOrders(orders)
            }
        } catch (error) {
            console.log(error)

            if (error.response) {
                console.log(`status code: ${error.response.status}`)
                console.log(error.response.data)
            }
        }
    }

    saveOrders = (orders: IOrderRecord[]) => {
        const language: LangKey = this.props.pageContext.language

        const preparedOrders = orders.map((order) => {
            order.date = formatDate(order.date, language)
            return order
        })

        this.setState({
            orders: preparedOrders,
            successOrders: true,
        })
    }

    fetchInvoices = async () => {
        const url = '/api/v1.0/Invoices'

        try {
            const response = await axios.get(url, {
                headers: {
                    'Accept-Language': this.props.pageContext.language,
                },
            })

            if (response && response.data) {
                const invoices = response.data as IInvoiceRecord[]
                this.saveInvoices(invoices)
            }
        } catch (error) {
            console.log(error)

            if (error.response) {
                console.log(`status code: ${error.response.status}`)
                console.log(error.response.data)
            }
        }
    }

    saveInvoices = (invoices: IInvoiceRecord[]) => {
        const language = this.props.pageContext.language

        const preparedInvoices = invoices.map((invoice) => {
            invoice.date = formatDate(invoice.date, language)
            return invoice
        })

        this.setState({
            invoices: preparedInvoices,
            successInvoices: true,
        })
    }

    render() {
        const { pageContext, contactUrls } = this.props
        const contactUrl = contactUrls[pageContext.language] || ''

        return (
            <>
                <QuoteTable
                    contactUrl={contactUrl}
                    onDataUpdate={this.fetchOnUpdateData}
                    quotes={this.state.quotes}
                    success={this.state.successQuotes}
                />
                <OrderTable orders={this.state.orders} success={this.state.successQuotes} />
                <InvoiceTable invoices={this.state.invoices} success={this.state.successInvoices} />

                <RetryDialog />
                <CreditCardConfirmDialog />
            </>
        )
    }
}

export default withLocation(OrderProcess)

interface OrderProcessProps {
    pageContext: PageContext<any>
    contactUrls: UrlLinks
}
