import * as React from 'react'
import { TAllContactGroups, TContactGroups } from '../../../types/Contact'
import PageContext from '../../../types/PageContext'
import Contacts from '../Contacts'

class ContactsPlugin extends React.Component<Props, {}> {
    render() {
        const { pageContext, pluginData } = this.props
        let contacts: TContactGroups = {}

        if (pluginData && pluginData[pageContext.language]) {
            contacts = pluginData[pageContext.language]
        }

        return <Contacts contacts={contacts} language={pageContext.language} />
    }
}

export default ContactsPlugin

interface Props {
    config: any
    pageContext: PageContext<any>
    pluginData: TAllContactGroups
}
