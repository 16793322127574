import * as React from 'react'
import { Col, Container, Image, Row } from 'react-bootstrap'
import AnyLink from '../components/AnyLink'
import GridLayoutRenderer from '../components/content/GridLayoutRenderer'
import RenderHtml from '../components/content/RenderHtml'
import withTranslations from '../components/hoc/withTranslations'
import MainLayout from '../layouts/MainLayout'
import PageContext from '../types/PageContext'
import Translation from '../types/Translation'
import * as styles from './PublicationPage.module.scss'

class PublicationPageTemplate extends React.Component<Props> {
    public render() {
        const { pageContext, t } = this.props
        const { templateData, grids } = pageContext
        const { title, teaser, furtherInformation, authorImage, filename, author, publication } = templateData
        const gridsBanner = grids?.filter((g) => g.slot === 'banner')

        const fileDownloadPath = `/public/downloads/know-how/${filename}`

        return (
            <MainLayout pageContext={pageContext}>
                {gridsBanner &&
                    gridsBanner.map((grid) => (
                        <GridLayoutRenderer key={`banner_${grid.directusId}`} pageContext={pageContext} rootItem={grid} />
                    ))}
                <Container>
                    <Row>
                        <Col sm={4}>
                            <Row>
                                <Col sm={12} className="text-center">
                                    <Image src={authorImage} fluid={true} rounded={true} className={styles.authorImage} />
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={12}>
                                    <h3 className="underline">{t('template', 'publication.articleinfo.title')}</h3>
                                    <div>
                                        <p>
                                            {t('template', 'publication.originalsource')}:{' '}
                                            <AnyLink to={fileDownloadPath} className="link-file">
                                                {publication}
                                            </AnyLink>
                                        </p>
                                        <p>
                                            {t('template', 'publication.author')}: {author}{' '}
                                        </p>
                                        <p>
                                            {t('template', 'publication.publication')}: {publication}{' '}
                                        </p>
                                    </div>
                                </Col>
                            </Row>
                            {furtherInformation && (
                                <Row>
                                    <Col sm={12}>
                                        <h3 className="underline">{t('template', 'publication.furtherinfo.title')}</h3>
                                        <RenderHtml html={furtherInformation} />
                                    </Col>
                                </Row>
                            )}
                        </Col>
                        <Col sm={8}>
                            <div>
                                <h1>{title}</h1>
                                <p className="font-italic font-weight-bold">{teaser}</p>
                            </div>

                            {pageContext.grids
                                ?.filter((g) => g.slot === 'default')
                                .map((grid) => (
                                    <GridLayoutRenderer
                                        key={`grid_${grid.directusId}`}
                                        pageContext={pageContext}
                                        rootItem={grid}
                                        wrapRoot={false}
                                    />
                                ))}
                        </Col>
                    </Row>
                </Container>
            </MainLayout>
        )
    }
}

export default withTranslations(PublicationPageTemplate)
interface Props extends Translation {
    pageContext: PageContext<any>
}
