import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { formatDate } from '../../../helper/DateHelper'
import { IDownloadData } from '../../../types/LicenseKit'
import PageContext from '../../../types/PageContext'
import Translation from '../../../types/Translation'
import withTranslations from '../../hoc/withTranslations'
import LicensesHeadRow from './LicensesHeadRow'
import * as styles from './ProductLineLicences.module.scss'

class History extends React.Component<HistoryProps, HistoryState> {
    render() {
        const { pageContext, t, threeHeightsHistory, fourHeightsHistory } = this.props

        const historyRecords = []

        let content = null

        if (fourHeightsHistory) {
            historyRecords.push(...fourHeightsHistory)
        }

        if (threeHeightsHistory) {
            historyRecords.push(...threeHeightsHistory)
        }

        const rows = historyRecords.map((record, i: number) => {
            return (
                <Row key={`history-record-${i}`}>
                    <Col sm={8}>{record.articleName}</Col>
                    <Col sm={2}>{record.articleCode}</Col>
                    <Col sm={2}>
                        {record.maintenanceActiveUntil && formatDate(record.maintenanceActiveUntil, pageContext.language)}
                    </Col>
                </Row>
            )
        })

        if (historyRecords.length > 1) {
            content = (
                <>
                    <Row>
                        <Col sm={12}>
                            <h2 className={styles.title}>{t('template', 'history.title')}</h2>
                        </Col>
                    </Row>

                    <LicensesHeadRow>
                        <Col sm={8}>{t('template', 'licenses.column.title.description')}</Col>
                        <Col sm={2}>{t('template', 'licenses.column.title.articlecode')}</Col>
                        <Col sm={2}>{t('template', 'history.column.title.expired')}</Col>
                    </LicensesHeadRow>

                    {rows}
                </>
            )
        }

        return <>{content}</>
    }
}

export default withTranslations(History)

interface HistoryProps extends Translation {
    pageContext: PageContext
    fourHeightsHistory: IDownloadData[] | null
    threeHeightsHistory: IDownloadData[] | null
}

interface HistoryState {}
