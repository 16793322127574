import * as React from 'react'
import { IFilterOption } from '../../../types/Common'
import PageContext from '../../../types/PageContext'
import { ISuccessStoryListRecord } from '../../../types/SuccessStory'
import FilterableSuccessStoryList from '../successstory/FilterableSuccessStoryList'

class SuccessStoryListPlugin extends React.Component<SuccessStoryListPluginProps, {}> {
    render() {
        const { pageContext, pluginData } = this.props
        const { language } = pageContext

        let stories: ISuccessStoryListRecord[] = []
        let productFilterOptions: IFilterOption[] = []
        let countryFilterOptions: IFilterOption[] = []

        if (pluginData && pluginData[language]) {
            stories = pluginData[language].successStories
            productFilterOptions = pluginData[language].productFilterOptions
            countryFilterOptions = pluginData[language].countryFilterOptions
        }

        return (
            <FilterableSuccessStoryList
                successStories={stories}
                productFilterOptions={productFilterOptions}
                countryFilterOptions={countryFilterOptions}
            />
        )
    }
}

export default SuccessStoryListPlugin

interface SuccessStoryListPluginProps {
    config: any
    pageContext: PageContext<any>
    pluginData: any
}
