import * as React from 'react'
import { TGlossaryData } from '../../../types/Glossary'
import AnchorLink from '../AnchorLink'
import AnchorNavigation from '../AnchorNavigation'

const GlossaryNavigation: React.FunctionComponent<GlossaryNavigationProps> = (props: GlossaryNavigationProps) => {
    const { glossaryData } = props
    const anchorLinks: any = []

    for (const glossaryKey of Object.keys(glossaryData)) {
        if (glossaryData[glossaryKey].length) {
            const anchor = `#${glossaryKey}`

            anchorLinks.push(
                <AnchorLink
                    anchor={anchor}
                    title={`PDF Glossary: ${glossaryKey}-terms`}
                    anchorKey={glossaryKey}
                    key={`anchor-${glossaryKey}`}
                />,
            )
        } else {
            anchorLinks.push(<AnchorLink anchorKey={glossaryKey} key={`anchor-${glossaryKey}`} />)
        }
    }

    return <AnchorNavigation>{anchorLinks}</AnchorNavigation>
}

interface GlossaryNavigationProps {
    glossaryData: TGlossaryData
}

export default GlossaryNavigation
