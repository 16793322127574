import * as React from 'react'
import { Col, Row } from 'react-bootstrap'
import ColoredBox from './ColoredBox'
import * as styles from './NoteBox.module.scss'

const NoteBox: React.FunctionComponent<NoteBoxProps> = (props: NoteBoxProps) => {
    const { pdftColor, fullWidth, children } = props
    const iconClass = `${props.iconClass ? 'pti-' + props.iconClass : 'pti-info-twirl-circle'}`

    return (
        <ColoredBox fullWidth={fullWidth} spaceBottom={true} pdftColor={pdftColor || 'blue'} invertColor={true}>
            <Row className={styles.noteBox}>
                <Col xs={2} md={1} className={'text-right'}>
                    <span className={`${iconClass} ${styles.infoIcon}`} />
                </Col>
                <Col xs={10} md={11}>
                    <div className={styles.infoSection}>{children}</div>
                </Col>
            </Row>
        </ColoredBox>
    )
}

interface NoteBoxProps {
    fullWidth?: boolean
    pdftColor?: string
    iconClass?: string
    children?: React.ReactNode
}

export default NoteBox
