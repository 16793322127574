import * as React from 'react'
import { Alert } from 'react-bootstrap'
import PageContext from '../../../types/PageContext'
import Translation from '../../../types/Translation'
import axios from '../../../utils/axios'
import withTranslations from '../../hoc/withTranslations'
import IFrame from '../IFrame'

class MyPDFIframePlugin extends React.Component<Props, State> {
    state: State = {
        iframeUrl: '',
        errorMsg: '',
    }

    componentDidMount = async () => {
        const { config } = this.props

        const fileExchangeUrl = '/api/v1.0/Url/fileshare'
        const supportRequestUrl = '/api/v1.0/Url/supportrequest'

        let fetchUrl = ''

        if (config.type === 'fileExchange') {
            fetchUrl = fileExchangeUrl
        }

        if (config.type === 'supportRequest') {
            fetchUrl = supportRequestUrl
        }

        try {
            const response = await axios.get(fetchUrl)

            if (response && response.data) {
                this.setState({
                    iframeUrl: response.data.url,
                })
            }
        } catch (e) {
            console.log(e)

            this.setState({
                errorMsg: this.props.t('form', 'general.form.error'),
            })
        }
    }

    render() {
        const { iframeUrl, errorMsg } = this.state
        const { config } = this.props

        let content = <IFrame src={iframeUrl} title={config.type} />

        if (errorMsg) {
            content = <Alert variant={'danger'}>{errorMsg}</Alert>
        }

        return <div>{content}</div>
    }
}

export default withTranslations(MyPDFIframePlugin)

interface State {
    iframeUrl: string
    errorMsg: string
}

interface Props extends Translation {
    config: any
    pageContext: PageContext
    pluginData: any
}
