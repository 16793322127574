import * as React from 'react'
import { IInternshipReport, TReportPreviews } from '../../../types/InternshipReport'
import PageContext from '../../../types/PageContext'
import InternshipReportList from '../internship/InternshipReportList'

class InternshipReportsPlugin extends React.Component<Props, {}> {
    render() {
        const { pageContext, pluginData } = this.props
        const langKey = pageContext.language
        const reports = pluginData[langKey]

        return <InternshipReportList reports={reports} />
    }
}

export default InternshipReportsPlugin

interface Props {
    config: any
    pageContext: PageContext<IInternshipReport>
    pluginData: TReportPreviews
}
