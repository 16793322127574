import * as React from 'react'
import { fetchSixPaymentUrl } from '../../../helper/SixPaymentHelper'
import MasterCardLogo from '../../../images/mastercard_64_logo.png'
import VisaLogo from '../../../images/visa_64_logo.png'
import { IQuoteDialogData } from '../../../types/Quote'
import Translation from '../../../types/Translation'
import axios from '../../../utils/axios'
import withTranslations from '../../hoc/withTranslations'
import * as styles from './CreditCardModal.module.scss'
import CreditCardDialog from './CreditCardDialog'

class CreditCardModal extends React.Component<CreditCardModalProps, CreditCardModalState> {
    state: CreditCardModalState = {
        errorMsg: '',
    }

    renderBtnContent = () => <span className={'pti-credit-card'}>{this.props.t('template', 'general.creditcard')}</span>

    renderTextContent = () => (
        <>
            <p>{this.props.t('template', 'quotes.dialog.creditcard.text')}</p>

            <div className={styles.imgContainer}>
                <img src={MasterCardLogo} style={{ marginRight: '5px' }} alt={'MasterCard'} />
                <img src={VisaLogo} alt={'Visa'} />
            </div>
        </>
    )

    handlePayQuote = async (quoteDialogData: IQuoteDialogData) => {
        const success = await this.postQuoteCreditCardPayment(quoteDialogData)

        if (success) {
            const { itemId, t } = this.props

            const onError = () => {
                this.displayError(t('form', 'general.form.error'))
            }

            const url = await fetchSixPaymentUrl(itemId, 'Quote', onError)

            if (url) {
                window.location.href = url
            }
        }
    }

    handlePayInvoice = async () => {
        const { itemId, t } = this.props

        const onError = () => {
            this.displayError(t('form', 'general.form.error'))
        }
        const url = await fetchSixPaymentUrl(itemId, 'Invoice', onError)

        if (url) {
            window.location.href = url
        }
    }

    postQuoteCreditCardPayment = async (quoteDialogData: IQuoteDialogData) => {
        const { itemId, t } = this.props
        const quotePayUrl = `/api/v1.0/Quotes/${itemId}/Order/creditcard`

        try {
            await axios.post(quotePayUrl, quoteDialogData)
            return true
        } catch (error) {
            console.log(error)

            if (error.response) {
                console.log(`status code: ${error.response.status}`)
                console.log(error.response.data)
            }

            this.displayError(t('form', 'general.form.error'))

            return false
        }
    }

    displayError = (errorMsg: string) => {
        this.setState({
            errorMsg,
        })
    }

    render() {
        const btnContent = this.renderBtnContent()

        const { t, itemNumber, totalPrice, isQuote } = this.props

        const { errorMsg } = this.state

        const textContent = this.renderTextContent()

        const additionalContent = (
            <div className={styles.totalPrice}>
                <span>
                    <strong>{t('template', 'quotes.dialog.total.label')}</strong>
                </span>

                <span>
                    <strong>{totalPrice}</strong>
                </span>
            </div>
        )

        const btnLabel = t('template', 'quotes.dialog.creditcard.button.label')

        let title
        let subTitle
        if (isQuote) {
            title = t('template', 'quotes.dialog.creditcard.title')
            subTitle = t('template', 'quotes.title.quote') + ` ${itemNumber}`
        } else {
            title = t('template', 'invoices.dialog.creditcard.title')
            subTitle = t('template', 'invoices.title.invoice') + ` ${itemNumber}`
        }

        return (
            <CreditCardDialog
                btnContent={btnContent}
                title={title}
                subTitle={subTitle}
                textContent={textContent}
                errorMsg={errorMsg}
                additionalContent={additionalContent}
                modalAction={isQuote ? this.handlePayQuote : this.handlePayInvoice}
                modalActionBtnLabel={btnLabel}
            />
        )
    }
}

export default withTranslations(CreditCardModal)

interface CreditCardModalProps extends Translation {
    itemNumber: string
    totalPrice: string
    itemId: string
    isQuote: boolean
}

interface CreditCardModalState {
    errorMsg: string
}
