import React from 'react'
import { Alert, Col, Form, Row } from 'react-bootstrap'
import Translation from '../../../types/Translation'
import { IProductRecords } from '../../../types/Product'
import Location from '../../../types/Location'
import withLocation from '../../hoc/withLocation'
import withTranslations from '../../hoc/withTranslations'
import { LangKey } from '../../../types/Languages'
import ColoredBox from '../ColoredBox'
import ContentButton from '../ContentButton'
import GenericForm from './GenericForm'
import withProduct from '../../hoc/withProducts'
import * as styles from './EvalFeedbackForm.module.scss'
import StarRating from './StarRating'

class EvalFeedbackForm extends React.Component<EvalFeedbackFormProps, EvalFeedbackFormState> {
    constructor(props: EvalFeedbackFormProps) {
        super(props)
        this.state = {
            errorMsg: '',
            form: {
                cid: this.props.search.cid,
                product: '',
            },
            // mandatory questions
            missingQ1: false,
        }
    }

    componentDidMount = async () => {
        let productName = ''
        this.props.products.forEach((p) => {
            if (p.code === this.props.search.pc) {
                productName = p.name
                return
            }
        })
        this.setState({
            form: {
                ...this.state.form,
                product: productName,
            },
        })
    }

    handleSubmit = () => {
        if (this.props.onSubmit) {
            if (this.state.form.question1) {
                const formData = this.state.form
                this.props.onSubmit(formData)
            } else {
                this.setState({
                    errorMsg: this.props.t('form', 'form.evalfeedback.error.q1'),
                    missingQ1: true,
                })
            }
        }
    }

    handleChange = (e: any) => {
        const field = e.target.name
        this.setState({
            form: {
                ...this.state.form,
                [field]: e.target.value,
            },
        })
        if (field === 'question1')
            this.setState({
                missingQ1: false,
            })
    }

    handleCheck = (e: any) => {
        const field = e.target.name
        this.setState({
            form: {
                ...this.state.form,
                [field]: e.target.value,
            },
        })
    }

    Star = (star: { marked: boolean; starId: number; question: string }) => {
        const { marked, starId, question } = star
        return (
            <span id={starId + question} data-star-id={starId} className={styles.star} role="button">
                {marked ? '\u2605' : '\u2606'}
            </span>
        )
    }

    StarRating = (starRating: { question: string; required: boolean }) => {
        const { question, required } = starRating
        const [rating, setRating] = React.useState(0)
        const [selection, setSelection] = React.useState(0)
        const hoverOver = (event: any) => {
            let val = 0
            if (event && event.target && event.target.getAttribute('data-star-id'))
                val = event.target.getAttribute('data-star-id')

            setSelection(val)
        }
        return (
            <div
                onMouseOut={() => hoverOver(null)}
                onClick={(e) => {
                    const target = e.target as Element
                    const starId = (target.getAttribute('data-star-id') as unknown) as number
                    setRating(starId || rating)
                    this.handleChange({ target: { name: question, value: starId || (rating as number) } })
                }}
                onMouseOver={hoverOver}
            >
                {Array.from({ length: 5 }, (v, i) => (
                    <this.Star
                        starId={i + 1}
                        question={question}
                        key={`star_${i + 1}`}
                        marked={selection ? selection >= i + 1 : rating >= i + 1}
                    />
                ))}
            </div>
        )
    }

    render() {
        const { t } = this.props
        const { form, missingQ1, errorMsg } = this.state
        return (
            <ColoredBox>
                <div className={styles.coloredBox}>
                    <Row>
                        <Col>
                            <p>{t('form', 'general.field.product.label')}</p>
                            <p>
                                <b>{this.state.form.product}</b>
                            </p>
                        </Col>
                    </Row>
                    <GenericForm onSubmit={this.handleSubmit}>
                        <Row>
                            <Col sm={12} md={6}>
                                <Row>
                                    <Col sm={12}>
                                        <Form.Group controlId="form.question1">
                                            <Form.Label>{t('form', 'form.evalfeedback.question1')} *</Form.Label>
                                            <StarRating question="question1" error={missingQ1} handleChange={this.handleChange} />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={12}>
                                        <Form.Group controlId="form.question2">
                                            <Form.Label>{t('form', 'form.evalfeedback.question2')}</Form.Label>
                                            <div className="d-flex align-items-center ">
                                                <Form.Check
                                                    type="radio"
                                                    label={t('form', 'general.field.yes.label')}
                                                    id="radio-question2-yes"
                                                    name="question2"
                                                    inline={true}
                                                    value="Yes"
                                                    onChange={this.handleCheck}
                                                />
                                                <Form.Check
                                                    type="radio"
                                                    label={t('form', 'general.field.no.label')}
                                                    id="radio-question2-no"
                                                    name="question2"
                                                    value="No"
                                                    inline={true}
                                                    onChange={this.handleCheck}
                                                />
                                            </div>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                {!!form.question2 && form.question2 === 'No' && (
                                    <Row>
                                        <Col sm={12}>
                                            <Form.Group controlId="form.question2.1">
                                                <Form.Label>{t('form', 'form.evalfeedback.question2.1')}</Form.Label>
                                                <Form.Control
                                                    as="textarea"
                                                    rows="5"
                                                    name="question2_1"
                                                    onChange={this.handleChange}
                                                    maxLength={400}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                )}
                                <Row>
                                    <Col sm={12}>
                                        <Form.Group controlId="form.question3">
                                            <Form.Label>{t('form', 'form.evalfeedback.question3')}</Form.Label>
                                            <StarRating question="question3" handleChange={this.handleChange} />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                {!!form.question3 && form.question3 < 3 && (
                                    <Row>
                                        <Col sm={12}>
                                            <Form.Group controlId="form.question3.1">
                                                <Form.Label>{t('form', 'form.evalfeedback.question3.1')}</Form.Label>
                                                <Form.Control
                                                    as="textarea"
                                                    rows="5"
                                                    name="question3_1"
                                                    onChange={this.handleChange}
                                                    maxLength={400}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                )}
                                <Row>
                                    <Col sm={12}>
                                        <Form.Group controlId="form.question4">
                                            <Form.Label>{t('form', 'form.evalfeedback.question4')}</Form.Label>
                                            <StarRating question="question4" handleChange={this.handleChange} />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                {!!form.question4 && form.question4 < 3 && (
                                    <Row>
                                        <Col sm={12}>
                                            <Form.Group controlId="form.question4.1">
                                                <Form.Label>{t('form', 'form.evalfeedback.question4.1')}</Form.Label>
                                                <Form.Control
                                                    as="textarea"
                                                    rows="5"
                                                    name="question4_1"
                                                    onChange={this.handleChange}
                                                    maxLength={400}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                )}
                                <Row>
                                    <Col sm={12}>
                                        <Form.Group controlId="form.question5">
                                            <Form.Label>{t('form', 'form.evalfeedback.question5')}</Form.Label>
                                            <StarRating question="question5" handleChange={this.handleChange} />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                {!!form.question5 && form.question5 < 3 && (
                                    <Row>
                                        <Col sm={12}>
                                            <Form.Group controlId="form.question5.1">
                                                <Form.Label>{t('form', 'form.evalfeedback.question5.1')}</Form.Label>
                                                <Form.Control
                                                    as="textarea"
                                                    rows="5"
                                                    name="question5_1"
                                                    onChange={this.handleChange}
                                                    maxLength={400}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                )}

                                <Row>
                                    <Col sm={12}>
                                        <Form.Group controlId="form.question6">
                                            <Form.Label>{t('form', 'form.evalfeedback.question6')} *</Form.Label>
                                            <div className="d-flex align-items-center ">
                                                <Form.Check
                                                    type="radio"
                                                    label={t('form', 'general.field.yes.label')}
                                                    id="radio-question6-yes"
                                                    name="question6"
                                                    inline={true}
                                                    value="Yes"
                                                    onChange={this.handleCheck}
                                                    required={true}
                                                />
                                                <Form.Check
                                                    type="radio"
                                                    label={t('form', 'general.field.no.label')}
                                                    id="radio-question6-no"
                                                    name="question6"
                                                    value="No"
                                                    inline={true}
                                                    onChange={this.handleCheck}
                                                    required={true}
                                                />
                                            </div>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                {!!form.question6 && form.question6 === 'Yes' && (
                                    <div>
                                        <Row>
                                            <Col sm={12}>
                                                <Form.Group controlId="form.question6.1">
                                                    <Form.Label>{t('form', 'form.evalfeedback.question6.1')}</Form.Label>
                                                    <StarRating question="question6_1" handleChange={this.handleChange} />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={12}>
                                                <Form.Group controlId="form.question6.2">
                                                    <Form.Label>{t('form', 'form.evalfeedback.question6.2')}</Form.Label>
                                                    <StarRating question="question6_2" handleChange={this.handleChange} />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={12}>
                                                <Form.Group controlId="form.question6.3">
                                                    <Form.Label>{t('form', 'form.evalfeedback.question6.3')}</Form.Label>
                                                    <StarRating question="question6_3" handleChange={this.handleChange} />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </div>
                                )}
                            </Col>
                            <Col sm={12} md={6}>
                                <Row>
                                    <Col sm={12}>
                                        <Form.Group controlId="form.question7">
                                            <Form.Label>{t('form', 'form.evalfeedback.question7')}</Form.Label>
                                            <Form.Control
                                                as="textarea"
                                                rows="5"
                                                name="question7"
                                                onChange={this.handleChange}
                                                maxLength={400}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={12}>
                                        <Form.Group controlId="form.question8">
                                            <Form.Label>{t('form', 'form.evalfeedback.question8')}</Form.Label>

                                            <div className="d-flex align-items-center ">
                                                <Form.Check
                                                    type="radio"
                                                    label={t('form', 'general.field.yes.label')}
                                                    id="radio-question8-yes"
                                                    name="question8"
                                                    inline={true}
                                                    value="Yes"
                                                    onChange={this.handleCheck}
                                                />

                                                <Form.Check
                                                    type="radio"
                                                    label={t('form', 'general.field.no.label')}
                                                    id="radio-question8-no"
                                                    name="question8"
                                                    value="No"
                                                    inline={true}
                                                    onChange={this.handleCheck}
                                                />

                                                <Form.Check
                                                    type="radio"
                                                    label={t('form', 'general.field.partly.label')}
                                                    id="radio-question8-partly"
                                                    name="question8"
                                                    value="Partly"
                                                    inline={true}
                                                    onChange={this.handleCheck}
                                                />
                                            </div>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                {(form.question8 === 'No' || form.question8 === 'Partly') && (
                                    <Row>
                                        <Col sm={12}>
                                            <Form.Group controlId="form.question8.1">
                                                <Form.Label>{t('form', 'form.evalfeedback.question8.1')}</Form.Label>
                                                <Form.Control
                                                    as="textarea"
                                                    rows="5"
                                                    name="question8_1"
                                                    onChange={this.handleChange}
                                                    maxLength={400}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                )}

                                <Row>
                                    <Col sm={12}>
                                        <Form.Group controlId="form.question9">
                                            <Form.Label>{t('form', 'form.evalfeedback.question9')}</Form.Label>
                                            <Form.Control
                                                as="textarea"
                                                rows="5"
                                                name="question9"
                                                onChange={this.handleChange}
                                                maxLength={400}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={12}>
                                        <Form.Group controlId="form.question10">
                                            <Form.Label>{t('form', 'form.evalfeedback.question10')}</Form.Label>
                                            <Form.Control
                                                as="textarea"
                                                rows="5"
                                                name="question10"
                                                onChange={this.handleChange}
                                                maxLength={400}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={5}>
                                <Form.Group controlId="form.submit">
                                    <ContentButton type="submit" variant="primary">
                                        {t('form', 'general.btn.send')}
                                    </ContentButton>
                                </Form.Group>
                            </Col>
                        </Row>
                    </GenericForm>
                </div>
                {missingQ1 && <Alert variant="danger">{errorMsg}</Alert>}
            </ColoredBox>
        )
    }
}

export default withLocation(withTranslations(withProduct(EvalFeedbackForm)))

interface EvalFeedbackFormProps extends IProductRecords, Translation, Location<any> {
    onSubmit: any
    language: LangKey
    templateData: any // IEvalFeedbackTemplate
}

interface EvalFeedbackFormState {
    errorMsg: string
    form: any
    missingQ1: boolean
}
