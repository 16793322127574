import React from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import { IFilterOption } from '../../../types/Common'
import Translation from '../../../types/Translation'
import ColoredBox from '../../content/ColoredBox'
import withTranslations from '../../hoc/withTranslations'
import ContentButton from '../ContentButton'
import * as styles from './ContactForm.module.scss'
import GenericForm from './GenericForm'
import GoogleRecaptcha from './GoogleRecaptcha'

class ContactForm extends React.Component<ContactFormProps, ContactFormState> {
    state = {
        form: {
            firstname: '',
            lastname: '',
            email: '',
            message: '',
        },
    }

    handleSubmit = () => {
        if (this.props.onSubmit) {
            const formData = this.state.form
            const sentData = this.prepareSentData(formData)

            this.props.onSubmit(formData, sentData)
        }
    }

    handleChange = (e: any) => {
        const field = e.target.name
        const newState = {
            form: {
                ...this.state.form,
                [field]: e.target.value,
            },
        }

        this.setState(newState)
    }

    prepareSentData(formData: any) {
        const sentData: any = {}

        Object.keys(formData).forEach((key) => {
            const val = formData[key]
            let field

            switch (key) {
                case 'firstname':
                    field = this.props.t('form', 'general.field.firstname.label')
                    sentData[field] = val
                    break
                case 'lastname':
                    field = this.props.t('form', 'general.field.lastname.label')
                    sentData[field] = val
                    break
                case 'email':
                    field = this.props.t('form', 'general.field.email.label')
                    sentData[field] = val
                    break
                case 'message':
                    field = this.props.t('form', 'contactForm.field.message.label')
                    sentData[field] = val
                    break
                default:
            }
        })

        return sentData
    }

    render() {
        const { t } = this.props

        const columnWidths = {
            requiredFields: 12,
            optionalFields: 0,
        }

        const noWidth = true
        return (
            <GenericForm onSubmit={this.handleSubmit}>
                <Row>
                    <ColoredBox noWidth={noWidth}>
                        <div className={styles.coloredBox}>
                            <Row>
                                <Col sm={columnWidths.requiredFields}>
                                    <Row>
                                        <Col sm={12}>
                                            <h2>{t('form', 'general.contactDetails.title')}</h2>
                                        </Col>
                                        <Col sm={6} />
                                    </Row>

                                    <Row>
                                        <Col sm={6}>
                                            <Form.Group controlId={'form.firstname'}>
                                                <Form.Label>
                                                    {t('form', 'general.field.firstname.label')}
                                                    <span>*</span>
                                                </Form.Label>
                                                <Form.Control
                                                    type={'text'}
                                                    name={'firstname'}
                                                    required={true}
                                                    onChange={this.handleChange}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {t('form', 'form.validate.firstname.invalid')}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                        <Col sm={6}>
                                            <Form.Group controlId={'form.lastname'}>
                                                <Form.Label>
                                                    {t('form', 'general.field.lastname.label')}
                                                    <span>*</span>
                                                </Form.Label>
                                                <Form.Control
                                                    type={'text'}
                                                    name={'lastname'}
                                                    required={true}
                                                    onChange={this.handleChange}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {t('form', 'form.validate.lastname.invalid')}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={12}>
                                            <Form.Group controlId={'form.email'}>
                                                <Form.Label>
                                                    {t('form', 'general.field.email.label')}
                                                    <span>*</span>
                                                </Form.Label>
                                                <Form.Control
                                                    type="email"
                                                    name={'email'}
                                                    required={true}
                                                    onChange={this.handleChange}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {t('form', 'form.validate.email.invalid')}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col sm={12}>
                                            <Form.Group style={{ marginTop: '5px' }} controlId={'form.message'}>
                                                <Form.Label>
                                                    {t('form', 'contactForm.field.message.label')}
                                                    <span>*</span>
                                                </Form.Label>
                                                <div>
                                                    <Form.Control
                                                        as="textarea"
                                                        rows="5"
                                                        required={true}
                                                        name={'message'}
                                                        onChange={this.handleChange}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {t('form', 'form.validate.message.invalid')}
                                                    </Form.Control.Feedback>
                                                </div>
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <GoogleRecaptcha handleRecaptcha={this.props.handleRecaptcha} />

                                    <Row>
                                        <Col sm={5}>
                                            <Form.Group>
                                                <ContentButton type="submit" variant="primary">
                                                    {t('form', 'general.btn.send')}
                                                </ContentButton>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                    </ColoredBox>
                </Row>
            </GenericForm>
        )
    }
}

export default withTranslations(ContactForm)

interface ContactFormProps extends Translation {
    onSubmit: any
    language: any
    countryOptions: IFilterOption[]
    findUsOptions: IFilterOption[]
    handleRecaptcha: (response: string) => void
}

interface ContactFormState {
    form: {
        firstname: string
        lastname: string
        email: string
        message: string
    }
}
