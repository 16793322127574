import * as React from 'react'

import { TContactGroups } from '../../types/Contact'
import { LangKey } from '../../types/Languages'
import ContactBox from './ContactBox'

const Contacts: React.FunctionComponent<ContactsProps> = (props: ContactsProps) => {
    const { contacts, language } = props
    const contactsContent: any = []

    Object.keys(contacts).forEach((contactGroup) => {
        contactsContent.push(<h3 className={'underline'}>{contactGroup[0].toUpperCase() + contactGroup.slice(1)}</h3>)

        contacts[contactGroup].forEach((contact) => {
            let position = contact.position
            contact.translation.forEach((c) => {
                if (c.language === language) {
                    position = c.position
                }
            })
            contactsContent.push(
                <ContactBox
                    imgSrc={contact.photo}
                    position={position}
                    title={contact.name}
                    email={contact.email}
                    xing={contact.xing}
                    linkedin={contact.linkedin}
                />,
            )
        })
    })

    return <>{contactsContent}</>
}

interface ContactsProps {
    contacts: TContactGroups
    language: LangKey
}

export default Contacts
