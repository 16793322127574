import * as React from 'react'
import { Alert, Button, Modal } from 'react-bootstrap'
import { fetchSixPaymentUrl } from '../../../helper/SixPaymentHelper'
import Location, { ISearchPayment } from '../../../types/Location'
import Translation from '../../../types/Translation'
import withLocation from '../../hoc/withLocation'
import withTranslations from '../../hoc/withTranslations'
import * as styles from './RetryDialog.module.scss'

class RetryDialog extends React.Component<RetryDialogProps, RetryDialogState> {
    state = {
        show: false,
        errorMsg: '',
    }

    componentDidMount = () => {
        const { search } = this.props
        const { payment, quoteId, invoiceId } = search

        if ((payment === 'abort' || payment === 'fail') && (quoteId || invoiceId)) {
            this.handleShow()
        }
    }

    handleShow = () => {
        this.setState({
            show: true,
        })
    }

    handleClose = () => {
        this.setState({
            show: false,
        })
    }

    displayError = (errorMsg: string) => {
        this.setState({
            errorMsg,
        })
    }

    handleRetry = async () => {
        const { search, t } = this.props
        const { quoteId, invoiceId } = search

        const onError = () => {
            this.displayError(t('form', 'general.form.error'))
        }

        if (invoiceId) {
            const url = await fetchSixPaymentUrl(invoiceId, 'Invoice', onError)

            if (url) {
                window.location.href = url
            }
        } else if (quoteId) {
            const url = await fetchSixPaymentUrl(quoteId, 'Quote', onError)

            if (url) {
                window.location.href = url
            }
        }
    }

    render() {
        const { show, errorMsg } = this.state

        const { t } = this.props

        return (
            <>
                <Modal show={show} onHide={this.handleClose} className={styles.modal}>
                    <Modal.Header closeButton={true}>
                        <h2>{t('template', 'quotes.dialog.creditcard.title')}</h2>
                    </Modal.Header>
                    <Modal.Body>
                        {errorMsg && <Alert variant={'danger'}>{errorMsg}</Alert>}

                        <p className={styles.retryText}>
                            <strong>{t('template', 'quotes.dialog.creditcard.retry.title')}</strong>
                        </p>

                        {t('template', 'quotes.dialog.creditcard.retry.text')}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant={'outline-secondary'} onClick={this.handleClose} className={styles.cancelBtn}>
                            {t('template', 'quotes.dialog.cancel.label')}
                        </Button>
                        <Button variant="primary" onClick={this.handleRetry} className={styles.modalActionBtn}>
                            {t('template', 'quotes.dialog.creditcard.retry.button.label')}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        )
    }
}

export default withLocation(withTranslations(RetryDialog))

interface RetryDialogProps extends Translation, Location<ISearchPayment> {}

interface RetryDialogState {
    show: boolean
    errorMsg: string
}
