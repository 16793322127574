import * as React from 'react'
import { Col, Collapse, Row } from 'react-bootstrap'
import * as styles from './CollapsableRow.module.scss'

class CollapsableRow extends React.Component<CollapsableRowProps, CollapsableRowState> {
    constructor(props: CollapsableRowProps) {
        super(props)

        let open = false

        if (this.props.uncollapsed) {
            open = true
        }

        this.state = {
            open,
        }
    }

    toggleCollapse = () => {
        this.setState({
            open: !this.state.open,
        })
    }

    render() {
        const { open } = this.state

        const { variant, platform, articleCode, name } = this.props

        return (
            <>
                <Row onClick={this.toggleCollapse} className={styles.row} aria-controls={this.props.id} aria-expanded={open}>
                    <Col sm={6}>
                        <span className={`${styles.collapseIcon} ${open ? '' : styles.collapsed}`} />
                        <span className={styles.articleName}>{name}</span>
                    </Col>
                    <Col sm={2}>{variant}</Col>
                    <Col sm={2}>{platform}</Col>
                    <Col sm={2}>{articleCode}</Col>
                </Row>
                <Collapse in={open}>
                    <Row id={this.props.id}>
                        <Col sm={12}>{this.props.children}</Col>
                    </Row>
                </Collapse>
            </>
        )
    }
}

export default CollapsableRow

interface CollapsableRowProps {
    id: string
    name: string
    variant?: string
    platform?: string
    articleCode?: string
    uncollapsed?: boolean
}

interface CollapsableRowState {
    open: boolean
}
