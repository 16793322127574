import React from 'react'
import * as styles from './StarRating.module.scss'

const Star = (star: { marked: boolean; starId: number; question: string; hover: boolean; error?: boolean }) => {
    const { marked, starId, question, hover, error } = star

    let classNames = styles.star
    let hoverClass = error ? styles.errorStarHover : styles.starHover
    classNames += hover ? ' ' + hoverClass : ''
    classNames += error ? ' ' + styles.error : ''

    return (
        <span id={starId + question} data-star-id={starId} className={classNames} role="button">
            {marked ? '\u2605' : '\u2606'}
        </span>
    )
}

const StarRating: React.FunctionComponent<StarRatingProps> = (props: StarRatingProps) => {
    const { question, handleChange, error } = props
    const [rating, setRating] = React.useState(0)
    const [selection, setSelection] = React.useState(0)
    const hoverOver = (event: any) => {
        let val = 0
        if (event && event.target && event.target.getAttribute('data-star-id')) val = event.target.getAttribute('data-star-id')

        setSelection(val)
    }
    return (
        <div
            onMouseOut={() => hoverOver(null)}
            onClick={(e) => {
                const target = e.target as Element
                const starId = (target.getAttribute('data-star-id') as unknown) as number
                setRating(starId || rating)
                handleChange({ target: { name: question, value: starId || (rating as number) } })
            }}
            onMouseOver={hoverOver}
        >
            {Array.from({ length: 5 }, (v, i) => (
                <Star
                    starId={i + 1}
                    question={question}
                    key={`star_${i + 1}`}
                    marked={selection ? selection >= i + 1 : rating >= i + 1}
                    hover={selection ? true : false}
                    error={error}
                />
            ))}
        </div>
    )
}

export default StarRating

interface StarRatingProps {
    question: string
    handleChange: any
    error?: boolean
}
