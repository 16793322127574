import * as React from 'react'
import PageContext from '../../../types/PageContext'
import NewsletterActivate from '../newsletter/NewsletterActivate'

class NewsletterActivatePlugin extends React.Component<Props, {}> {
    render() {
        const { pageContext } = this.props

        return <NewsletterActivate pageContext={pageContext} />
    }
}

export default NewsletterActivatePlugin

interface Props {
    config: any
    pageContext: PageContext
    pluginData: any
}
