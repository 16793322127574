/* eslint-disable react/display-name */
import * as React from 'react'
import { Col, Container, Row, Tab, Tabs } from 'react-bootstrap'
import { parseHtml, parseMarkdown, parseText } from '../../helper/ContentParser'
import Banner from '../layout/Banner'
import { IImage } from '../../types/Image'
import { Pagecontent, PagecontentGridWidthType } from '../../types/PageContent'
import { LangKey } from '../../types/Languages'
import PageContext from '../../types/PageContext'
import ColoredBox from './ColoredBox'
import ContentButton from './ContentButton'
import ImageLink from './ImageLink'
import NoteBox from './NoteBox'
import { getPlugin } from './Plugins'
import ReadMoreBox from './ReadMoreBox'
import BlogBox from './BlogBox'
import HorizontalRule from './HorizontalRule'
import ContentImage from './ContentImage'
import Citation from './Citation'
import RenderHtml from './RenderHtml'

const emptyGridItem: TRenderFunction = () => null

let languageGlobal: LangKey
let pageContextGlobal: PageContext<any>

let tabsRendered = 0

const GridLayoutRenderer: React.FunctionComponent<GridLayoutRendererProps> = (props: GridLayoutRendererProps) => {
    const { rootItem, pageContext, wrapRoot } = props
    languageGlobal = pageContext.language
    pageContextGlobal = pageContext

    let components: any = []
    let classes = ''

    let renderElements: any = []

    if (rootItem) {
        const shouldWrapWithRowAndCol = rootItem.type !== 'grid' && rootItem.type !== 'banner' && !rootItem.fullwidth

        if (rootItem.nomargin) {
            classes = 'my-0'
        }

        components = renderGridTree(rootItem)

        if (wrapRoot) {
            if (shouldWrapWithRowAndCol) {
                renderElements = (
                    <Container className={classes}>
                        <Row>
                            <Col sm={12}>{components}</Col>
                        </Row>
                    </Container>
                )
            } else {
                if (rootItem.type === 'grid') {
                    renderElements = <Container className={classes}>{components}</Container>
                } else {
                    renderElements = components
                }
            }
        } else {
            renderElements = components
        }
    }

    return <>{renderElements}</>
}

const renderGridTree = (rootItem: Pagecontent) => {
    const gridElement = renderGridElement(rootItem)

    let children: React.ReactNode[] = []

    if (rootItem.children && rootItem.children.length > 0) {
        children = rootItem.children.map((child) => renderGridTree(child))
    }

    return gridElement(children)
}

const renderGridElement = (pageContent: Pagecontent): TRenderFunction => {
    switch (pageContent.type) {
        case 'grid':
            return renderGridRoot(pageContent)

        case 'grid.column':
            return renderColumn(pageContent)

        case 'tabs':
            return renderTabs(pageContent)

        case 'tabs.tab':
            return renderTab(pageContent)

        case 'text':
            return renderText(pageContent)

        case 'html':
        case 'wysiwyg':
            return renderHtml(pageContent)

        case 'markdown':
            return renderMarkdown(pageContent)

        case 'button':
            return renderButton(pageContent)

        case 'image':
            return renderImage(pageContent)

        case 'colorbox':
            return renderColorBox(pageContent)

        case 'readmorebox':
            return renderReadMoreBox(pageContent)

        case 'blogbox':
            return renderBlogBox(pageContent)

        case 'notebox':
            return renderNoteBox(pageContent)

        case 'separator':
            return renderSeparator(pageContent)

        case 'banner':
            return renderBanner(pageContent)

        case 'citation':
            return renderCitation(pageContent)

        case 'plugin':
            return renderPlugin(pageContent)

        default:
            console.error('Could not render Element', pageContent?.directusId)
            break
    }

    return emptyGridItem
}

const renderGridRoot = (node: Pagecontent): TRenderFunction => (children) => <Row key={`grid_${node.directusId}`}>{children}</Row>

const renderPlugin = (node: Pagecontent): TRenderFunction => {
    const plugin = getPlugin(node, pageContextGlobal)

    if (plugin) {
        return () => plugin
    }
    return emptyGridItem
}

const renderColumn = (node: Pagecontent): TRenderFunction => {
    const gridWidth = getGridWidth(node)

    const textAlign = getTextAlignClassName(node)
    const justifyContent = getJustifyContentClassName(node)

    return (children) => (
        <Col key={`col_${node.directusId}`} sm={gridWidth} className={`${textAlign} ${justifyContent}`}>
            {children}
        </Col>
    )
}

const renderColorBox = (node: Pagecontent): TRenderFunction => (children) => (
    <ColoredBox
        key={`colorbox_${node.directusId}`}
        pdftColor={node.color}
        fullWidth={node.fullwidth}
        spaceBottom={!node.nomargin}
    >
        {children}
    </ColoredBox>
)

const renderReadMoreBox = (node: Pagecontent): TRenderFunction => {
    const translation = node.translation?.[languageGlobal]

    if (!translation || !node.file?.localFile?.publicURL) {
        return emptyGridItem
    }

    const gridWidth = getGridWidth(node)

    const img = getImage(node, false)
    const html = parseText(translation.content)

    return () => (
        <Col sm={gridWidth}>
            <ReadMoreBox
                key={`readmore_${node.directusId}`}
                img={img}
                to={translation.url || '#'}
                title={translation.title || ''}
            >
                {html && <RenderHtml html={`<p>${html}</p>`} />}
            </ReadMoreBox>
        </Col>
    )
}

const renderBlogBox = (node: Pagecontent): TRenderFunction => {
    const translation = node.translation?.[languageGlobal]

    if (!translation || !node.file?.localFile?.publicURL) {
        return emptyGridItem
    }

    const img = getImage(node, false)

    return () => (
        <Col sm={node.grid_width}>
            <BlogBox key={`blog_${node.directusId}`} img={img} href={translation.url || '#'} title={translation.title || ''}>
                {translation.content}
            </BlogBox>
        </Col>
    )
}

const renderSeparator = (node: Pagecontent): TRenderFunction => {
    const spaceBottom = !node.nomargin

    return () => <HorizontalRule spaceBottom={spaceBottom} />
}

const renderTabs = (node: Pagecontent): TRenderFunction => {
    const tabsId = `tabs_${node.directusId}`
    const defaultActiveKey = getDefaultActiveKey()

    return (children) => (
        <Tabs id={tabsId} key={tabsId} defaultActiveKey={defaultActiveKey}>
            {children}
        </Tabs>
    )
}

const renderTab = (node: Pagecontent): TRenderFunction => {
    const translation = node.translation?.[languageGlobal]

    if (!translation) {
        return emptyGridItem
    }

    const tabKey = `tab_${node.directusId}`
    const eventKey = getTabEventKey()

    return (children) => (
        <Tab title={translation.title} key={tabKey} eventKey={eventKey}>
            {children}
        </Tab>
    )
}

const renderNoteBox = (node: Pagecontent): TRenderFunction => {
    let iconClass: string | undefined

    if (node.icon) {
        iconClass = node.icon
    }

    return (children) => (
        <NoteBox key={`info_${node.directusId}`} iconClass={iconClass} fullWidth={node.fullwidth}>
            {children}
        </NoteBox>
    )
}

const renderButton = (node: Pagecontent): TRenderFunction => {
    const translation = node.translation?.[languageGlobal]

    if (!translation) {
        return emptyGridItem
    }

    const key = `btn_${node.directusId}`

    return () => (
        <ContentButton
            key={key}
            to={translation.url}
            pdftColor={node.color}
            tooltip={translation.tooltip}
            block={node.fullwidth}
            fullheight={node.fullheight}
        >
            {translation.title}
        </ContentButton>
    )
}

const renderText = (node: Pagecontent): TRenderFunction => {
    const translation = node.translation?.[languageGlobal]

    if (!translation) {
        return emptyGridItem
    }

    const heading = getHeading(node)
    const textalign = getTextAlignClassName(node)
    const html = parseText(translation.content)

    return () => (
        <div key={`text_${node.directusId}`} className={textalign}>
            {heading}
            {html && <RenderHtml html={`<p>${html}</p>`} />}
        </div>
    )
}

const renderMarkdown = (node: Pagecontent): TRenderFunction => {
    const translation = node.translation?.[languageGlobal]

    if (!translation) {
        return emptyGridItem
    }

    const heading = getHeading(node)
    const textalign = getTextAlignClassName(node)

    const html = parseMarkdown(translation.content)

    return () => (
        <div className={textalign} key={`markdown_${node.directusId}`}>
            {heading}
            {html && <RenderHtml className="ul-chevron" html={html} />}
        </div>
    )
}

const renderHtml = (node: Pagecontent): TRenderFunction => {
    const translation = node.translation?.[languageGlobal]

    if (!translation) {
        return emptyGridItem
    }

    const heading = getHeading(node)
    // const textalign = getTextAlignClassName(node)

    const html = parseHtml(translation.content)

    return () => (
        <div key={`html_${node.directusId}`}>
            {heading}
            {html && <RenderHtml className="ul-chevron" html={html} />}
        </div>
    )
}

const renderBanner = (node: Pagecontent): TRenderFunction => {
    const translation = node.translation?.[languageGlobal]

    if (!translation) {
        return emptyGridItem
    }

    const imgSrc = node.file?.localFile?.publicURL

    const shortenText = !!node.shorten_text

    return (children) => (
        <Banner
            header={translation.title || ''}
            headerText={translation.content}
            subHeader={translation.subtitle}
            imageSrc={imgSrc}
            shortenText={shortenText}
            pdftColor={node.color || 'blue'}
            textColor={node.textcolor}
            bgColor={node.background_color}
        >
            {children}
        </Banner>
    )
}

const renderImage = (node: Pagecontent): TRenderFunction => {
    const translation = node.translation?.[languageGlobal]

    const img = getImage(node, true)

    if (!img) {
        return emptyGridItem
    }

    let image: React.ReactNode
    if (translation?.url) {
        image = <ImageLink to={translation?.url} image={img} />
    } else {
        image = <ContentImage image={img} fluid={true} />
    }

    if (translation?.title) {
        image = (
            <figure key={node.directusId}>
                {image}
                <figcaption>{translation.title}</figcaption>
            </figure>
        )
    }

    return () => image
}

const renderCitation = (node: Pagecontent): TRenderFunction => {
    const translation = node.translation?.[languageGlobal]

    if (!translation) {
        return emptyGridItem
    }

    const img = getImage(node, true)

    const textalign = (node.textalign as Exclude<typeof node.textalign, 'center'>) || 'right'
    const author = translation.title || 'Unknown'
    const authorCompany = translation.subtitle

    return () => (
        <Citation
            image={img}
            author={author}
            citation={translation.content || ''}
            company={authorCompany}
            language={languageGlobal}
            alignText={textalign}
            to={translation.url}
            pdftColor={node.color}
        />
    )
}

// HELPER FUNCTIONS
const getHeading = (node: Pagecontent): React.ReactNode | undefined => {
    const translation = node.translation?.[languageGlobal]

    if (!translation || !node.title_type) {
        return undefined
    }

    let headingSize

    const type = node.title_type?.split('-')

    switch (type[0]) {
        case 'h1':
            headingSize = 1
            break
        case 'h2':
            headingSize = 2
            break
        case 'h3':
            headingSize = 3
            break
        case 'h4':
            headingSize = 4
            break
    }

    if (!headingSize) {
        return undefined
    }

    const headingClassName = type[1] // undefined if no style appended

    const CustomHeading = `h${headingSize}` as keyof JSX.IntrinsicElements
    return (
        <CustomHeading id={node.directusId.toString()} className={headingClassName}>
            {translation.title}
        </CustomHeading>
    )
}

const getTextAlignClassName = (node: Pagecontent): string => {
    let textalign = ''

    if (node.textalign) {
        textalign = `text-${node.textalign}`
    }

    return textalign
}

const getJustifyContentClassName = (node: Pagecontent): string => {
    const justifyContent = ''

    // if (node.justify_content === 'flex-start' || node.justify_content === 'flex-end') {
    //     // todo: remove this legacy fallback
    //     if (node.justify_content === 'flex-end') {
    //         justifyContent = 'justify-content-end'
    //     }
    // } else if (node.justify_content && node.justify_content !== 'start') {
    //     justifyContent = `justify-content-${node.justify_content}`
    // }

    return justifyContent
}

const getGridWidth = (node: Pagecontent): true | PagecontentGridWidthType => {
    let gridWidth: true | PagecontentGridWidthType = true // => auto width

    if (node.grid_width) {
        gridWidth = node.grid_width
    }

    return gridWidth
}

const getImage = (node: Pagecontent, useImgInTransl: boolean): IImage | undefined => {
    const translation = node.translation?.[languageGlobal]

    let imgSrc: string | undefined = node.file?.localFile?.publicURL

    if (useImgInTransl && translation?.image?.localFile?.publicURL) {
        imgSrc = translation.image?.localFile?.publicURL
    }

    if (!imgSrc) {
        return undefined
    }

    return {
        src: imgSrc,
        alt: translation?.alt || 'image',
        title: translation?.tooltip,
    }
}

const getDefaultActiveKey = () => `tab_${tabsRendered + 1}`

const getTabEventKey = () => {
    tabsRendered++
    return `tab_${tabsRendered}`
}

export default GridLayoutRenderer

interface GridLayoutRendererProps {
    rootItem: Pagecontent
    pageContext: PageContext<any>
    wrapRoot?: boolean
}

GridLayoutRenderer.defaultProps = {
    wrapRoot: true,
}

type TRenderFunction = (children?: React.ReactNode | React.ReactNode[]) => React.ReactNode
