import * as React from 'react'
import { INewsletterPluginData } from '../../../types/Newsletter'
import PageContext from '../../../types/PageContext'
import NewsletterSubscribe from '../../content/newsletter/NewsletterSubscribe'
import NewsletterUnsubscribe from '../../content/newsletter/NewsletterUnsubscribe'

class NewsletterPlugin extends React.Component<Props, {}> {
    render() {
        const { pageContext, pluginData, config } = this.props
        let NewsletterComponent: any = null

        if (config && config.id) {
            if (config.id === 'subscribe') {
                NewsletterComponent = NewsletterSubscribe
            }

            if (config.id === 'unsubscribe') {
                NewsletterComponent = NewsletterUnsubscribe
            }
        }

        if (NewsletterComponent) {
            return <NewsletterComponent pageContext={pageContext} pluginData={pluginData} />
        }

        return null
    }
}

export default NewsletterPlugin

interface Props {
    config: any
    pageContext: PageContext<any>
    pluginData: INewsletterPluginData
}
