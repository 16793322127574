import * as React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { ISuccessStoryList, ISuccessStoryListRecord } from '../../../types/SuccessStory'
import Translation from '../../../types/Translation'
import withTranslations from '../../hoc/withTranslations'
import SuccessStoryListNavigation from './SuccessStoryListNavigation'
import SuccessStoryListSection from './SuccessStoryListSection'

const SuccessStoryList: React.FunctionComponent<SuccessStoryListProps> = (props: SuccessStoryListProps) => {
    const { successStories, additionalHeader, t } = props

    let listSections: any = []
    const listKeys = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('')

    const successStoryList: ISuccessStoryList = {}

    listKeys.forEach((key) => {
        successStoryList[key] = []
    })

    successStories.forEach((story: ISuccessStoryListRecord) => {
        let indexKey = story.companyName?.charAt(0).toUpperCase() || ''

        if (!(indexKey in successStoryList)) {
            if (indexKey.match(/[ÁÀÂÄ]/)) {
                indexKey = 'A'
            } else if (indexKey.match(/[ÚÙÛÜ]/)) {
                indexKey = 'U'
            } else if (indexKey.match(/[ÓÒÔÖ]/)) {
                indexKey = 'O'
            } else {
                console.warn(`SuccessStory ${story} couldn't be categorized to a letter!`)
            }
        }

        successStoryList[indexKey]?.push(story)
    })

    const sortByCompany = (a: ISuccessStoryListRecord, b: ISuccessStoryListRecord) => a.companyName.localeCompare(b.companyName)

    for (const sectionKey of Object.keys(successStoryList)) {
        const sortedGlossarItems = successStoryList[sectionKey].sort(sortByCompany)

        if (sortedGlossarItems.length) {
            listSections.push(
                <SuccessStoryListSection
                    sectionKey={sectionKey}
                    sectionItems={sortedGlossarItems}
                    key={`section-${sectionKey}`}
                />,
            )
        }
    }

    if (!listSections.length) {
        listSections = <p style={{ marginTop: '20px' }}>{t('template', 'successstory.list.empty')}</p>
    }

    return (
        <Container>
            <SuccessStoryListNavigation list={successStoryList} />

            {additionalHeader && additionalHeader}

            <Row>
                <Col sm={12}>{listSections}</Col>
            </Row>
        </Container>
    )
}

export default withTranslations(SuccessStoryList)

interface SuccessStoryListProps extends Translation {
    successStories: ISuccessStoryListRecord[]
    additionalHeader?: JSX.Element
}
