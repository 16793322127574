import * as React from 'react'
import { IQuoteDialogData } from '../../../types/Quote'
import Translation from '../../../types/Translation'
import axios from '../../../utils/axios'
import withTranslations from '../../hoc/withTranslations'
import ModifyConfirmDialog from './ModifyConfirmDialog'
import CreditCardDialog from './CreditCardDialog'

class ModalModify extends React.Component<ModalModifyProps, ModalModifyState> {
    state = {
        displayConfirmDialog: false,
        errorMsg: '',
    }

    displayError = (errorMsg: string) => {
        this.setState({
            errorMsg,
        })
    }

    showConfirmDialog = () => {
        this.setState({
            displayConfirmDialog: true,
        })
    }

    closeConfirmDialog = async () => {
        this.setState({
            displayConfirmDialog: false,
        })

        if (this.props.onModifyComplete) {
            await this.props.onModifyComplete()
        }
    }

    handleModifyQuote = async (quoteDialogData: IQuoteDialogData, closeQuoteDialog: any) => {
        const { quoteId, t } = this.props
        const modifyUrl = `/api/v1.0/Quotes/${quoteId}/Modify`

        try {
            await axios.post(modifyUrl, quoteDialogData)
            closeQuoteDialog()
            this.showConfirmDialog()
        } catch (error) {
            console.log(error)

            if (error.response) {
                console.log(`status code: ${error.response.status}`)
                console.log(error.response.data)
            }

            this.displayError(t('form', 'general.form.error'))
        }
    }

    render() {
        const { t, quoteDocumentId } = this.props
        const { errorMsg, displayConfirmDialog } = this.state

        const subTitle = t('template', 'quotes.title.quote') + ` ${quoteDocumentId}`

        const btnContent = <span className={'pti-pencil'} />

        return (
            <>
                <CreditCardDialog
                    title={t('template', 'quotes.dialog.modify.title')}
                    subTitle={subTitle}
                    textContent={t('template', 'quotes.dialog.modify.text')}
                    slim={true}
                    modalActionBtnLabel={t('template', 'quotes.dialog.modify.button.label')}
                    modalAction={this.handleModifyQuote}
                    btnContent={btnContent}
                    errorMsg={errorMsg}
                />

                <ModifyConfirmDialog
                    show={displayConfirmDialog}
                    title={t('template', 'quotes.dialog.modify.title')}
                    subTitle={t('template', 'quotes.dialog.modify.confirmation.title')}
                    textContent={t('template', 'quotes.dialog.modify.confirmation.text')}
                    onClose={this.closeConfirmDialog}
                />
            </>
        )
    }
}

export default withTranslations(ModalModify)

interface ModalModifyProps extends Translation {
    quoteDocumentId: string
    quoteId: string
    onModifyComplete?: any
}

interface ModalModifyState {
    errorMsg: string
    displayConfirmDialog: boolean
}
