import React from 'react'
import { Spinner } from 'react-bootstrap'
import { IAPIData, IAPIRecord } from '../../../types/LicenseKit'
import PageContext from '../../../types/PageContext'
import axios, { allSettled } from '../../../utils/axios'
import FourHeights from './FourHeights'
import History from './History'
import * as styles from './LicensesKits.module.scss'
import ThreeHeights from './ThreeHeights'

class LicenseKits extends React.Component<LicenseKitsProps, LicenseKitsState> {
    state: LicenseKitsState = {
        displaySpinner: true,
        apiData: null,
    }

    async componentDidMount() {
        const apiRecords = this.getApiRecords()

        const promises = apiRecords.map((record) => axios.get(record.url))
        const results = await allSettled(promises)

        results.forEach((result: any, num) => {
            if (result.status === 'fulfilled') {
                const record = apiRecords[num]
                record.data = result.value.data
            }
            if (result.status === 'rejected') {
                const record = apiRecords[num]
                console.log(`${record.url}: ${result.reason}`)
            }
        })

        const apiData: any = {}

        apiRecords.forEach((record) => {
            apiData[record.name] = record.data
        })

        this.setState({
            displaySpinner: false,
            apiData,
        })
    }

    getApiRecords = (): IAPIRecord[] => {
        const apiRecords: IAPIRecord[] = [
            {
                name: 'versionsFourHeights',
                url: '/api/v1.0/Kits/DownloadVersions/fourHeights',
                data: null,
            },
            {
                name: 'versionsThreeHeights',
                url: '/api/v1.0/Kits/DownloadVersions/threeHeights',
                data: null,
            },
            {
                name: 'fourHeightsActive',
                url: '/api/v1.0/Kits/DownloadData/fourHeights/active',
                data: null,
            },
            {
                name: 'fourHeightsEval',
                url: '/api/v1.0/Kits/DownloadData/fourHeights/eval',
                data: null,
            },
            {
                name: 'threeHeightsActive',
                url: '/api/v1.0/Kits/DownloadData/threeHeights/active',
                data: null,
            },
            {
                name: 'threeHeightsEval',
                url: '/api/v1.0/Kits/DownloadData/threeHeights/eval',
                data: null,
            },
            {
                name: 'fourHeightsHistory',
                url: '/api/v1.0/Kits/DownloadData/fourHeights/evalHistory',
                data: null,
            },
            {
                name: 'threeHeightsHistory',
                url: '/api/v1.0/Kits/DownloadData/threeHeights/evalHistory',
                data: null,
            },
        ]

        return apiRecords
    }

    render(): React.ReactNode {
        const { displaySpinner, apiData } = this.state

        let content: any = (
            <div className={styles.spinnerContainer}>
                <span>loading licenses &amp; kits</span> <Spinner animation={'border'} />
            </div>
        )

        if (!displaySpinner && apiData) {
            content = (
                <>
                    <FourHeights
                        pageContext={this.props.pageContext}
                        fourHeightsActive={apiData?.fourHeightsActive}
                        fourHeightsEval={apiData.fourHeightsEval}
                        fourHeightsVersions={apiData.versionsFourHeights}
                    />

                    <ThreeHeights
                        pageContext={this.props.pageContext}
                        threeHeightsActive={apiData.threeHeightsActive}
                        threeHeightsEval={apiData.threeHeightsEval}
                        versionsThreeHeights={apiData.versionsThreeHeights}
                    />

                    <History
                        pageContext={this.props.pageContext}
                        fourHeightsHistory={apiData.fourHeightsHistory}
                        threeHeightsHistory={apiData.threeHeightsHistory}
                    />
                </>
            )
        }

        return <>{content}</>
    }
}

export default LicenseKits

interface LicenseKitsProps {
    pageContext: PageContext<any>
}

interface LicenseKitsState {
    displaySpinner: boolean
    apiData: IAPIData | null
}
