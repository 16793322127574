import * as React from 'react'
import { Col, Row } from 'react-bootstrap'
import { GlossaryRecord } from '../../../types/Glossary'
import RenderHtml from '../RenderHtml'
import * as styles from './GlossarySection.module.scss'

const GlossarySection: React.FunctionComponent<GlossarySectionProps> = (props: GlossarySectionProps) => {
    const { glossarKey, glossarItems } = props

    return (
        <Row className={styles.section}>
            <Col sm={4}>
                <span id={glossarKey} className={styles.index}>{`#${glossarKey}`}</span>
            </Col>
            <Col sm={8}>
                {glossarItems.map((item: GlossaryRecord, i) => (
                    <div key={`record-${i}`}>
                        <h2>{item.title}</h2>
                        <RenderHtml html={item.explanation} />
                    </div>
                ))}
            </Col>
        </Row>
    )
}

interface GlossarySectionProps {
    glossarKey: string
    glossarItems: GlossaryRecord[]
}

export default GlossarySection
