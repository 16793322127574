import axios from '../utils/axios'

export const fetchSixPaymentUrl = async (itemId: string, type: string, onError?: any) => {
    const param = encodeURIComponent(`${window.location.origin + window.location.pathname}?${type.toLowerCase()}Id=${itemId}`)
    const urlParams = `?returnUrl=${param}`
    const quotePayUrl = `/api/v1.0/Pay/${type}/${itemId}/Url${urlParams}`
    try {
        const response = await axios.get(quotePayUrl)
        if (response && response.data && response.data.url) {
            return response.data.url
        }
    } catch (error: any) {
        console.log(error)
        if (error.response) {
            console.log(`status code: ${error.response.status}`)
            console.log(error.response.data)
        }
        if (onError) {
            onError(error)
        }
        return null
    }
}
