import React from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import Select from 'react-select'
import { DatePicker, Localization } from 'react-widgets/cjs'
import { DateLocalizer } from 'react-widgets/IntlLocalizer'
import { formatDate } from '../../../helper/DateHelper'
import { IFilterOption } from '../../../types/Common'
import Translation from '../../../types/Translation'
import ColoredBox from '../../content/ColoredBox'
import withTranslations from '../../hoc/withTranslations'
import ContentButton from '../ContentButton'
import * as styles from './ContactForm.module.scss' // TODO
import GenericForm from './GenericForm'

class CallbackForm extends React.Component<CallbackFormProps, CallbackFormState> {
    state = {
        displayOptional: false,
        form: {
            areaOfInterest: { value: '', label: '' },
            message: '',
            gender: 'Male',
            firstname: '',
            lastname: '',
            company: '',
            phone: '',
            date: formatDate(new Date(), this.props.language),
            time: { value: '', label: '' },
            email: '',
        },
        errorTime: false,
        errorArea: false,
    }

    handleSubmit = () => {
        this.showFormError(this.state.form.areaOfInterest?.value, this.state.form.time?.value)

        const hasSelected = this.state.form.areaOfInterest?.value && this.state.form.time?.value
        if (this.props.onSubmit && hasSelected) {
            const apiData = this.state.form
            const sentData = this.prepareSentData(apiData)
            this.props.onSubmit(apiData, sentData)
        }
    }

    showFormError(errorArea: string, errorTime: string) {
        const newState = {
            errorTime: errorTime === '',
            errorArea: errorArea === '',
        }
        this.setState(newState)
    }

    handleChange = (e: any) => {
        const field = e.target.name
        const newState = {
            form: {
                ...this.state.form,
                [field]: e.target.value,
            },
        }

        this.setState(newState)
    }

    handleGender = (e: any) => {
        this.setState({
            form: {
                ...this.state.form,
                gender: e.target.value,
            },
        })
    }

    handleAreaChange = (opt: any) => {
        this.setState({
            form: {
                ...this.state.form,
                areaOfInterest: opt,
            },
        })
    }

    handleDateChange = (opt: any) => {
        this.setState({
            form: {
                ...this.state.form,
                date: formatDate(opt, this.props.language),
            },
        })
    }

    handleTimeChange = (opt: any) => {
        this.setState({
            form: {
                ...this.state.form,
                time: opt,
            },
        })
    }

    prepareSentData(formData: any) {
        const sentData: any = {}

        Object.keys(formData).forEach((key) => {
            const val = formData[key]
            let field
            const salutation = this.props.t('form', 'general.field.salutation.label')

            switch (key) {
                case 'areaOfInterest':
                    field = this.props.t('form', 'callback.areaofinterest.title')
                    if (typeof val === 'object' && val !== null) {
                        sentData[field] = val.label
                    }
                    break
                case 'message':
                    field = this.props.t('form', 'contactForm.field.message.label')
                    sentData[field] = val
                    break
                case 'gender':
                    if (val === 'Male') {
                        sentData[salutation] = this.props.t('form', 'general.salutation.mister')
                    } else if (val === 'Female') {
                        sentData[salutation] = this.props.t('form', 'general.salutation.misses')
                    } // else if (val === 'Other') {
                    //   sentData[salutation] = this.props.t('form', 'general.salutation.other')
                    // }
                    break

                case 'firstname':
                    field = this.props.t('form', 'general.field.firstname.label')
                    sentData[field] = val
                    break
                case 'lastname':
                    field = this.props.t('form', 'general.field.lastname.label')
                    sentData[field] = val
                    break
                case 'company':
                    field = this.props.t('form', 'general.field.company.label')
                    sentData[field] = val
                    break
                case 'phone':
                    field = this.props.t('form', 'general.field.telephone.label')
                    sentData[field] = val
                    break
                case 'date':
                    field = this.props.t('form', 'general.field.date.label')
                    sentData[field] = val
                    break
                case 'time':
                    field = this.props.t('form', 'callback.time.title')
                    if (typeof val === 'object' && val !== null) {
                        sentData[field] = val.label
                    }
                    break
                case 'email':
                    field = this.props.t('form', 'general.field.email.label')
                    sentData[field] = val
                    break

                default:
            }
        })
        return sentData
    }

    render() {
        const { t, language, areasOfInterest, spansOfTime } = this.props
        const { errorTime, errorArea } = this.state

        return (
            <GenericForm onSubmit={this.handleSubmit}>
                <Row>
                    <ColoredBox>
                        <div className={styles.coloredBox}>
                            <Row>
                                <Col sm={12} md={6}>
                                    <Row>
                                        <Col sm={12}>
                                            <h2>{t('form', 'callback.areaofinterest.title')}</h2>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={12}>
                                            <Form.Group controlId={'form.area'}>
                                                <Form.Label>
                                                    {t('form', 'callback.areaofinterest.title')} <span>*</span>
                                                </Form.Label>
                                                <Select
                                                    onChange={this.handleAreaChange}
                                                    options={areasOfInterest}
                                                    classNamePrefix={'pdft_select'}
                                                />
                                                {errorArea && (
                                                    <p className="invalid pt-0">
                                                        {t('form', 'form.validate.areaofinterest.invalid')}
                                                    </p>
                                                )}
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={12}>
                                            <Form.Group style={{ marginTop: '5px' }} controlId={'form.message'}>
                                                <Form.Label>{t('form', 'callback.message.label')}</Form.Label>
                                                <div>
                                                    <Form.Control
                                                        as="textarea"
                                                        rows="5"
                                                        name={'message'}
                                                        onChange={this.handleChange}
                                                    />
                                                </div>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col>
                                    <Row>
                                        <Col sm={12}>
                                            <h2>{t('form', 'callback.contact.title')}</h2>
                                        </Col>
                                        <Col sm={12}>
                                            <Form.Group className={styles.salutation} controlId={'form.salutation'}>
                                                <Form.Label>{t('form', 'general.field.salutation.label')}</Form.Label>

                                                <div className={`d-flex align-items-center ${styles.salutationRadios}`}>
                                                    <Form.Check
                                                        type={'radio'}
                                                        label={t('form', 'general.salutation.mister')}
                                                        id={'radio-male'}
                                                        name="gender"
                                                        inline={true}
                                                        value={'Male'}
                                                        defaultChecked={true}
                                                        onChange={this.handleGender}
                                                    />

                                                    <Form.Check
                                                        type={'radio'}
                                                        label={t('form', 'general.salutation.misses')}
                                                        id={'radio-female'}
                                                        name="gender"
                                                        value={'Female'}
                                                        inline={true}
                                                        onChange={this.handleGender}
                                                    />
                                                </div>
                                            </Form.Group>
                                        </Col>
                                        <Col sm={6} />
                                    </Row>

                                    <Row>
                                        <Col sm={6}>
                                            <Form.Group controlId={'form.firstname'}>
                                                <Form.Label>
                                                    {t('form', 'general.field.firstname.label')}
                                                    <span>*</span>
                                                </Form.Label>
                                                <Form.Control
                                                    type={'text'}
                                                    name={'firstname'}
                                                    required={true}
                                                    onChange={this.handleChange}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {t('form', 'form.validate.firstname.invalid')}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                        <Col sm={6}>
                                            <Form.Group controlId={'form.lastname'}>
                                                <Form.Label>
                                                    {t('form', 'general.field.lastname.label')} <span>*</span>
                                                </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="lastname"
                                                    required={true}
                                                    onChange={this.handleChange}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {t('form', 'form.validate.lastname.invalid')}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={12}>
                                            <Form.Group className={styles.pseudoHeading} controlId={'form.company'}>
                                                <Form.Label>{t('form', 'general.field.company.label')}</Form.Label>
                                                <Form.Control type={'text'} name={'company'} onChange={this.handleChange} />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={12}>
                                            <Form.Group controlId={'form.telephone'}>
                                                <Form.Label>
                                                    {t('form', 'general.field.telephone.label')} <span>*</span>
                                                </Form.Label>
                                                <Form.Control
                                                    type={'text'}
                                                    name={'phone'}
                                                    onChange={this.handleChange}
                                                    required={true}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {t('form', 'form.validate.telephone.invalid')}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={12}>
                                            <Form.Group controlId={'form.date'}>
                                                <Form.Label>
                                                    {t('form', 'general.field.date.label')} <span>*</span>
                                                </Form.Label>
                                                <Localization date={new DateLocalizer({ culture: language })}>
                                                    <DatePicker
                                                        defaultValue={new Date()}
                                                        min={new Date()}
                                                        onChange={this.handleDateChange}
                                                    />
                                                </Localization>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={12}>
                                            <Form.Group controlId={'form.time'}>
                                                <Form.Label>
                                                    {t('form', 'callback.time.title')} <span>*</span>
                                                </Form.Label>
                                                <Select
                                                    onChange={this.handleTimeChange}
                                                    options={spansOfTime}
                                                    classNamePrefix={'pdft_select'}
                                                    required={true}
                                                />
                                                {errorTime && (
                                                    <p className="invalid pt-0">{t('form', 'form.validate.time.invalid')}</p>
                                                )}
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={12}>
                                            <Form.Group controlId={'form.email'}>
                                                <Form.Label>
                                                    {t('form', 'general.field.email.label')} <span>*</span>
                                                </Form.Label>
                                                <Form.Control
                                                    type="email"
                                                    name="email"
                                                    required={true}
                                                    onChange={this.handleChange}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {t('form', 'form.validate.email.invalid')}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Form.Group>
                                                <ContentButton type="submit" variant="primary">
                                                    {t('form', 'general.btn.send')}
                                                </ContentButton>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                    </ColoredBox>
                </Row>
            </GenericForm>
        )
    }
}

export default withTranslations(CallbackForm)

interface CallbackFormProps extends Translation {
    onSubmit: any
    language: any
    areasOfInterest: IFilterOption[]
    spansOfTime: IFilterOption[]
}

interface CallbackFormState {
    displayOptional: boolean
    form: {
        areaOfInterest: IFilterOption
        message: string
        gender: string
        firstname: string
        lastname: string
        company: string
        phone: string
        date: string
        time: IFilterOption
        email: string
    }
    errorTime: boolean
    errorArea: boolean
}
