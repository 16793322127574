import * as React from 'react'
import { Button, Col, Container, Image, Modal, Row } from 'react-bootstrap'
import ConfirmationImg from '../../../images/massband.jpg'
import Translation from '../../../types/Translation'
import withTranslations from '../../hoc/withTranslations'
import * as styles from './QuoteDialog.module.scss'

class ModifyConfirmDialog extends React.Component<ModifyConfirmDialogProps, ModifyConfirmDialogState> {
    render() {
        const { title, subTitle, textContent, t, show, onClose } = this.props

        return (
            <>
                <Modal show={show} onHide={onClose} className={styles.modal}>
                    <Modal.Header closeButton={true}>
                        <h2>{title}</h2>
                    </Modal.Header>
                    <Modal.Body>
                        <h5 className={styles.subTitle}>{subTitle}</h5>
                        <Container>
                            <Row>
                                <Col
                                    sm={8}
                                    style={{ paddingLeft: 0, paddingRight: 0 }}
                                    dangerouslySetInnerHTML={{ __html: textContent }}
                                />
                                <Col sm={4}>
                                    <Image src={ConfirmationImg} width={'100%'} />
                                </Col>
                            </Row>
                        </Container>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant={'primary'} onClick={onClose} className={styles.cancelBtn}>
                            {t('template', 'quotes.dialog.close.label')}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        )
    }
}
export default withTranslations(ModifyConfirmDialog)

interface ModifyConfirmDialogProps extends Translation {
    title: string
    subTitle: string
    textContent: any
    show: boolean
    onClose: any
}

interface ModifyConfirmDialogState {}
