import React, { useMemo } from 'react'
import { Alert, Form } from 'react-bootstrap'
import { useDropzone } from 'react-dropzone'
import Translation from '../../../../types/Translation'
import withTranslations from '../../../hoc/withTranslations'
import * as styles from './UploadForm.module.scss'

const baseStyle = {
    minWidth: '200px',
    minHeight: '100px',
    outline: '#008bd2 dashed 2px',
    outlineOffset: '-8px',
    padding: '10px',
    background: '#f2f9fd',
    color: '#008bd2',
    textAlign: 'center' as const,
    cursor: 'pointer',
}

const activeStyle = {
    background: '#e6f3fa',
}

const UploadForm: React.FunctionComponent<UploadFormProps> = (props: UploadFormProps) => {
    const onDrop = (acceptedFiles: File[]) => {
        if (props.onDrop) {
            props.onDrop(acceptedFiles)
        }
    }

    const { t, progressing, errorMsg, successMsg } = props

    const accept = '.bin'

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, accept })

    const style = useMemo(
        () => ({
            ...baseStyle,
            ...(isDragActive ? activeStyle : {}),
        }),
        [isDragActive],
    )

    let displayMsg: any = null

    if (errorMsg) {
        displayMsg = <Alert variant={'danger'}>{errorMsg}</Alert>
    }

    if (successMsg) {
        displayMsg = <Alert variant={'success'}>{successMsg}</Alert>
    }

    return (
        <div>
            <div {...getRootProps({ style })}>
                <input {...getInputProps()} />
                <Form.Label className={styles.uploadLabel}>
                    <span className={`pti-cloud-upload ${styles.iconCloud} ${progressing ? styles.iconProgressing : ''}`} />
                    <div>
                        <strong className={styles.choose}>{t('form', 'licenseactivator.choosefile')}</strong>
                        <span>{t('form', 'licenseactivator.draghere')}</span>
                    </div>
                </Form.Label>

                {displayMsg}
            </div>
        </div>
    )
}

export default withTranslations(UploadForm)

interface UploadFormProps extends Translation {
    onDrop?: any
    progressing?: boolean
    errorMsg: string
    successMsg: string
}
