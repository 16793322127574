import * as React from 'react'
import { Col, Row } from 'react-bootstrap'
import PageContext from '../../../types/PageContext'
import * as styles from './Sitemap.module.scss'
import Translation from '../../../types/Translation'
import withTranslations from '../../hoc/withTranslations'
import { ReactNode } from 'react'
import { IPageHierarchyLeaf, IPageHierarchyTree } from '../../../types/PageHierarchy'

const Sitemap: React.FunctionComponent<SitemapProps> = (props: SitemapProps) => {
    const startBuildingTree = (item: IPageHierarchyTree) => {
        let tree: ReactNode[] = []

        for (const key in item) {
            const result = buildTree(item[key], 1)
            tree.push(result)
        }

        return tree
    }

    const buildTree = (item: IPageHierarchyLeaf, hierarchy: number) => {
        let treeElement: ReactNode[] = []
        let titleElement: ReactNode

        const hierarchyClass = ['hierarchyOne', 'hierarchyTwo', 'hierarchyThree', 'hierarchyFour']
        const style = styles[hierarchyClass[hierarchy - 1]] || ''

        item.translation.forEach((t) => {
            if (
                t.url?.endsWith('/404/') ||
                t.url?.endsWith(`/${t.language}/`) ||
                t.url?.endsWith('/mypdftools/') ||
                t.url?.endsWith('/sitemap/')
            )
                return

            if (t.language === props.pageContext.language) {
                let title: string = t.title || ''
                const titleExtension: string = t.titleExtension || ''

                if (t.titleExtension) title += ' | ' + titleExtension
                const url = t.url || ''

                titleElement = url ? <a href={`${url}`}>{title}</a> : (title as ReactNode)
            }
        })

        let childrenList: ReactNode[] = []

        if (item.children?.length > 0) {
            if (hierarchy === 1) titleElement = <b>{titleElement}</b>

            let children: any[] = []
            item.children.forEach((c) => {
                children.push(buildTree(c, hierarchy + 1))
            })
            childrenList.push(<ul key={`sitemap-list-${item.hierarchyId}`}>{children}</ul>)
        }

        treeElement.push(
            <li className={style} key={`sitemap-item-${item.hierarchyId}`}>
                {titleElement}
                {childrenList && childrenList}
            </li>,
        )
        return treeElement
    }

    const tree: ReactNode = props.pageContext.pageHierarchy ? startBuildingTree(props.pageContext.pageHierarchy) : '' // TODO types
    const { t } = props

    return (
        <Row>
            <Col>
                <h1>{t('template', 'sitemap.title')}</h1>
                <ul key="sitemap">{tree && tree}</ul>
            </Col>
        </Row>
    )
}

export default withTranslations(Sitemap)

interface SitemapProps extends Translation {
    pageContext: PageContext<any>
}
