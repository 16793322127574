import * as React from 'react'
import { Button, Col, Container, Modal, Row } from 'react-bootstrap'
import Translation from '../../../types/Translation'
import withTranslations from '../../hoc/withTranslations'
import AnyLinkButton from '../AnyLinkButton'
import AnyLink from '../../AnyLink'
import * as styles from './CancelConfirmDialog.module.scss'

class CancelConfirmDialog extends React.Component<CancelConfirmDialogProps, CancelConfirmDialogState> {
    render() {
        const { title, subTitle, textContent, t, show, onClose, contactLinkUrl } = this.props

        return (
            <>
                <Modal show={show} onHide={onClose} className={styles.modal} dialogClassName={styles.dialog}>
                    <Modal.Header closeButton={true}>
                        <h2>{title}</h2>
                    </Modal.Header>
                    <Modal.Body>
                        <h5 className={styles.subTitle}>{subTitle}</h5>

                        <div dangerouslySetInnerHTML={{ __html: textContent }} />

                        <Container>
                            <Row>
                                <Col
                                    sm={6}
                                    dangerouslySetInnerHTML={{
                                        __html: t('template', 'quotes.dialog.cancel.confirmation.feedback.text'),
                                    }}
                                    style={{ paddingLeft: 0 }}
                                />

                                <Col sm={6} style={{ paddingLeft: 0, paddingRight: 0 }}>
                                    <AnyLinkButton
                                        to="mailto:pdfmarketing@pdf-tools.com?Subject=Quote cancellation feedback"
                                        className={`mb-4 ${styles.emailBtn}`}
                                    >
                                        {t('template', 'quotes.dialog.cancel.confirmation.emailfeedback.title')}
                                    </AnyLinkButton>

                                    <AnyLinkButton
                                        to={contactLinkUrl}
                                        title={t('template', 'quotes.dialog.cancel.confirmation.contactfeedback.tooltip')}
                                        block={true}
                                    >
                                        {t('template', 'quotes.dialog.cancel.confirmation.contactfeedback.title')}
                                    </AnyLinkButton>
                                </Col>
                            </Row>
                        </Container>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant={'primary'} onClick={onClose} className={styles.cancelBtn}>
                            {t('template', 'quotes.dialog.close.label')}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        )
    }
}

export default withTranslations(CancelConfirmDialog)

interface CancelConfirmDialogProps extends Translation {
    title: string
    subTitle: string
    textContent: any
    show: boolean
    onClose: any
    contactLinkUrl: string
}

interface CancelConfirmDialogState {}
