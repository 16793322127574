import * as React from 'react'
import PageContext from '../../../types/PageContext'
import Sitemap from '../sitemap/Sitemap'

class SitemapPlugin extends React.Component<Props, {}> {
    render() {
        const { pageContext } = this.props
        return <Sitemap pageContext={pageContext} />
    }
}

export default SitemapPlugin

interface Props {
    config: any
    pageContext: PageContext<any>
    pluginData: any
}
