import _cloneDeep from 'clone-deep'
import React from 'react'
import postifyData, { findCountryOption } from '../../../helper/FormHelper'
import { isLoggedIn } from '../../../services/auth.js'
import { IFilterOption } from '../../../types/Common'
import { ICountries } from '../../../types/Country'
import PageContext from '../../../types/PageContext'
import Translation from '../../../types/Translation'
import axios from '../../../utils/axios'
import withTranslations from '../../hoc/withTranslations'
import AccountForm from './AccountForm'
import { PasswordChange } from './PasswordChange'

class AccountProfile extends React.Component<AccountProfileProps, AccountProfileState> {
    state = {
        errorMsg: '',
        successMsg: '',
    }

    handleFormMount = async (component: any) => {
        const url = '/api/v1.0/Users/current'

        try {
            const response = await axios.get(url)

            if (response.data) {
                const { fullname, ...initState } = response.data

                const lang = response.data.language
                const country = response.data.customer.country

                let langOpt = { value: '', label: '' }
                let countryOpt = { value: '', label: '' }

                const selectedLangOpt = this.props.languageOptions.find((languageOpt) => languageOpt.value === lang)

                if (selectedLangOpt) {
                    langOpt = selectedLangOpt
                }

                const foundOpt = findCountryOption(country, this.props.countryOptions, this.props.countries)

                if (foundOpt) {
                    countryOpt = foundOpt
                }

                component.setState({
                    form: {
                        ...initState,
                        language: langOpt,
                        customer: {
                            ...initState.customer,
                            country: countryOpt,
                        },
                    },
                    spinner: false,
                })
            }
        } catch (e) {
            console.log(e)
        }
    }

    handleSubmit = async (formState: any) => {
        if (!isLoggedIn()) {
            return
        }

        await this.updateUser(formState)
    }

    updateUser = async (formState: any) => {
        const url = '/api/v1.0/Users/current'

        const customerState = _cloneDeep(formState.form.customer)
        const copyFormState = _cloneDeep(formState.form)

        const customerData = postifyData(customerState)
        const formData = postifyData(copyFormState)

        formData.customer = customerData

        try {
            await axios.put(url, formData)

            this.displaySuccess()
        } catch (error) {
            console.log(error)

            if (error.response) {
                console.log(`status code: ${error.response.status}`)
                console.log(error.response.data)
            }

            if (error.response.status === 403) {
                this.displayFormError(this.props.t('form', 'general.authentification.failed'))
            } else {
                this.displayFormError(this.props.t('form', 'general.form.error'))
            }
        }
    }

    displayFormError = (errorMsg: string) => {
        this.setState({
            successMsg: '',
            errorMsg,
        })
    }

    displaySuccess = () => {
        this.setState({
            successMsg: this.props.t('form', 'account.update.success'),
            errorMsg: '',
        })
    }

    render() {
        const { errorMsg, successMsg } = this.state
        const { t } = this.props
        const { pageContext } = this.props

        const { language } = pageContext

        let defaultCountryOpt = this.props.countryOptions[0]
        let defaultLangOpt = this.props.languageOptions[0]

        const countryOptSwitzerland = findCountryOption('Switzerland', this.props.countryOptions, this.props.countries)

        if (countryOptSwitzerland) {
            defaultCountryOpt = countryOptSwitzerland
        }

        const langOptReal = this.props.languageOptions.find((langOpt) => langOpt.value === language)

        if (langOptReal) {
            defaultLangOpt = langOptReal
        }

        return (
            <>
                <PasswordChange t={t} />
                <AccountForm
                    countryOptions={this.props.countryOptions}
                    languageOptions={this.props.languageOptions}
                    onSubmit={this.handleSubmit}
                    onComponentMount={this.handleFormMount}
                    errorMsg={errorMsg}
                    successMsg={successMsg}
                    emailAddrInfo={t('form', 'account.profile.email.info')}
                    submitBtnText={t('form', 'account.profile.update.btn')}
                    defaultCountryOpt={defaultCountryOpt}
                    defaultLangOpt={defaultLangOpt}
                    displayUpdateFlash={true}
                />
            </>
        )
    }
}

export default withTranslations(AccountProfile)

interface AccountProfileProps extends Translation {
    countryOptions: IFilterOption[]
    countries: ICountries
    languageOptions: IFilterOption[]
    pageContext: PageContext<any>
}

interface AccountProfileState {
    errorMsg: string
    successMsg: string
}
