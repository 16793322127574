import React from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import Translation from '../../../types/Translation'
import ColoredBox from '../../content/ColoredBox'
import withTranslations from '../../hoc/withTranslations'
import GenericForm from './GenericForm'
import * as styles from './NewsletterForm.module.scss'

class NewsletterForm extends React.Component<NewsletterFormProps, NewsletterFormState> {
    constructor(props: NewsletterFormProps) {
        super(props)
        this.state = {
            form: {
                gender: 'Male',
                firstname: '',
                lastname: '',
                email: '',
                language: 'German',
            },
        }
    }

    handleSubmit = () => {
        if (this.props.onSubmit) {
            const formData = this.state.form
            this.props.onSubmit(formData)
        }
    }

    handleChange = (e: any) => {
        const field = e.target.name
        const newState = {
            form: {
                ...this.state.form,
                [field]: e.target.value,
            },
        }

        this.setState(newState)
    }

    handleGender = (e: any) => {
        this.setState({
            form: {
                ...this.state.form,
                gender: e.target.value,
            },
        })
    }

    handleLanguageChange = (e: any) => {
        this.setState({
            form: {
                ...this.state.form,
                language: e.target.value,
            },
        })
    }

    render() {
        const { t } = this.props

        return (
            <GenericForm onSubmit={this.handleSubmit}>
                <ColoredBox noWidth={true}>
                    <div className={styles.coloredBox}>
                        <Col sm={12}>
                            <Row>
                                <Col sm={12}>
                                    <h1>{t('form', 'newsletterForm.title')}</h1>

                                    <Form.Group className={styles.salutation} controlId={'form.salutation'}>
                                        <Form.Label>{t('form', 'general.field.salutation.label')}</Form.Label>

                                        <div className={`d-flex align-items-center ${styles.salutationRadios}`}>
                                            <Form.Check
                                                type={'radio'}
                                                label={t('form', 'general.salutation.mister')}
                                                id={'radio-male'}
                                                name="gender"
                                                inline={true}
                                                value={'Male'}
                                                defaultChecked={true}
                                                onChange={this.handleGender}
                                            />

                                            <Form.Check
                                                type={'radio'}
                                                label={t('form', 'general.salutation.misses')}
                                                id={'radio-female'}
                                                name="gender"
                                                value={'Female'}
                                                inline={true}
                                                onChange={this.handleGender}
                                            />
                                        </div>
                                    </Form.Group>
                                </Col>
                                <Col sm={6} />
                            </Row>

                            <Row>
                                <Col sm={6}>
                                    <Form.Group controlId={'form.firstname'}>
                                        <Form.Label>
                                            {t('form', 'general.field.firstname.label')}
                                            <span>*</span>
                                        </Form.Label>
                                        <Form.Control
                                            type={'text'}
                                            name={'firstname'}
                                            required={true}
                                            onChange={this.handleChange}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {t('form', 'form.validate.firstname.invalid')}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col sm={6}>
                                    <Form.Group controlId={'form.lastname'}>
                                        <Form.Label>
                                            {t('form', 'general.field.lastname.label')}
                                            <span>*</span>
                                        </Form.Label>
                                        <Form.Control
                                            type={'text'}
                                            name={'lastname'}
                                            required={true}
                                            onChange={this.handleChange}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {t('form', 'form.validate.lastname.invalid')}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={12}>
                                    <Form.Group controlId={'form.email'}>
                                        <Form.Label>
                                            {t('form', 'general.field.email.label')}
                                            <span>*</span>
                                        </Form.Label>
                                        <Form.Control type="email" name={'email'} required={true} onChange={this.handleChange} />
                                        <Form.Control.Feedback type="invalid">
                                            {t('form', 'form.validate.email.invalid')}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={12}>
                                    <Form.Group controlId={'form.langauge'}>
                                        <Form.Label>{t('form', 'newsletterForm.field.language')}</Form.Label>
                                        <div className={`d-flex align-items-center ${styles.salutationRadios}`}>
                                            <Form.Check
                                                type={'radio'}
                                                label={t('form', 'general.field.english')}
                                                id={'radio-english'}
                                                name="language"
                                                inline={true}
                                                value={'English'}
                                                onChange={this.handleLanguageChange}
                                            />

                                            <Form.Check
                                                type={'radio'}
                                                label={t('form', 'general.field.german')}
                                                id={'radio-german'}
                                                name="language"
                                                value={'Deutsch'}
                                                inline={true}
                                                defaultChecked={true}
                                                onChange={this.handleLanguageChange}
                                            />
                                        </div>
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row>
                                <Col sm={6}>
                                    <Form.Group>
                                        <input
                                            type={'submit'}
                                            className={'btn btn-primary'}
                                            value={t('form', 'newsletterForm.register')}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Col>
                    </div>
                </ColoredBox>
            </GenericForm>
        )
    }
}

export default withTranslations(NewsletterForm)

interface NewsletterFormProps extends Translation {
    onSubmit: any
}

interface NewsletterFormState {
    form: any
}
