import { useState } from 'react';
import axiosUtil from '../../../utils/axios';

export enum ErrorStates {
    NoError,
    PasswordTooShort,
    MissingPassword,
    MissingConfirmPassword,
    PasswordsDoNotMatch,
    GenericError,
}

// update password function
const updatePassword = async (password: string) => {
    return await axiosUtil.post('/api/v1.0/Users/current/ChangePassword', {
        "newPassword": password,
    })
}

const usePasswordChange = () => {
    const [error, setError] = useState(ErrorStates.NoError);
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false);

    const [password, setPassword] = useState<string>();
    const [confirmPassword, setConfirmPassword] = useState<string>();

    const handleSubmit = async (event: any) => {
        event.preventDefault();

        if (!password) {
            setError(ErrorStates.MissingPassword)
            return;
        }
        if (password.length < 8) {
            setError(ErrorStates.PasswordTooShort)
            return;
        }
        if (!confirmPassword) {
            setError(ErrorStates.MissingConfirmPassword)
            return;
        }
        if (password !== confirmPassword) {
            setError(ErrorStates.PasswordsDoNotMatch);
            return;
        }

        try {
            setLoading(true);
            await updatePassword(password);
            setSuccess(true);
            setError(ErrorStates.NoError)
        } catch (e) {
            setError(ErrorStates.GenericError)
        } finally {
            setLoading(false);
        }
    }

    return { error, setError, success, loading, password, confirmPassword, setPassword, setConfirmPassword, handleSubmit, ErrorStates };
}

export default usePasswordChange;