import marked from 'marked'
import { Nullable } from '../types/Common'

// ///// Common Code
const DEFAULT_LIST_CLASS = 'ul-chevron'
const DEFAULT_TABLE_CLASS = 'table table-striped'

export interface IContentParserOptions {
    listClass?: string
}

// ///// Markdown Parser

class PdftMarkedRenderer extends marked.Renderer {
    constructor(options?: PdftMarkedOptions) {
        super(options)
    }

    list(body: string, ordered: boolean, start: number): string {
        if (!ordered) {
            const listClass = (this.options as PdftMarkedOptions)?.listClass || DEFAULT_LIST_CLASS
            return `<ul class="${listClass}">${body}</ul>`
        }
        return super.list(body, ordered, start)
    }

    // maybe add @link replacement here
    // link(href: string | null, title: string | null, text: string): string {
    //     return ''
    // }

    table(header: string, body: string): string {
        if (header) {
            header = `<thead>\n${header}</thead>\n`
        }
        if (body) {
            body = `<tbody>\n${body}</tbody>\n`
        }
        return `<table class="${DEFAULT_TABLE_CLASS}">\n${header}${body}</table>\n`
    }

    tablecell(content: string, flags: { header: boolean; align: 'center' | 'left' | 'right' | null }): string {
        const type = flags.header ? 'th' : 'td'
        const align = flags.align ? ` class="text-${flags.align}"` : ''

        return `<${type}${align}>${content}</${type}>\n`
    }
}

interface PdftMarkedOptions extends marked.MarkedOptions {
    /** css class added to lists */
    listClass?: string
}

const defaultMarkedOptions: PdftMarkedOptions = {
    headerIds: false,
    renderer: new PdftMarkedRenderer(),
}

/**
 * parses Markdown to HTML with frontend style classes
 *
 * @param markdown Markdown string
 * @returns html HTML string
 */
export const parseMarkdown = (markdown: Nullable<string>, options?: IContentParserOptions): string | null =>
    markdown ? marked(markdown, { ...defaultMarkedOptions, ...options } as PdftMarkedOptions) : null

// ///// HTML Parser

const REGEX_TABLE_TAG = /<table[^>]*>/gi
const REGEX_TABLE_ID = / id="[^"]*"/gi
const REGEX_UL_TAG = /<ul([^>]*)>/gi

const getTableId = (html: string): string | null => {
    const tableArray = html.match(REGEX_TABLE_TAG)
    if (tableArray) {
        const table = tableArray[0]
        const tableId = table.match(REGEX_TABLE_ID)
        return tableId ? tableId[0] : null
    }
    return null
}

const addTableStyle = (html: string): string => {
    const id = getTableId(html)
    return html.replace(REGEX_TABLE_TAG, `<table ${id} class="${DEFAULT_TABLE_CLASS}">`)
}

const addListStyle = (html: string, options?: IContentParserOptions): string => {
    const listClass = options?.listClass || DEFAULT_LIST_CLASS

    return html.replace(REGEX_UL_TAG, (fullTag, innerTag) => {
        if ((innerTag as string)?.includes('class')) {
            return fullTag // don't touch if any css class is defined
        }
        return `<ul class="${listClass}" ${innerTag}>`
    })
}

/**
 * parses wysiwyg HTML to HTML with frontend style classes
 *
 * @param html HTML string
 * @returns html HTML string
 */
export const parseHtml = (html: Nullable<string>, options?: IContentParserOptions): string | null => {
    if (html) {
        html = addTableStyle(html)
        html = addListStyle(html)
        return html
    }
    return null
}

// ///// Text Parser
const HTML_CHARS_MAP: Record<string, string> = {
    '&': '&amp;',
    '<': '&lt;',
    '>': '&gt;',
    '"': '&quot;',
    "'": '&#039;',
    '\n': '<br/>',
}

/**
 * parses text to HTML by escaping HTML characters and replacing newline chars to HTML-breaks
 *
 * @param text plain text string
 * @returns html HTML string
 */
export const parseText = (text: Nullable<string>): string | null => text?.replace(/[&<>"'\n]/g, (m) => HTML_CHARS_MAP[m]) || null
