import React from 'react'
import { Col, Pagination, Row } from 'react-bootstrap'
import { ISearchResults } from '../../../types/Search'
import * as styles from './SearchPagination.module.scss'
import SearchPaginationItem from './SearchPaginationItem'

class SearchPagination extends React.Component<SearchPaginationProps, SearchPaginationState> {
    render() {
        const { searchResults } = this.props
        const { pageNumber, totalPages } = searchResults

        const paginationItems = Array.from({ length: totalPages }, (_, i) => {
            const num = i + 1

            const clickHandler = () => {
                this.props.onPaginationClick(num)
            }

            return (
                <SearchPaginationItem onClick={clickHandler} active={pageNumber === num} key={`pagItem-${i}`}>
                    {num}
                </SearchPaginationItem>
            )
        })

        return (
            <Row>
                <Col sm={12}>
                    <nav className={styles.paginationContainer}>
                        <Pagination>{paginationItems}</Pagination>
                    </nav>
                </Col>
            </Row>
        )
    }
}

export default SearchPagination

interface SearchPaginationProps {
    searchResults: ISearchResults
    onPaginationClick: any
}

interface SearchPaginationState {}
