import * as React from 'react'
import { Alert, Button, Form, Modal } from 'react-bootstrap'
import { IQuoteDialogData } from '../../../types/Quote'
import Translation from '../../../types/Translation'
import AnyLink from '../../AnyLink'
import withTranslations from '../../hoc/withTranslations'
import * as styles from './QuoteDialog.module.scss'

class CreditCardDialog extends React.Component<QuoteDialogProps, QuoteDialogState> {
    state: QuoteDialogState = {
        show: false,
        orderReference: '',
        comment: '',
        acceptTerms: false,
        errorLicense: '',
    }

    handleShow = () => {
        this.setState({
            show: true,
        })
    }

    handleClose = () => {
        this.setState({
            show: false,
        })
    }

    handleChange = (e: any) => {
        const field = e.target.name
        const newState = {
            ...this.state,
            [field]: e.target.value,
        }

        this.setState(newState)
    }

    handleModalAction = async () => {
        if (this.state.acceptTerms || this.props.slim) {
            if (this.props.modalAction) {
                const quoteDialogData: IQuoteDialogData = {
                    orderReference: this.state.orderReference,
                    comment: this.state.comment,
                }

                await this.props.modalAction(quoteDialogData, this.handleClose)
            }
        } else {
            this.setState({ errorLicense: this.props.t('template', 'error.license.terms') })
        }
    }

    renderLicenseLabel() {
        const { t } = this.props
        const labelTxt = t('form', 'license.terms.label') + ' '

        const licenseLink = (
            <AnyLink to={'/public/downloads/licenses/' + t('slug', 'license.terms.filename')}>
                {t('form', 'license.terms.label.link')}
            </AnyLink>
        )

        const label = (
            <div>
                <span>{labelTxt}</span>
                {licenseLink} *
            </div>
        )

        return label
    }

    toggleAcceptTerms = () => {
        if (!this.state.acceptTerms) {
            this.setState({
                errorLicense: '',
            })
        }
        this.setState({
            acceptTerms: !this.state.acceptTerms,
        })
    }

    render() {
        const { show, acceptTerms, orderReference, comment, errorLicense } = this.state

        const { title, subTitle, textContent, t, additionalContent, modalActionBtnLabel, errorMsg, slim } = this.props

        const renderLicenseLabel = this.renderLicenseLabel()

        return (
            <>
                <Button variant="primary" onClick={this.handleShow} className={styles.payBtn} size={'sm'}>
                    {this.props.btnContent}
                </Button>

                <Modal show={show} onHide={this.handleClose} className={styles.modal}>
                    <Modal.Header closeButton={true}>
                        <h2>{title}</h2>
                    </Modal.Header>
                    <Modal.Body>
                        {errorMsg && <Alert variant={'danger'}>{errorMsg}</Alert>}
                        <h5 className={styles.subTitle}>{subTitle}</h5>
                        {textContent}

                        {!slim && (
                            <Form.Group controlId={'orderreference'}>
                                <Form.Label>
                                    <strong> {t('template', 'quotes.dialog.orderreference.label')} </strong>
                                </Form.Label>

                                <Form.Control
                                    name={'orderReference'}
                                    type={'text'}
                                    value={orderReference}
                                    maxLength={99}
                                    onChange={this.handleChange}
                                />
                            </Form.Group>
                        )}

                        <Form.Group controlId={'comment'}>
                            <Form.Label>
                                <strong> {t('template', 'quotes.dialog.comment.label')} </strong>
                            </Form.Label>

                            <Form.Control
                                name={'comment'}
                                as={'textarea'}
                                rows={2}
                                value={comment}
                                onChange={this.handleChange}
                            />
                        </Form.Group>

                        {!slim && (
                            <>
                                <Form.Group
                                    controlId="formBasicCheckbox  mb-2"
                                    className={errorLicense === t('template', 'error.license.terms') ? 'invalid' : ''}
                                >
                                    <Form.Check
                                        type={'checkbox'}
                                        label={renderLicenseLabel}
                                        name="acceptTerms"
                                        inline={true}
                                        checked={acceptTerms}
                                        onChange={this.toggleAcceptTerms}
                                        className="is-invalid"
                                    />
                                    {errorLicense && <div className="invalid-feedback">{errorLicense}</div>}
                                </Form.Group>

                                {additionalContent}
                            </>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant={'outline-secondary'} onClick={this.handleClose} className={styles.cancelBtn}>
                            {t('template', 'quotes.dialog.cancel.label')}
                        </Button>
                        <Button variant="primary" onClick={this.handleModalAction} className={styles.modalActionBtn}>
                            {modalActionBtnLabel}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        )
    }
}

export default withTranslations(CreditCardDialog)

interface QuoteDialogProps extends Translation {
    btnContent: any
    title: string
    slim?: boolean
    subTitle: string
    textContent: any
    additionalContent?: any
    modalAction?: any
    modalActionBtnLabel: string
    errorMsg: string
}

interface QuoteDialogState {
    show: boolean
    orderReference: string
    comment: string
    acceptTerms: boolean
    errorLicense: string
}
