import axios from 'axios'
import { navigate } from 'gatsby'
import * as React from 'react'
import { Alert } from 'react-bootstrap'
import postifyData from '../../../helper/FormHelper'
import { INewsletterPluginData } from '../../../types/Newsletter'
import PageContext from '../../../types/PageContext'
import Translation from '../../../types/Translation'
import withTranslations from '../../hoc/withTranslations'
import NewsletterForm from '../form/NewsletterForm'

class NewsletterSubscribe extends React.Component<NewsletterSubscribeProps, NewsletterSubscribeState> {
    state: NewsletterSubscribeState = {
        submitSuccess: false,
        error: false,
        errorMsg: '',
    }

    constructor(props: NewsletterSubscribeProps) {
        super(props)
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    async handleSubmit(formData: any) {
        this.setState({
            error: false,
            errorMsg: '',
        })

        const url = '/api/v1.0/Newsletter/Subscribe/Default'

        let confirmationUrl = ''

        const { pageContext, pluginData } = this.props

        if (pluginData && pluginData[pageContext.language]) {
            confirmationUrl = pluginData[pageContext.language].confirmationUrl
        }

        const postFormData = postifyData(formData)

        try {
            await axios.post(url, postFormData, {
                headers: {
                    'Accept-Language': formData.language,
                },
            })

            navigate(confirmationUrl)
        } catch (error: any) {
            console.log(error)

            if (error.response) {
                console.log(`status code: ${error.response.status}`)
                console.log(error.response.data)

                if (error.response.status === 406) {
                    this.displayFormError(this.props.t('form', 'newsletterForm.error.userexists'))
                } else if (error.response.status === 400) {
                    this.displayFormError(this.props.t('form', 'newsletterForm.error.invalidemail'))
                } else {
                    this.displayFormError(this.props.t('form', 'general.form.error'))
                }
            } else {
                this.displayFormError(this.props.t('form', 'general.form.error'))
            }
        }
    }

    displayFormError(errorMsg: string) {
        this.setState({
            error: true,
            errorMsg,
        })
    }

    render() {
        const { errorMsg, error } = this.state

        return (
            <>
                {error && <Alert variant="danger">{errorMsg}</Alert>}

                <NewsletterForm onSubmit={this.handleSubmit} />
            </>
        )
    }
}

export default withTranslations(NewsletterSubscribe)

interface NewsletterSubscribeProps extends Translation {
    pageContext: PageContext<any>
    pluginData: INewsletterPluginData
}

interface NewsletterSubscribeState {
    submitSuccess: boolean
    error: boolean
    errorMsg: string
}
