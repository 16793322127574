/* eslint-disable react/display-name */
import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import { IProductRecords } from '../../types/Product'

const useProducts = () => {
    const data = useStaticQuery(graphql`
        query PublicationPage {
            allDirectusProduct {
                nodes {
                    code
                    name
                    family
                }
            }
        }
    `)

    return data.allDirectusProduct.nodes
}

const withProducts = (Component: React.ComponentType<any>): React.ComponentType<any> => (props: IProductRecords) => {
    const products = useProducts()

    return <Component {...props} products={products} />
}

export default withProducts
