import * as React from 'react'
import { Alert, Spinner } from 'react-bootstrap'
import Location from '../../../types/Location'
import PageContext from '../../../types/PageContext'
import { ISearchResults } from '../../../types/Search'
import Translation from '../../../types/Translation'
import axios from '../../../utils/axios'
import withLocation from '../../hoc/withLocation'
import withTranslation from '../../hoc/withTranslations'
import * as styles from './Search.module.scss'
import SearchBar from './SearchBar'
import SearchPagination from './SearchPagination'
import SearchResults from './SearchResults'

class Search extends React.Component<SearchProps, SearchState> {
    state: SearchState = {
        submittedQuery: '',
        searchQuery: '',
        searchResults: undefined,
        noResults: false,
        isLoading: false,
        errorMsg: '',
    }

    async componentDidMount() {
        const { search } = this.props
        const { q, page } = search

        if (q) {
            if (page) {
                await this.fetchGoogleSearchResults(q, page)
            } else {
                await this.fetchGoogleSearchResults(q)
            }

            this.setState({
                ...this.state,
                searchQuery: q,
                submittedQuery: q,
            })
        }
    }

    handleSearchSubmit = async () => {
        const { searchQuery } = this.state
        if (searchQuery) {
            this.setUrlSearchQueryParam(searchQuery)
            await this.fetchGoogleSearchResults(searchQuery)

            this.setState({
                ...this.state,
                submittedQuery: searchQuery,
            })
        }
    }

    handlePaginationClick = async (pageNum: number) => {
        const { searchQuery } = this.state
        this.setUrlPageQueryParam(pageNum)
        await this.fetchGoogleSearchResults(searchQuery, pageNum)

        window.scrollTo(0, 0)
    }

    setUrlPageQueryParam = (pageNum: number) => {
        const searchParams = new URLSearchParams(window.location.search)
        searchParams.set('page', pageNum.toString())
        const newRelativePathQuery = window.location.pathname + '?' + searchParams.toString()
        window.history.pushState(null, '', newRelativePathQuery)
    }

    setUrlSearchQueryParam = (searchQuery: string) => {
        const searchParams = new URLSearchParams()
        searchParams.set('q', searchQuery)
        const newRelativePathQuery = window.location.pathname + '?' + searchParams.toString()
        window.history.pushState(null, '', newRelativePathQuery)
    }

    handleSearchChange = (searchQuery: string) => {
        this.setState({
            ...this.state,
            searchQuery,
        })
    }

    fetchGoogleSearchResults = async (searchQuery: string, pageNumber = 1) => {
        const fetchSearchUrl = `/api/v1.0/Search/${pageNumber}?q=${searchQuery}`
        const { t } = this.props

        this.setState({
            ...this.state,
            isLoading: true,
        })

        try {
            const response = await axios.get(fetchSearchUrl, {
                headers: { 'Accept-Language': this.props.pageContext.language },
            })

            if (response && response.data) {
                this.setState({
                    ...this.state,
                    searchResults: response.data,
                    noResults: false,
                })
            }
        } catch (error: any) {
            console.log(error)

            if (error.response) {
                console.log(`status code: ${error.response.status}`)
                console.log(error.response.data)
            }

            if (error.response.status === 503) {
                this.setState({
                    ...this.state,
                    noResults: true,
                })
            } else {
                this.displayError(t('form', 'general.form.error'))
            }

            return false
        } finally {
            this.setState({
                ...this.state,
                isLoading: false,
            })
        }
    }

    displayError = (errorMsg: string) => {
        this.setState({
            errorMsg,
        })
    }

    render() {
        const { searchResults, searchQuery, submittedQuery, isLoading, noResults, errorMsg } = this.state

        const { t } = this.props

        let contentNoResults = null

        if (noResults) {
            contentNoResults = (
                <>
                    <h1>{t('template', 'general.error.noresult.title')}</h1>
                    <p>{t('template', 'general.error.noresult.message')}</p>
                </>
            )
        }

        return (
            <>
                <SearchBar
                    onSearchSubmit={this.handleSearchSubmit}
                    onSearchChange={this.handleSearchChange}
                    searchQuery={searchQuery}
                />

                {isLoading && (
                    <div className={styles.spinnerContainer}>
                        <Spinner animation={'border'} />
                    </div>
                )}

                {contentNoResults}

                {errorMsg && <Alert variant={'danger'}>{errorMsg}</Alert>}

                {searchResults && !noResults && (
                    <>
                        <h1>
                            {t('template', 'search.result.title')} «{submittedQuery}»
                        </h1>

                        <SearchResults searchResults={searchResults} />

                        <SearchPagination searchResults={searchResults} onPaginationClick={this.handlePaginationClick} />
                    </>
                )}
            </>
        )
    }
}

export default withLocation(withTranslation(Search))

interface SearchState {
    submittedQuery: string
    searchQuery: string
    searchResults?: ISearchResults
    noResults: boolean
    isLoading: boolean
    errorMsg: string
}

interface SearchProps extends Translation, Location<any> {
    pageContext: PageContext<any>
}
