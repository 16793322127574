import * as React from 'react'
import { ISuccessStoryList } from '../../../types/SuccessStory'

import AnchorLink from '../AnchorLink'
import AnchorNavigation from '../AnchorNavigation'

const SuccessStoryListNavigation: React.FunctionComponent<SuccessStoryListNavigationProps> = (
    props: SuccessStoryListNavigationProps,
) => {
    const { list } = props

    const anchorLinks: any = []

    for (const sectionKey of Object.keys(list)) {
        if (list[sectionKey].length) {
            const anchor = `#${sectionKey}`

            anchorLinks.push(<AnchorLink anchor={anchor} title={''} anchorKey={sectionKey} key={`anchor-${sectionKey}`} />)
        } else {
            anchorLinks.push(<AnchorLink anchorKey={sectionKey} key={`anchor-${sectionKey}`} />)
        }
    }

    return <AnchorNavigation>{anchorLinks}</AnchorNavigation>
}

export default SuccessStoryListNavigation

interface SuccessStoryListNavigationProps {
    list: ISuccessStoryList
}
