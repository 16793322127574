/* eslint-disable react/display-name */
import * as React from 'react'
import { Pagecontent } from '../../types/PageContent'
import PageContext from '../../types/PageContext'
import ContactsPlugin from './plugin/ContactsPlugin'
import FormPlugin from './plugin/FormPlugin'
import GlossaryPlugin from './plugin/GlossaryPlugin'
import InternshipReportsPlugin from './plugin/InternshipReportsPlugin'
import MyPDFAccountPlugin from './plugin/MyPDFAccountPlugin'
import MyPDFIframePlugin from './plugin/MyPDFIframePlugin'
import MyPDFLicencesPlugin from './plugin/MyPDFLicencesPlugin'
import MyPDFLicenseActivationPlugin from './plugin/MyPDFLicenseActivationPlugin'
import MyPDFQuotesPlugin from './plugin/MyPDFQuotesPlugin'
import MyPDFRegistrationPlugin from './plugin/MyPDFRegisterPlugin'
import MyPDFToolDownloadPlugin from './plugin/MyPDFToolDownloadPlugin'
import NewsletterActivatePlugin from './plugin/NewsletterActivatePlugin'
import NewsletterPlugin from './plugin/NewsletterPlugin'
import NewsListPlugin from './plugin/NewsListPlugin'
import QuoteRequestSelectionPlugin from './plugin/QuoteRequestSelectionPlugin'
import SearchPlugin from './plugin/SearchPlugin'
import SitemapPlugin from './plugin/SitemapPlugin'
import SuccessStoryListPlugin from './plugin/SuccessStoryListPlugin'
import VacancyPlugin from './plugin/VacancyPlugin'

export const getPlugin = (node: Pagecontent, pageContext: PageContext<any>): React.ReactNode => {
    const pluginConfig = node.config ? JSON.parse(node.config) : undefined
    if (pluginConfig) {
        const type: TPluginType = pluginConfig.plugin

        if (plugins[type]) {
            return plugins[type](pluginConfig.config, node.pluginData, pageContext)
        }
    }

    return null
}

const plugins: Record<TPluginType, TPluginRenderFunction> = {
    form: (config, pluginData, pageContext) => <FormPlugin config={config} pageContext={pageContext} pluginData={pluginData} />,
    glossary: (config, pluginData, pageContext) => <GlossaryPlugin pageContext={pageContext} pluginData={pluginData} />,
    success_story_list: (config, pluginData, pageContext) => (
        <SuccessStoryListPlugin config={config} pageContext={pageContext} pluginData={pluginData} />
    ),
    mypdftools_account: (config, pluginData, pageContext) => (
        <MyPDFAccountPlugin config={config} pageContext={pageContext} pluginData={pluginData} />
    ),
    mypdftools_registration: (config, pluginData, pageContext) => (
        <MyPDFRegistrationPlugin config={config} pageContext={pageContext} pluginData={pluginData} />
    ),
    mypdftools_licences_kits: (config, pluginData, pageContext) => (
        <MyPDFLicencesPlugin config={config} pageContext={pageContext} pluginData={pluginData} />
    ),

    mypdftools_quotes: (config, pluginData, pageContext) => (
        <MyPDFQuotesPlugin config={config} pageContext={pageContext} pluginData={pluginData} />
    ),
    mypdftools_iframe: (config, pluginData, pageContext) => (
        <MyPDFIframePlugin config={config} pageContext={pageContext} pluginData={pluginData} />
    ),
    mypdftools_tool_download: (config, pluginData, pageContext) => (
        <MyPDFToolDownloadPlugin config={config} pageContext={pageContext} pluginData={pluginData} />
    ),
    mypdftools_license_activation: (config, pluginData, pageContext) => (
        <MyPDFLicenseActivationPlugin config={config} pageContext={pageContext} pluginData={pluginData} />
    ),
    search: (config, pluginData, pageContext) => (
        <SearchPlugin config={config} pageContext={pageContext} pluginData={pluginData} />
    ),
    contacts: (config, pluginData, pageContext) => (
        <ContactsPlugin config={config} pageContext={pageContext} pluginData={pluginData} />
    ),
    newsletter: (config, pluginData, pageContext) => (
        <NewsletterPlugin config={config} pageContext={pageContext} pluginData={pluginData} />
    ),
    newsletter_activate: (config, pluginData, pageContext) => (
        <NewsletterActivatePlugin config={config} pageContext={pageContext} pluginData={pluginData} />
    ),
    vacancy: (config, pluginData, pageContext) => (
        <VacancyPlugin config={config} pageContext={pageContext} pluginData={pluginData} />
    ),
    internship_reports: (config, pluginData, pageContext) => (
        <InternshipReportsPlugin config={config} pageContext={pageContext} pluginData={pluginData} />
    ),
    news_list: (config, pluginData, pageContext) => (
        <NewsListPlugin config={config} pageContext={pageContext} pluginData={pluginData} />
    ),
    quote_request_selection: (config, pluginData, pageContext) => (
        <QuoteRequestSelectionPlugin config={config} pageContext={pageContext} pluginData={pluginData} />
    ),
    sitemap: (config, pluginData, pageContext) => (
        <SitemapPlugin config={config} pageContext={pageContext} pluginData={pluginData} />
    ),
}

type TPluginType =
    | 'form'
    | 'glossary'
    | 'success_story_list'
    | 'mypdftools_account'
    | 'mypdftools_registration'
    | 'mypdftools_licences_kits'
    | 'mypdftools_quotes'
    | 'mypdftools_tool_download'
    | 'mypdftools_license_activation'
    | 'mypdftools_iframe'
    | 'search'
    | 'vacancy'
    | 'newsletter'
    | 'newsletter_activate'
    | 'contacts'
    | 'news_list'
    | 'internship_reports'
    | 'quote_request_selection'
    | 'sitemap'

type TPluginRenderFunction = (config: any, pluginData: any, pageContext: PageContext<any>) => React.ReactNode
