import * as React from 'react'
import PageContext from '../../../types/PageContext'
import Glossary from '../../content/glossary/Glossary'
import { IGlossaryPluginData } from '../../../types/Glossary'

const GlossaryPlugin: React.FunctionComponent<GlossaryPluginProps> = (props: GlossaryPluginProps) => {
    const { pageContext, pluginData } = props

    const langKey = pageContext.language

    if (pluginData && pluginData?.records[langKey]) {
        const glossaryKeyType = pluginData.keyType
        const glossaryEntries = pluginData.records[langKey]
        return <Glossary entries={glossaryEntries} keyType={glossaryKeyType || 'alphabet'} />
    } else {
        return <div>This glossary is empty.</div>
    }
}

export default GlossaryPlugin

interface GlossaryPluginProps {
    pageContext: PageContext<any>
    pluginData: IGlossaryPluginData
}
