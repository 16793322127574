import * as React from 'react'
import PageContext from '../../../types/PageContext'
import IToolDownloadConfig from '../../../types/ToolDownload'
import ToolDownload from '../ToolDownload'

class MyPDFToolDownloadPlugin extends React.Component<Props, State> {
    render() {
        const { config, pageContext } = this.props

        return (
            // wrapped in div-container for display:block
            <div>
                <ToolDownload config={config} pageContext={pageContext} />
            </div>
        )
    }
}

export default MyPDFToolDownloadPlugin

interface State {}

interface Props {
    config: IToolDownloadConfig
    pageContext: PageContext
    pluginData: any
}
