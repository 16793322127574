import * as React from 'react'
import PageContext from '../../types/PageContext'
import IToolDownloadConfig from '../../types/ToolDownload'
import Translation from '../../types/Translation'
import axios from '../../utils/axios'
import withTranslation from '../hoc/withTranslations'
import ContentButton from './ContentButton'

class ToolDownload extends React.Component<Props, State> {
    handleClick = async () => {
        const { config } = this.props
        const { toolId } = config

        const fetchDownloadUrl = `/api/v1.0/Url/tooldownload?toolId=${toolId}`

        try {
            const response = await axios.get(fetchDownloadUrl)

            if (response && response.data) {
                window.open(response.data.url, '_self')
            }
        } catch (e) {
            console.log(e)
        }
    }

    render() {
        const { config, t } = this.props

        const { translationCategory, translationKey } = config

        let label = '[Download]'

        if (translationCategory && translationKey) {
            label = t(translationCategory, translationKey)
        }

        return (
            <ContentButton variant="link" className="pti-download" onClick={this.handleClick}>
                {label}
            </ContentButton>
        )
    }
}

export default withTranslation(ToolDownload)

interface State {}

interface Props extends Translation {
    config: IToolDownloadConfig
    pageContext: PageContext
}
