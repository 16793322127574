import { Link } from 'gatsby'
import * as React from 'react'
import { Col, Row } from 'react-bootstrap'
import { INewsListItem } from '../../../types/News'
import Translation from '../../../types/Translation'
import withTranslations from '../../hoc/withTranslations'
import * as styles from './NewsListItem.module.scss'

const NewsListItem: React.FunctionComponent<NewsListItemProps> = (props: NewsListItemProps) => {
    const { newsletterUrl, polaroidImg, title, subTitle, teaser } = props.newsListItem

    const { t } = props

    return (
        <Row className={styles.newsListItem}>
            <Col sm={3}>
                <Link to={newsletterUrl}>
                    <img src={polaroidImg} style={{ width: '100%' }} />
                </Link>
            </Col>

            <Col sm={9}>
                <h2>
                    <Link to={newsletterUrl}>{title}</Link>
                </h2>

                <p className={styles.subTitle}>{subTitle}</p>

                <div dangerouslySetInnerHTML={{ __html: teaser }} />

                <Link to={newsletterUrl} className={'read-more'}>
                    {t('template', 'general.link.text.readmore')}
                </Link>
            </Col>
        </Row>
    )
}

export default withTranslations(NewsListItem)

interface NewsListItemProps extends Translation {
    newsListItem: INewsListItem
}
