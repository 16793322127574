import React from 'react'
import Translation from '../../../../types/Translation'
import axios from '../../../../utils/axios'
import withTranslations from '../../../hoc/withTranslations'
import UploadForm from './UploadForm'

class LicenseActivation extends React.Component<LicenseActivationProps, LicenseActivationState> {
    state: LicenseActivationState = {
        errorMsg: '',
        successMsg: '',
        progressing: false,
    }

    handleFileDrop = (acceptedFiles: File[]) => {
        const { t } = this.props

        if (acceptedFiles.length > 0) {
            const acceptedFile = acceptedFiles[0]
            this.processFile(acceptedFile)
        } else {
            this.displayError(t('api', 'licenseactivator.error.invalid'))
        }
    }

    processFile = (file: File) => {
        const fr = new FileReader()

        fr.onload = async (e: any) => {
            const fileData = e.target.result
            await this.postLicenseActivation(fileData, file.name)
        }

        fr.readAsText(file)
    }

    postLicenseActivation = async (fileData: any, uploadedFileName: string) => {
        const { t } = this.props

        this.setState({
            progressing: true,
        })

        const url = '/api/v1.0/LicenseActivation'

        try {
            const response = await axios.post(url, fileData, {
                headers: {
                    'Content-Type': 'text/plain',
                },
            })

            if (response.status === 200) {
                if (response && response.data) {
                    this.downloadFile(response.data, uploadedFileName)
                    this.displaySuccess(t('api', 'licenseactivator.success.200'))
                }
            }

            if (response.status === 204) {
                this.displaySuccess(t('api', 'licenseactivator.success.204'))
            }
        } catch (error) {
            if (error.response) {
                const status = error.response.status

                console.log(`status code: ${error.response.status}`)
                console.log(error.response.data)

                const statusRange = status.toString().substring(0, 2)

                if (statusRange === '45') {
                    if (status === 450) {
                        this.displayError(t('api', 'licenseactivator.error.450'))
                    }

                    if (status === 451) {
                        this.displayError(t('api', 'licenseactivator.error.451'))
                    }

                    if (status === 452) {
                        this.displayError(t('api', 'licenseactivator.error.452'))
                    }

                    if (status === 453) {
                        this.displayError(t('api', 'licenseactivator.error.453'))
                    }

                    if (status === 454) {
                        this.displayError(t('api', 'licenseactivator.error.454'))
                    }

                    if (status === 455) {
                        this.displayError(t('api', 'licenseactivator.error.455'))
                    }

                    if (status === 456) {
                        this.displayError(t('api', 'licenseactivator.error.456'))
                    }
                } else {
                    if (status >= 400 && status <= 500) {
                        this.displayError(t('api', 'licenseactivator.error.invalid'))
                    }
                }

                if (status === 503) {
                    this.displayError(t('api', 'licenseactivator.error.503'))
                }
            }
        } finally {
            this.setState({
                progressing: false,
            })
        }
    }

    downloadFile = (fileData: any, uploadedFileName: string) => {
        const downloadFileName = uploadedFileName.replace('request', 'response')

        if (navigator.msSaveBlob) {
            navigator.msSaveBlob(new Blob([fileData], { type: 'data:application/octet-stream' }), downloadFileName)
        } else {
            const blob = new Blob([fileData], { type: 'application/octet-stream' })
            const url = window.URL.createObjectURL(blob)

            const anchor = document.createElement('a')

            anchor.download = downloadFileName
            anchor.href = url
            anchor.target = '_self'
            anchor.click()
        }
    }

    displayError = (errorMsg: string) => {
        this.setState({
            errorMsg,
            successMsg: '',
        })
    }

    displaySuccess = (successMsg: string) => {
        this.setState({
            successMsg,
            errorMsg: '',
        })
    }

    render() {
        const { progressing, errorMsg, successMsg } = this.state

        return <UploadForm onDrop={this.handleFileDrop} progressing={progressing} errorMsg={errorMsg} successMsg={successMsg} />
    }
}

export default withTranslations(LicenseActivation)

interface LicenseActivationProps extends Translation {}

interface LicenseActivationState {
    errorMsg: string
    successMsg: string
    progressing: boolean
}
