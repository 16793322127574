import * as React from 'react'
import PageContext from '../../../types/PageContext'
import LicenseActivation from '../../mypdftools/licences/activation/LicenseActivation'

class MyPDFLicenseActivation extends React.Component<Props, {}> {
    render() {
        return <LicenseActivation />
    }
}

export default MyPDFLicenseActivation

interface Props {
    config: any
    pageContext: PageContext<any>
    pluginData: any
}
