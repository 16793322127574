import Select from 'react-select'
import * as React from 'react'
import PageContext from '../../../types/PageContext'
import { navigate } from 'gatsby'
import { IQuoteRequestData, IQuoteRequestTranslation } from '../../../types/QuoteRequest'

const QuoteRequestSelectionPlugin: React.FunctionComponent<Props> = (props: Props) => {
    const { pageContext, pluginData } = props
    let quoteRequestProducts: IQuoteRequestTranslation[] = []

    if (pluginData && pluginData[pageContext.language]) {
        quoteRequestProducts = pluginData[pageContext.language]
    }

    const handleProductChange = (opt: any) => {
        navigate(opt.to)
    }

    let productEnabledOptions: Array<any> = []

    quoteRequestProducts.forEach((e) => {
        productEnabledOptions.push({
            value: e.code,
            label: e.name,
            to: e.url,
        })
    })

    let product = productEnabledOptions[0]

    return (
        <Select
            defaultValue={product}
            onChange={handleProductChange}
            options={productEnabledOptions}
            classNamePrefix={'pdft_select'}
        />
    )
}

export default QuoteRequestSelectionPlugin

interface Props {
    config: any
    pageContext: PageContext<any>
    pluginData: IQuoteRequestData
}

interface IProductRecord {
    links: any[]
    code: string
    name: string
    family: string
    directusId: any
    quoterequest_enabled: boolean
}
