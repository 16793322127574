import * as React from 'react'
import { Row } from 'react-bootstrap'
import * as styles from './LicensesHeadRow.module.scss'

const LicensesHeadRow: React.FunctionComponent<LicensesHeadRowProps> = (props) => {
    const { children } = props

    return <Row className={styles.header}>{children}</Row>
}

export default LicensesHeadRow

interface LicensesHeadRowProps {}
