import React, { useState } from "react";
import { Alert, Col, Form } from "react-bootstrap";
import ColoredBox from "../../content/ColoredBox";
import usePasswordChange from "./usePasswordChange";



export const PasswordChange = (props: any) => {
    const { t } = props

    const { error, setError, success, loading, password, confirmPassword, setPassword, setConfirmPassword, handleSubmit, ErrorStates } = usePasswordChange();

    const [changePasswordFormOn, setChangePasswordFormOn] = useState(false);

    return (
        <ColoredBox>
            <div style={{ padding: '20px 10px' }}>
                {!changePasswordFormOn && (
                    <input
                        onClick={() => setChangePasswordFormOn(true)}
                        type={'submit'}
                        className={'btn btn-primary'}
                        value={t('form', 'general.field.changePassword.label')}
                    />
                )}
                {changePasswordFormOn && <Col sm={6} >
                    {success ? (
                        <div>
                            {t('form', 'general.updatePassword.successMessage')}
                        </div>
                    ) : (
                        <Form
                            onSubmit={handleSubmit}
                        >
                            <Form.Group controlId={'form.password'} >
                                <Form.Label>
                                    {t('form', 'general.field.newPassword.label')}
                                    <span>*</span>
                                </Form.Label>
                                <Form.Control
                                    type="password"
                                    name={'password'}
                                    value={password}
                                    isInvalid={error === ErrorStates.MissingPassword
                                        || error === ErrorStates.PasswordsDoNotMatch
                                        || error === ErrorStates.PasswordTooShort}
                                    onChange={(e: any) => {
                                        setError(ErrorStates.NoError)
                                        setPassword(e.target.value)
                                    }}
                                />
                                <Form.Control.Feedback type="invalid" style={error === ErrorStates.MissingPassword ? { display: 'unset' } : { display: 'none' }}>
                                    {t('form', 'general.validate.password.invalid')}
                                </Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid" style={error === ErrorStates.PasswordTooShort ? { display: 'unset' } : { display: 'none' }}>
                                    {t('form', 'general.validate.password.invalid.tooShort')}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group controlId={'form.confirm'}>
                                <Form.Label>
                                    {t('form', 'general.field.confirm.label')}
                                    <span>*</span>
                                </Form.Label>
                                <Form.Control
                                    name={'confirmPassword'}
                                    type={'password'}
                                    value={confirmPassword}
                                    isInvalid={error === ErrorStates.PasswordsDoNotMatch || error === ErrorStates.MissingConfirmPassword}
                                    onChange={(e: any) => {
                                        setError(ErrorStates.NoError)
                                        setConfirmPassword(e.target.value)
                                    }}
                                />
                                <Form.Control.Feedback type="invalid" style={error === ErrorStates.PasswordsDoNotMatch ? { display: 'unset' } : {}}>
                                    {t('form', 'general.validate.password.invalid.passwordsDoNotMatch')}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <div style={{ marginTop: '20px' }}>
                                <Form.Group>
                                    <input type={'submit'} className={'btn btn-primary'} value={t('form', 'general.field.changePasswordSubmit.label')} />
                                </Form.Group>
                            </div>

                            {error === ErrorStates.GenericError && <Alert variant={'danger'}>  {t('form', 'general.updatePassword.genericRequestError')}</Alert>}
                        </Form>)}
                </Col>}
            </div >
        </ColoredBox >
    )
}