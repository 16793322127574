import axios from '../utils/axios'

export const fetchDocument = async (documentId: string, errorCallback?: any) => {
    const url = `/api/v1.0/Documents/${documentId}`

    try {
        const response = await axios.get(url, {
            responseType: 'arraybuffer',
        })

        if (response && response.data) {
            return processBuffer(response.data) as TDocumentUrl
        }
    } catch (error) {
        console.log(error)

        if (error.response) {
            console.log(`status code: ${error.response.status}`)
            console.log(error.response.data)
        }

        if (errorCallback) {
            errorCallback(error)
        }
    }
}

export const openDocumentTab = (documentUrl: string) => {
    Object.assign(document.createElement('a'), { target: '_blank', href: documentUrl }).click()
}

const processBuffer = (buffer: any) => {
    const blob = new Blob([buffer], { type: 'application/pdf' })
    const url = window.URL.createObjectURL(blob)

    return url
}

type TDocumentUrl = string
