import * as React from 'react'
import { IQuoteDialogData } from '../../../types/Quote'
import Translation from '../../../types/Translation'
import axios from '../../../utils/axios'
import withTranslations from '../../hoc/withTranslations'
import CancelConfirmDialog from './CancelConfirmDialog'
import CreditCardDialog from './CreditCardDialog'

class ModalCancel extends React.Component<ModalCancelProps, ModalCancelState> {
    state = {
        displayConfirmDialog: false,
        errorMsg: '',
    }

    displayError = (errorMsg: string) => {
        this.setState({
            errorMsg,
        })
    }

    showConfirmDialog = () => {
        this.setState({
            displayConfirmDialog: true,
        })
    }

    closeConfirmDialog = async () => {
        this.setState({
            displayConfirmDialog: false,
        })

        if (this.props.onCancelComplete) {
            await this.props.onCancelComplete()
        }
    }

    handleCancelQuote = async (quoteDialogData: IQuoteDialogData, closeQuoteDialog: any) => {
        const { quoteId, t } = this.props
        const modifyUrl = `/api/v1.0/Quotes/${quoteId}/Cancel`

        try {
            await axios.post(modifyUrl, quoteDialogData)

            closeQuoteDialog()
            this.showConfirmDialog()
        } catch (error) {
            console.log(error)

            if (error.response) {
                console.log(`status code: ${error.response.status}`)
                console.log(error.response.data)
            }

            this.displayError(t('form', 'general.form.error'))
        }
    }

    render() {
        const { t, quoteDocumentId, contactLinkUrl } = this.props
        const { errorMsg, displayConfirmDialog } = this.state

        const subTitle = t('template', 'quotes.title.quote') + ` ${quoteDocumentId}`

        const btnContent = <span className={'pti-cancel'} />

        return (
            <>
                <CreditCardDialog
                    title={t('template', 'quotes.dialog.cancel.title')}
                    subTitle={subTitle}
                    textContent={t('template', 'quotes.dialog.cancel.text')}
                    slim={true}
                    modalActionBtnLabel={t('template', 'quotes.dialog.cancel.button.label')}
                    modalAction={this.handleCancelQuote}
                    btnContent={btnContent}
                    errorMsg={errorMsg}
                />

                <CancelConfirmDialog
                    show={displayConfirmDialog}
                    title={t('template', 'quotes.dialog.cancel.title')}
                    subTitle={t('template', 'quotes.dialog.cancel.confirmation.title')}
                    textContent={t('template', 'quotes.dialog.cancel.confirmation.text')}
                    onClose={this.closeConfirmDialog}
                    contactLinkUrl={contactLinkUrl}
                />
            </>
        )
    }
}

export default withTranslations(ModalCancel)

interface ModalCancelProps extends Translation {
    quoteDocumentId: string
    quoteId: string
    contactLinkUrl: string
    onCancelComplete?: any
}

interface ModalCancelState {
    errorMsg: string
    displayConfirmDialog: boolean
}
