import * as React from 'react'
import { Col, Row } from 'react-bootstrap'
import Translation from '../../types/Translation'
import withTranslations from '../hoc/withTranslations'
import { IImage } from '../../types/Image'
import ContentButton from './ContentButton'
import ContentImage from './ContentImage'

const BlogBox: React.FunctionComponent<BlogBoxProps> = (props: BlogBoxProps) => {
    const { img, title, children, href, t } = props

    return (
        <Row className="h-100">
            <Col sm={12} className="d-flex flex-column">
                {img && (
                    <div className="text-center">
                        <ContentImage image={img} fluid={true} />
                    </div>
                )}
                <div>
                    <h3>{title}</h3>
                    {children}
                </div>
                <div className="mt-auto text-center">
                    <hr />
                    <ContentButton to={href} size="sm" pdftColor="mustardyellow">
                        {t('template', 'general.link.text.readmore')}
                    </ContentButton>
                </div>
            </Col>
        </Row>
    )
}

interface BlogBoxProps extends Translation {
    title: string
    children: React.ReactNode
    img?: IImage
    href: string
}

export default withTranslations(BlogBox)
