import axios from 'axios'
import * as React from 'react'
import { Alert } from 'react-bootstrap'
import Location from '../../../types/Location'
import PageContext from '../../../types/PageContext'
import Translation from '../../../types/Translation'
import withLocation from '../../hoc/withLocation'
import withTranslation from '../../hoc/withTranslations'

class NewsletterActivate extends React.Component<NewsletterActivateProps, NewsletterActivateState> {
    state: NewsletterActivateState = {
        activationSuccess: null,
    }

    async componentDidMount() {
        const { id } = this.props.search

        if (id) {
            const url = '/api/v1.0/Newsletter/Activate'
            const data = {
                activationId: id,
            }

            try {
                await axios.post(url, data)

                this.setState({
                    activationSuccess: true,
                })
            } catch (error: any) {
                console.log(error)

                if (error.response) {
                    console.log(`status code: ${error.response.status}`)
                    console.log(error.response.data)
                }

                this.setState({ activationSuccess: false })
            }
        }
    }

    render() {
        const { t } = this.props
        const { activationSuccess } = this.state

        let alert = <Alert variant="primary">{t('template', 'newsletter.activation.loading')}</Alert>

        if (activationSuccess === true) {
            alert = <Alert variant="success">{t('template', 'newsletter.activation.success')}</Alert>
        } else if (activationSuccess === false) {
            alert = <Alert variant="danger">{t('template', 'newsletter.activation.error')}</Alert>
        }

        return <>{alert}</>
    }
}

export default withTranslation(withLocation(NewsletterActivate))

interface NewsletterActivateProps extends Translation, Location<any> {
    pageContext: PageContext<any>
}

interface NewsletterActivateState {
    activationSuccess: boolean | null
}
