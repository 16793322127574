import * as React from 'react'
import AnyLink from '../AnyLink'
import * as styles from './ImageLink.module.scss'
import ContentImage, { ContentImageProps } from './ContentImage'

const ImageLink: React.FunctionComponent<ImageLinkProps> = (props: ImageLinkProps) => {
    const { image, to, center } = props

    return (
        <AnyLink to={to} className={`${styles.link} ${center ? 'text-center' : ''}`}>
            <ContentImage image={image} fluid={true} />
        </AnyLink>
    )
}

export default ImageLink

interface ImageLinkProps extends ContentImageProps {
    to: string
    center?: boolean
}
