import * as React from 'react'
import { INewsListPluginData } from '../../../types/News'
import PageContext from '../../../types/PageContext'
import NewsList from '../news/NewsList'

class NewsListPlugin extends React.Component<Props, {}> {
    render() {
        const { pageContext, pluginData } = this.props
        const newsList = pluginData[pageContext.language]

        return <NewsList newsList={newsList} />
    }
}

export default NewsListPlugin

interface Props {
    config: any
    pageContext: PageContext<any>
    pluginData: INewsListPluginData
}
