import React from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import Translation from '../../../types/Translation'
import ColoredBox from '../../content/ColoredBox'
import Location from '../../../types/Location.js'
import withLocation from '../../hoc/withLocation'
import withTranslations from '../../hoc/withTranslations'
import GenericForm from './GenericForm'
import * as styles from './NewsletterForm.module.scss'

class NewsletterUnsubscribeForm extends React.Component<NewsletterUnsubscribeFormProps, NewsletterUnsubscribeFormState> {
    constructor(props: NewsletterUnsubscribeFormProps) {
        super(props)

        this.state = {
            email: this.props.search.email || '',
        }
    }

    handleSubmit = () => {
        if (this.props.onSubmit) {
            const formData = this.state
            this.props.onSubmit(formData)
        }
    }

    handleChange = (e: any) => {
        const newState = {
            email: e.target.value,
        }

        this.setState(newState)
    }

    render() {
        const { t } = this.props

        return (
            <GenericForm onSubmit={this.handleSubmit}>
                <ColoredBox noWidth={true}>
                    <div className={styles.coloredBox}>
                        <Col sm={12}>
                            <Row>
                                <Col sm={12}>
                                    <h2>{t('form', 'newsletterUnsubscribeForm.title')}</h2>
                                    <Form.Group controlId={'form.email'}>
                                        <Form.Label>
                                            {t('form', 'general.field.email.label')}
                                            <span>*</span>
                                        </Form.Label>
                                        <Form.Control
                                            type="email"
                                            name={'email'}
                                            required={true}
                                            value={this.state.email}
                                            onChange={this.handleChange}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {t('form', 'form.validate.email.invalid')}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row>
                                <Col sm={6}>
                                    <Form.Group>
                                        <input
                                            type={'submit'}
                                            className={'btn btn-primary'}
                                            value={t('form', 'newsletterUnsubscribeForm.unregister')}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Col>
                    </div>
                </ColoredBox>
            </GenericForm>
        )
    }
}

export default withLocation(withTranslations(NewsletterUnsubscribeForm))

interface NewsletterUnsubscribeFormProps extends Location<any>, Translation {
    onSubmit: any
}

interface NewsletterUnsubscribeFormState {
    email: string
}
