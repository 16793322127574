import React from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import Recaptcha from '../../../types/Recaptcha'
import ReCaptcha from '../../../types/ReCaptchaV2'
import withRecaptcha from '../../hoc/withRecaptcha'
import withTranslations from '../../hoc/withTranslations'
import * as styles from './GoogleRecaptcha.module.scss'

const recaptchaId = 'recaptchaBox'

class GoogleRecaptcha extends React.Component<GoogleRecaptchaProps> {
    handleRecaptcha = (response: string) => {
        if (this.props.handleRecaptcha) {
            this.props.handleRecaptcha(response)
        }
    }

    componentDidMount = () => {
        if (typeof window !== 'undefined') {
            this.initRecaptcha()
        }
    }

    initRecaptcha = () => {
        if (window.grecaptcha) {
            window.grecaptcha.ready(() => {
                // wait for reCaptcha to be ready

                window.grecaptcha.render(recaptchaId, {
                    sitekey: this.props.recaptcha.recaptchaSiteKey,
                    callback: this.handleRecaptcha,
                })
            })
        } else {
            setTimeout(this.initRecaptcha, 200)
        }
    }

    render() {
        const { t } = this.props
        return (
            <>
                <Helmet>
                    <script src="https://www.google.com/recaptcha/api.js" async={true} defer={true} />
                </Helmet>
                <Row>
                    <Col sm={6}>
                        <Form.Group
                            style={{
                                marginBottom: '-10px',
                            }}
                            controlId={'form.spam'}
                        >
                            <Form.Label>
                                {t('form', 'contactForm.field.spam.label')}
                                <span>*</span>
                            </Form.Label>
                            <div id={recaptchaId} className={styles.captcha} />
                            <br />
                        </Form.Group>
                    </Col>
                </Row>
            </>
        )
    }
}

export default withTranslations(withRecaptcha(GoogleRecaptcha))

export interface GoogleRecaptchaProps extends Recaptcha {
    handleRecaptcha: (response: string) => void
}

declare global {
    interface Window {
        grecaptcha: ReCaptcha
    }
}
