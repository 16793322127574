import React from 'react'
import { Button, OverlayTrigger, Spinner, Tooltip } from 'react-bootstrap'
import { fetchDocument, openDocumentTab } from '../../../helper/DocumentHelper'
import { IInvoiceRecord } from '../../../types/Invoice'
import Translation from '../../../types/Translation'
import { GenericTable } from '../../content/GenericTable'
import CreditCardModal from '../../content/modal/CreditCardModal'
import withTranslations from '../../hoc/withTranslations'
import * as styles from './InvoiceTable.module.scss'
import { getStatus } from './StatusHelper'

class InvoiceTable extends React.Component<InvoiceTableProps> {
    createHandleDocument = (documentId: string) => async () => {
        await this.handleDocument(documentId)
    }

    handleDocument = async (documentId: string) => {
        const documentUrl = await fetchDocument(documentId)

        if (documentUrl) {
            openDocumentTab(documentUrl)
        }
    }

    renderInvoice = (invoice: IInvoiceRecord, index: number) => {
        const { t } = this.props
        const key = `invoice-row-${index}`

        const statusIsOpen = invoice.status === 'Open'

        const handleDocument = this.createHandleDocument(invoice.documentId)

        const detailTooltip = (
            <OverlayTrigger
                key={'btn-trigger'}
                placement={'top'}
                overlay={<Tooltip id={'tooltip-btn'}>{t('template', 'invoice.tooltip.text.showinvoice')}</Tooltip>}
            >
                <Button onClick={handleDocument} size={'sm'}>
                    <span className={'pti-file-text-o'} />
                </Button>
            </OverlayTrigger>
        )

        const payBtn = (
            <CreditCardModal itemNumber={invoice.documentId} totalPrice={invoice.totalStr} itemId={invoice.id} isQuote={false} />
        )

        return (
            <tr key={key}>
                <td>{invoice.title}</td>
                <td>{invoice.date}</td>
                <td>
                    {invoice.currency} {invoice.totalStr}
                </td>
                <td>{getStatus(invoice.status, this.props.t)}</td>
                <td className={'text-center'}>{detailTooltip}</td>
                <td>{statusIsOpen && payBtn}</td>
            </tr>
        )
    }

    render() {
        const { t } = this.props
        const tableRows = this.props.invoices.map(this.renderInvoice)

        let content: any = (
            <div className={styles.spinnerContainer}>
                <Spinner animation={'border'} />
            </div>
        )

        if (this.props.success) {
            if (this.props.invoices.length > 0) {
                content = (
                    <>
                        <GenericTable>
                            <thead>
                                <tr>
                                    <th>{t('template', 'quotes.table.head.invoice')}</th>

                                    <th>{t('template', 'kits.table.head.date')}</th>

                                    <th>{t('template', 'quotes.table.head.price')}</th>

                                    <th>{t('template', 'quotes.table.head.status')}</th>
                                    <th className={'text-center'}>{t('template', 'quotes.table.head.details')}</th>
                                    <th />
                                </tr>
                            </thead>
                            <tbody>{tableRows}</tbody>
                        </GenericTable>
                    </>
                )
            } else {
                content = t('template', 'invoices.table.data.empty')
            }
        }

        return (
            <>
                <h2>{t('template', 'quotes.title.invoices')}</h2>
                {content}
            </>
        )
    }
}

export default withTranslations(InvoiceTable)

interface InvoiceTableProps extends Translation {
    invoices: IInvoiceRecord[]
    success: boolean
}
