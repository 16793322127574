import axios from 'axios'
import * as React from 'react'
import { Alert } from 'react-bootstrap'
import postifyData from '../../../helper/FormHelper'
import { INewsletterPluginData } from '../../../types/Newsletter'
import PageContext from '../../../types/PageContext'
import Translation from '../../../types/Translation'
import withTranslations from '../../hoc/withTranslations'
import NewsletterUnsubscribeForm from '../form/NewsletterUnsubscribeForm'

class NewsletterUnsubscribe extends React.Component<NewsletterUnsubscribeProps, NewsletterUnsubscribeState> {
    state: NewsletterUnsubscribeState = {
        submitSuccess: false,
    }

    constructor(props: NewsletterUnsubscribeProps) {
        super(props)

        this.handleSubmit = this.handleSubmit.bind(this)
    }

    async handleSubmit(formData: any) {
        const url = '/api/v1.0/Newsletter/Subscribe/All'
        const postFormData = postifyData(formData)

        try {
            await axios.delete(url, {
                data: postFormData,
                headers: { 'Accept-Language': this.props.pageContext.language },
            })

            this.setState({
                submitSuccess: true,
            })
        } catch (error: any) {
            console.log(error)

            if (error.response) {
                console.log(`status code: ${error.response.status}`)
                console.log(error.response.data)
            }
        }
    }

    render() {
        const { t } = this.props
        const { submitSuccess } = this.state

        return (
            <>
                {submitSuccess && <Alert variant={'success'}>{t('template', 'newsletter.unsubscribe.success')}</Alert>}
                <NewsletterUnsubscribeForm onSubmit={this.handleSubmit} />
            </>
        )
    }
}

export default withTranslations(NewsletterUnsubscribe)

interface NewsletterUnsubscribeProps extends Translation {
    pageContext: PageContext<any>
    pluginData: INewsletterPluginData
}

interface NewsletterUnsubscribeState {
    submitSuccess: boolean
}
